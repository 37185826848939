import React from 'react';
import AchillesSimulator from './achilles-simulator';
import './achilles.scss';

import {ReactComponent as AchillesSvg} from '../../../css/imgs/greek-gods/achilles.svg';

const Achilles = () => {

  return (
    <div className="achilles">
      <h3>Achilles</h3>
      <div className='god-icon'><AchillesSvg/></div>
      <AchillesSimulator />
    </div>
  );

};

export default Achilles;