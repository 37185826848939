import React, { useEffect, useState } from 'react';
import './test.scss';
import DawicoinIcon from './dawicoin-icon';
import useScript from '../../../utils/use-script';
// import RenderInWindow from '../../../utils/render-in-window';
import DawicoinReactSdk from 'dawicoin-react-sdk';
// import DawicoinReactSdk from '../dawicoin-react-sdk';

// // Tested on "react": "^17.0.2"

// import React, { useState } from 'react';
// import DawicoinReactSdk from 'dawicoin-react-sdk';

// const ExampleComponent = () => {

//   let [price,setPrice] = useState("price_77Tuc7HCxkLdcRyxDKrpnr");
//   let [euid,setEuid] = useState("");
//   let storeUid = "store_rwn9SJLkbqRqRht663KZAU";

//   return (
//     <div className="example-component">
//       <DawicoinReactSdk env={"Local"}
//         storeUid={storeUid}
//         priceUid={price}
//         euid={euid}
//         callback={({completed,paymentUid,subUid}) => {
//           // completed: Bool, paymentUid: String?, subUid: String?
//           // do stuff
//         }}
//         />
//     </div>
//   );
// };

// export default ExampleComponent;

const Test = () => {

  // let [price,setPrice] = useState("price_gfch57ztGqAPb1YnQ6Y44J");
  let [price,setPrice] = useState("price_6QA2ueMUocrDiBW9EveY2H");
  let [euid,setEuid] = useState("user_1");

  // let storeUid = "store_dBG24Bjzg4kwMNEDve2433";// Swiperino
  let storeUid = "store_rwn9SJLkbqRqRht663KZAU";// Voric
  // let scriptSrc = "http://localhost:3045/sdk/v1/"+storeUid;
  // useScript(scriptSrc);

  // let UID = "25_test-test_24abc";
  // let returnUrl = "http://localhost:3024";
  // let priceIdSub = "price_gfch57ztGqAPb1YnQ6Y44J";
  // let priceIdOTP = "price_o78h7Z3mGCa7Z4YGcVHrvy";
  // let urlSub = "http://localhost:3040/p/"+priceIdSub+"?euid="+UID+"&r="+returnUrl
  // let urlOTP = "http://localhost:3040/p/"+priceIdOTP+"?euid="+UID+"&r="+returnUrl

  // const dawicoinPaymentCallback = ({completed, paymentUid, subUid}) => {
  //   // do stuff
  //   console.log("completed",completed);
  //   console.log("paymentUid",paymentUid);
  //   console.log("subUid",subUid);

  // }

  // const foo = async () => {
	// 	const tokenAddress = '0xd00981105e61274c8a5cd5a88fe7e037d935b513';
	// 	const tokenSymbol = 'TUT';
	// 	const tokenDecimals = 18;
	// 	const tokenImage = 'http://placekitten.com/200/300';

	// 	try {
	// 	  // wasAdded is a boolean. Like any RPC method, an error may be thrown.
	// 	  const wasAdded = await ethereum.request({
	// 	    method: 'wallet_watchAsset',
	// 	    params: {
	// 	      type: 'ERC20', // Initially only supports ERC20, but eventually more!
	// 	      options: {
	// 	        address: tokenAddress, // The address that the token is at.
	// 	        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
	// 	        decimals: tokenDecimals, // The number of decimals in the token
	// 	        image: tokenImage, // A string url of the token logo
	// 	      },
	// 	    },
	// 	  });

	// 	  if (wasAdded) {
	// 	    console.log('Thanks for your interest!');
	// 	  }
	// 	} catch (error) {
	// 	  console.log(error);
	// 	}
	// }

  return (
    <div className="test">
      <h3>Test Component</h3>

      {/* <select value={price} onChange={(e) => {setPrice(e.target.value)}} >
        <option>price_gfch57ztGqAPb1YnQ6Y44J</option>
        <option>price_o78h7Z3mGCa7Z4YGcVHrvy</option>
        <option>price_5AG35zjshhwkQtCo52kSSB</option>
        <option>price_8pcEAwHvEaSgFv7DggKaP9</option>
      </select> */}
      {/* <select value={price} onChange={(e) => {setPrice(e.target.value)}} >
        <option>price_re1fBgA4tQGPbn3VdbDPYq</option>
      </select>
      <input type="text" value={euid} onChange={(e) => {setEuid(e.target.value)}} /> */}

      {/* <a href={urlSub}>Dawicoin Pay Subscription</a>
      <a href={urlOTP}>Dawicoin Pay One Time Payment</a> */}

      <DawicoinReactSdk 
        env={"Local"} 
        sandbox={false}
        storeUid={storeUid}
        priceUid={price}
        euid={euid}
        callback={({completed,paymentUid,subUid}) => {
          console.log("completed",completed);
          console.log("paymentUid",paymentUid);
          console.log("subUid",subUid);
        }}
        />

      {/* 
      <div 
        id="dawicoin-payment-button" 
        data-price-uid={price} 
        data-euid={euid}>
      </div>

      <DawicoinIcon /> */}

    </div>
  );
};

export default Test;