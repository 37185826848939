import React,{useState,useEffect} from 'react';
import './artemis.scss';
import DateEpochInput from '../../elements/date-epoch-input/date-epoch-input';
import usePersistState from '../../../utils/use-persist-state';
import Button from '../../elements/button/button';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import Loading from '../../common/loading/loading';
import { toast } from 'react-toastify';
import { request, unSubRequest } from '../../../utils/request';
import ArtemisResult from './artemis-result';
import * as DateUtil from '../../../utils/date';

import {ReactComponent as ArtemisSvg} from '../../../css/imgs/greek-gods/artemis.svg';


const Artemis = () => {

  const oneMinute = 60 * 1000;

  let rangeStartInit = 1483232400000;//jan 1 2017 1am
  // let rangeStartInit = 1501545600000;//aug 1 2017 1am
  let rangeEndInit = new Date().getTime();

  const [loading,setLoading] = useState(false);
  const [rangeStart,setRangeStart] = useState(rangeStartInit);
  const [rangeEnd,setRangeEnd] = useState(rangeEndInit);

  const [algo,setAlgo] = usePersistState(1,"artemisAlgo","number");
  const [duration,setDuration] = usePersistState(oneMinute * 60 * 24 * 30 * 1,"artemisDuration","number");
  const [hidden,setHidden] = usePersistState(1,"artemisHidden","boolean");

  const [intervalStep,setIntervalStep] = usePersistState(oneMinute * 60,"artemisIntervalStep","number");
  const [ponies,setPonies] = usePersistState(3,"artemisPonies","number");
  const [minQuoteAssetVolume,setMinQuoteAssetVolume] = usePersistState(150,"artemisMinQuoteAssetVolume","number");
  const [trendLength,setTrendLength] = usePersistState(8,"artemisTrendLength","number");
  const [trendStep,setTrendStep] = usePersistState(oneMinute * 60,"artemisTrendStep","number");

  const [binanceData,setBinanceData] = usePersistState(true,"artemisBinanceData","boolean");
  const [kucoinData,setKucoinData] = usePersistState(true,"artemisKucoinData","boolean");
  const [poloniexData,setPoloniexData] = usePersistState(true,"artemisPoloniexData","boolean");
  const [ascendexData,setAscendexData] = usePersistState(true,"artemisAscendexData","boolean");
  const [table,setTable] = usePersistState("KlineHourOne","artemisTable");

  const [binanceMoney,setBinanceMoney] = usePersistState(true,"artemisBinanceMoney","boolean");
  const [kucoinMoney,setKucoinMoney] = usePersistState(true,"artemisKucoinMoney","boolean");
  const [poloniexMoney,setPoloniexMoney] = usePersistState(true,"artemisPoloniexMoney","boolean");
  const [ascendexMoney,setAscendexMoney] = usePersistState(true,"artemisAscendexMoney","boolean");
  const [okexMoney,setOkexMoney] = usePersistState(true,"artemisOkexMoney","boolean");
  const [gateioMoney,setGateioMoney] = usePersistState(true,"artemisGateioMoney","boolean");
  const [huobiMoney,setHuobiMoney] = usePersistState(true,"artemisHuobiMoney","boolean");
  const [ftxMoney,setFtxMoney] = usePersistState(true,"artemisFtxMoney","boolean");

  const [results,setResults] = useState([]);

  useEffect(() => {
    return () => {
      unSubRequest("artemis");
    }
  },[])

  const submitHandler = (e) => {
    e.preventDefault();

    fetchResults();
  }

  const fetchResults = () => {
    let endpoint = "/artemis";
    let name = "artemis";

    let dataExchanges = [];
    if(binanceData){dataExchanges.push("binance")};
    if(kucoinData){dataExchanges.push("kucoin")};
    if(poloniexData){dataExchanges.push("poloniex")};
    if(ascendexData){dataExchanges.push("ascendex")};

    let moneyExchanges = [];
    if(binanceMoney){moneyExchanges.push("binance")};
    if(kucoinMoney){moneyExchanges.push("kucoin")};
    if(poloniexMoney){moneyExchanges.push("poloniex")};
    if(ascendexMoney){moneyExchanges.push("ascendex")};
    if(okexMoney){moneyExchanges.push("okex")};
    if(gateioMoney){moneyExchanges.push("gateio")};
    if(huobiMoney){moneyExchanges.push("huobi")};
    if(ftxMoney){moneyExchanges.push("ftx")};

    let data = {
      rangeStart,
      rangeEnd,
      algo,
      duration: parseInt(duration),
      intervalStep,
      minQuoteAssetVolume,
      ponies,
      trendLength,
      trendStep,
      table,
      dataExchanges,
      moneyExchanges,
    }

    setLoading(true);
    request(name,endpoint,"POST", data, {
      then: function(res){
        console.log("res.data.res",res.data.res);
        setResults(res.data.res.reverse());
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  const artemisStartCrawler = () => {
    let endpoint = "/artemis-start-crawler";
    let name = "artemis-start-crawler";

    let dataExchanges = [];
    if(binanceData){dataExchanges.push("binance")};
    if(kucoinData){dataExchanges.push("kucoin")};
    if(poloniexData){dataExchanges.push("poloniex")};
    if(ascendexData){dataExchanges.push("ascendex")};

    let moneyExchanges = []
    if(binanceMoney){moneyExchanges.push("binance")};
    if(kucoinMoney){moneyExchanges.push("kucoin")};
    if(poloniexMoney){moneyExchanges.push("poloniex")};
    if(okexMoney){moneyExchanges.push("okex")};
    if(gateioMoney){moneyExchanges.push("gateio")};
    if(ascendexMoney){moneyExchanges.push("ascendex")};
    if(huobiMoney){moneyExchanges.push("huobi")};
    if(ftxMoney){moneyExchanges.push("ftx")};

    let data = {
      rangeStart,
      rangeEnd,
      minQuoteAssetVolume,
      algo,
      duration: parseInt(duration),
      intervalStep,
      ponies,
      trendLength,
      trendStep,
      table,
      dataExchanges,
      moneyExchanges,
    }

    request(name,endpoint,"POST", data, {
      then: function(res){toast.success("Artemis Crawler Finished")},
      catch: function(err){toast.error(err.message);},
      finally: function(){
      }
    }) 
  }

  const artemisStopCrawler = () => {
    let endpoint = "/artemis-stop-crawler";
    let name = "artemis-stop-crawler";

    request(name,endpoint,"POST", {}, {
      then: function(res){toast.info("Artemis Crawler Stopped")},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    }) 
  }

  const artemisCrawlerStatus = () => {
    let endpoint = "/artemis-crawler-status";
    let name = "artemis-crawler-status";

    request(name,endpoint,"POST", {}, {
      then: function(res){
        let crawlerActive = res.data.res.crawlerActive;
        let crawlerLastUpdate = res.data.res.crawlerLastUpdate;
        crawlerLastUpdate = DateUtil.dateTimeSince(crawlerLastUpdate,1);
        let msg = "";
        msg += "Artemis Crawler Active: "+crawlerActive+". ";
        msg += "Artemis Last update: "+crawlerLastUpdate+". ";
        toast.info(msg); 
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    }) 
  }

  const deleteAll = () => {
    console.log("results",results);
    for (let i = 0; i < results.length; i++) {
      let result = results[i];
      if(result.exists){
        const id = results[i].batchId;
        deleteBatchResults(id);
      }
    }
  }

  const deleteBatchResults = (id) => {
    console.log("deleteBatchResults",id);
    if(id === undefined || id < 1) return;
    setLoading(true);
    request(("delete-hephaestus-batch-results"+id),"/hephaestus-batch-results","DELETE", {id}, {
      then: function(res){
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  return (
    <div className="artemis">
      <h3>Artemis</h3>

      <div className='god-icon'><ArtemisSvg/></div>

      <div className="artemis-content">


        <form onSubmit={submitHandler}>
          <div className="block crawler">
            <div>
              <Button type="button" onClick={() => {artemisStartCrawler()}}>Start Crawler</Button>
            </div>
            <div>
              <Button type="button" onClick={() => {artemisStopCrawler()}}>Stop Crawler</Button>
            </div>
            <div>
              <Button type="button" onClick={() => {artemisCrawlerStatus()}}>Status</Button>
            </div>
          </div>
          <div className="block">
            <div>
              <label>Range Start</label>
              <DateEpochInput time={rangeStart} setTime={setRangeStart} />
            </div>
            <div>
              <label>Range End</label>
              <DateEpochInput time={rangeEnd} setTime={setRangeEnd} />
            </div>
            
          </div>
          <div className="block">
            <div>
              <label>Algo Version</label>
              <input type="text" value={algo} onChange={(e) => {setAlgo(e.target.value)}}/>
            </div>
            <div>
              <label>Duration</label>
              <select value={duration} onChange={(e) => setDuration(e.target.value)}>
                <option value={oneMinute * 60 * 24 * 1}>1 day</option>
                <option value={oneMinute * 60 * 24 * 2}>2 days</option>
                <option value={oneMinute * 60 * 24 * 3}>3 days</option>
                <option value={oneMinute * 60 * 24 * 4}>4 days</option>
                <option value={oneMinute * 60 * 24 * 5}>5 days</option>
                <option value={oneMinute * 60 * 24 * 5}>6 days</option>
                <option value={oneMinute * 60 * 24 * 7}>1 week</option>
                <option value={oneMinute * 60 * 24 * 30 * 1}>1 month</option>
              </select>
            </div>
            <div>
              <label>Hidden</label>
              <SwitchToggle isOn={hidden} onClickFunc={() => {setHidden(!hidden)}}/>
            </div>
          </div>
          <div className="block">
            <div className={hidden?"blur":""}>
              <label>Interval Step</label>
              <select value={intervalStep} onChange={(e) => setIntervalStep(e.target.value)}>
                <option value={oneMinute}>1 minute</option>
                <option value={oneMinute * 3}>3 minutes</option>
                <option value={oneMinute * 5}>5 minutes</option>
                <option value={oneMinute * 15}>10 minutes</option>
                <option value={oneMinute * 15}>15 minutes</option>
                <option value={oneMinute * 30}>30 minutes</option>
                <option value={oneMinute * 60}>1 hour</option>
                <option value={oneMinute * 60 * 2}>2 hours</option>
                <option value={oneMinute * 60 * 3}>3 hours</option>
                <option value={oneMinute * 60 * 4}>4 hours</option>
                <option value={oneMinute * 60 * 5}>5 hours</option>
                <option value={oneMinute * 60 * 6}>6 hours</option>
                <option value={oneMinute * 60 * 7}>7 hours</option>
                <option value={oneMinute * 60 * 8}>8 hours</option>
                <option value={oneMinute * 60 * 9}>9 hours</option>
                <option value={oneMinute * 60 * 10}>10 hours</option>
                <option value={oneMinute * 60 * 11}>11 hours</option>
                <option value={oneMinute * 60 * 12}>12 hours</option>
                <option value={oneMinute * 60 * 24}>24 hours</option>
                <option value={oneMinute * 60 * 36}>36 hours</option>
                <option value={oneMinute * 60 * 24 * 2}>2 days</option>
                <option value={oneMinute * 60 * 24 * 3}>3 days</option>
                <option value={oneMinute * 60 * 24 * 5}>5 days</option>
                <option value={oneMinute * 60 * 24 * 7}>1 week</option>
              </select>
            </div>
            <div className={hidden?"blur":""}>
              <label>Trend Step</label>
              <select value={trendStep} onChange={(e) => setTrendStep(e.target.value)}>
                <option value={oneMinute}>1 minute</option>
                <option value={oneMinute * 5}>5 minutes</option>
                <option value={oneMinute * 15}>15 minutes</option>
                <option value={oneMinute * 60}>1 hour</option>
              </select>
            </div>
            <div className={hidden?"blur":""}>
              <label>Trend Length</label>
              <input type="text" value={trendLength} onChange={(e) => {setTrendLength(e.target.value)}}/>
            </div>
            <div className={hidden?"blur":""}>
              <label>Min Q Volume</label>
              <input type="text" value={minQuoteAssetVolume} onChange={(e) => {setMinQuoteAssetVolume(e.target.value)}}/>
            </div>
            <div className={hidden?"blur":""}>
              <label>Ponies</label>
              <input type="text" value={ponies} onChange={(e) => {setPonies(e.target.value)}}/>
            </div>
          </div>

          <div className="block">
            <div>
              <label>Binance</label>
              <SwitchToggle isOn={binanceData} onClickFunc={() => {setBinanceData(!binanceData)}}/>
            </div>
            <div>
              <label>Kucoin</label>
              <SwitchToggle isOn={kucoinData} onClickFunc={() => {setKucoinData(!kucoinData)}}/>
            </div>
            <div>
              <label>Poloniex</label>
              <SwitchToggle isOn={poloniexData} onClickFunc={() => {setPoloniexData(!poloniexData)}}/>
            </div>
            <div>
              <label>Ascendex</label>
              <SwitchToggle isOn={ascendexData} onClickFunc={() => {setAscendexData(!ascendexData)}}/>
            </div>
            <div>
              <label>Table</label>
              <select value={table} onChange={(e) => {setTable(e.target.value)}}>
                <option value="KlineMinuteOne">Minute One</option>
                <option value="KlineMinuteFive">Minute Five</option>
                <option value="KlineMinuteFifteen">Minute Fifteen</option>
                <option value="KlineHourOne">Hour One</option>
              </select>
            </div>
          </div>

          <div className="block">
            <div>
              <label>Binance</label>
              <SwitchToggle isOn={binanceMoney} onClickFunc={() => {setBinanceMoney(!binanceMoney)}}/>
            </div>
            <div>
              <label>Kucoin</label>
              <SwitchToggle isOn={kucoinMoney} onClickFunc={() => {setKucoinMoney(!kucoinMoney)}}/>
            </div>
            <div>
              <label>Poloniex</label>
              <SwitchToggle isOn={poloniexMoney} onClickFunc={() => {setPoloniexMoney(!poloniexMoney)}}/>
            </div>
            <div>
              <label>Okex</label>
              <SwitchToggle isOn={okexMoney} onClickFunc={() => {setOkexMoney(!okexMoney)}}/>
            </div>
            <div>
              <label>Ascendex</label>
              <SwitchToggle isOn={ascendexMoney} onClickFunc={() => {setAscendexMoney(!ascendexMoney)}}/>
            </div>
            <div>
              <label>Gateio</label>
              <SwitchToggle isOn={gateioMoney} onClickFunc={() => {setGateioMoney(!gateioMoney)}}/>
            </div>
            <div>
              <label>Huobi</label>
              <SwitchToggle isOn={huobiMoney} onClickFunc={() => {setHuobiMoney(!huobiMoney)}}/>
            </div>
            <div>
              <label>Ftx</label>
              <SwitchToggle isOn={ftxMoney} onClickFunc={() => {setFtxMoney(!ftxMoney)}}/>
            </div>
          </div>
          <div className="buttons">
            <Button type="submit" value={"Fire"} />
            <Button type="button" onClick={() => {                
              if (window.confirm('Are you sure you wish to all of these results?')){
                console.log("do it")
                deleteAll();
              }
            }}>Delete all batch Results</Button>
          </div>
        </form>
        <div className="results">
          <Loading show={loading} />
          <div className="results-list">
            {
              results.map((d,i) => {
                return(
                  <ArtemisResult key={i} data={d}/>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );

};

export default Artemis;