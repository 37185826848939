const leadingTitle = "";
const trailingTitle = " | Olympus";

const Seo = {
  Home:{
    Title:leadingTitle+"Stock Analysis"+trailingTitle,
    Desc:"Stock Analysis. Easy to use Stock Analysis tool. Find out which Stocks are a bargin. ",
    Keywords:"Stock Analysis, Stock Analyzer, Yahoo Finance",
  },
  Financials:{
    Title:leadingTitle+"Financials"+trailingTitle,
    Desc:"US Stock Financials. Pure simple data without news bothering you. Stock Analysis",
    Keywords:"Stock Financials, Yahoo Finance",
  },
  HistoricalPrices:{
    Title:leadingTitle+"Historical Prices"+trailingTitle,
    Desc:"US Stocks Historical data. Compare historical data with other stocks and world events. ",
    Keywords:"Stock Historical Prices, Yahoo Finance",
  },
  Guides:{
    Title:leadingTitle+"Guides"+trailingTitle,
    Desc:"Stock & Stock related tax guides and information ",
    Keywords:"Stock Guide, How to stock, how to stonk",
  },
  Help:{
    Title:leadingTitle+"Help"+trailingTitle,
    Desc:"Stonkify Help. We're here to help. ",
    Keywords:"Stonkify Help",
  },
  Login:{
    Title:leadingTitle+"Login"+trailingTitle,
    Desc:"Stonkify Login. Login and start turning your stocks into stonks. ",
    Keywords:"Stonkify Login",
  },
  CreateAccount:{
    Title:leadingTitle+"Create Account"+trailingTitle,
    Desc:"Stonkify Create Account. Enhance your Stock knowledge today. ",
    Keywords:"Stonkify Create Account",
  },
  MyAccount:{
    Title:leadingTitle+"My Account"+trailingTitle,
    Desc:"Stonkify My Account. ",
    Keywords:"Stonkify My Account",
  },
  ResetPassword:{
    Title:leadingTitle+"Reset Password"+trailingTitle,
    Desc:"Stonkify Reset Password. Forgot your password? No worries, we got you covered. ",
    Keywords:"Stonkify Reset Password",
  },
  VerifyEmail:{
    Title:leadingTitle+"Verify Email"+trailingTitle,
    Desc:"Stonkify Verify Email. Need to verify your email? Here's where you want to be. ",
    Keywords:"Stonkify Verify Email",
  },
  TermsOfUse:{
    Title:leadingTitle+"Terms Of Use"+trailingTitle,
    Desc:"Stonkify Terms Of Use. The boring stuff. ",
    Keywords:"Stonkify Terms Of Use",
  },
  PrivacyPolicy:{
    Title:leadingTitle+"Privacy Policy"+trailingTitle,
    Desc:"Stonkify Privacy Policy. The boring stuff. ",
    Keywords:"Stonkify Privacy Policy",
  },
};

export default Seo;