import React,{useState} from 'react';
import './hera-coin-modal.scss';
import Modal from '../../common/modal/modal';
import Button from '../../elements/button/button';
import usePersistState from '../../../utils/use-persist-state';
import {request} from '../../../utils/request';
import {toast} from 'react-toastify';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import {clone} from '../../../utils/clone';
import * as DateUtil from '../../../utils/date';

const HeraCoinModal = ({value,show,handleClose,tickers}) => {

  const [loading,setLoading] = useState(false);
  const [orderType,setOrderType] = useState("sell");
  const [coin,setCoin] = useState("");
  const [quoteAsset,setQuoteAsset] = useState("USDT");
  const [amount,setAmount] = useState("");

  const [showLowVals,setShowLowVals] = usePersistState(false,"HeraModalShowLowVals","boolean");
  const [showExchangeCoinsVals,setShowExchangeCoinsVals] = usePersistState(false,"HeraModalShowLowExchangeCoins","boolean");
  const [showBikeCoinsVals,setShowBikeCoinsVals] = usePersistState(false,"HeraModalShowLowBikeCoins","boolean");
  const [showNoBikeCoinsVals,setShowNoBikeCoinsVals] = usePersistState(false,"HeraModalShowLowNoBikeCoins","boolean");


  const [oec,setOec] = useState(null);
  const [oecDir,setOecDir] = useState(null);
  const [obc,setObc] = useState(null);
  const [obcDir,setObcDir] = useState(null);
  const [onbc,setOnbc] = useState(null);
  const [onbcDir,setOnbcDir] = useState(null);

  let apiKeyId = "";
  if(value){
    apiKeyId += value.owner+"-"+value.apiKeyName+"-"+value.exchange;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    marketOrder(apiKeyId, null, orderType, coin, quoteAsset, amount,);
  }

  const marketOrder = (apiKeyId,bikeId,type,coin,quoteAsset,amount) => {

    let name = "hera-market-order-by-api-key-id"+apiKeyId+"-"+coin+"-"+type;
    let data = {
      apiKeyId: apiKeyId,
      type: type,
      coin: coin,
      quoteAsset: quoteAsset,
      amount: parseFloat(amount),
    }
    if(bikeId) data.bikeId = bikeId;

    console.log("data",data);

    setLoading(true);
    request(name,"/market-order-by-api-key-id","POST", data, {
      then: function(res){toast.success("Order performed")},
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    });
  }

  const addTickerValues = (array,tickers,quoteAsset,showLowVals) => {
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      const arrayItem = array[i];
      let coinValue = 0;
      let symbol = arrayItem.coin+"-"+quoteAsset;
      if(arrayItem.coin === quoteAsset){
        coinValue = arrayItem.amount
      }else if(tickers[symbol]){
        coinValue = arrayItem.amount * tickers[symbol];
      }
      let obj = {
        coin: arrayItem.coin,
        value: parseInt(coinValue),
        amount: arrayItem.amount,
      }
      if(arrayItem.bikeId) obj.bikeId = arrayItem.bikeId;
      if(arrayItem.createdDate) obj.createdDate = arrayItem.createdDate;
      if(!showLowVals && obj.value < 1) continue;

      newArray.push(obj)
    }
    return newArray;
  }

  let exchangeCoins = [];
  let bikeCoins = [];
  let noBikeCoins = [];
  if(value !== null){
    exchangeCoins = addTickerValues(value.exchangeCoins,tickers,quoteAsset,showLowVals);
    bikeCoins = addTickerValues(value.bikeCoins,tickers,quoteAsset,showLowVals);
    if(oec){
      exchangeCoins.sort((a, b) => {
        if(oecDir === "DESC") return ((a[oec] > b[oec]) ? -1 : 1);
        return ((a[oec] > b[oec]) ? 1 : -1);
      });
    }
    if(obc){
      bikeCoins.sort((a, b) => {
        if(obcDir === "DESC") return ((a[obc] > b[obc]) ? -1 : 1);
        return ((a[obc] > b[obc]) ? 1 : -1);
      });
    }
  
    for (let i = 0; i < exchangeCoins.length; i++) {
      const exchangeCoin = clone(exchangeCoins[i]);
      if(exchangeCoin.coin === quoteAsset) continue;
      for (let j = 0; j < bikeCoins.length; j++) {
        const bikeCoin = bikeCoins[j];
        if(exchangeCoin.coin === bikeCoin.coin){
          exchangeCoin.amount = exchangeCoin.amount - bikeCoin.amount;
          exchangeCoin.value = exchangeCoin.value - bikeCoin.value;
        }
      }
      if(!showLowVals && exchangeCoin.value < 1 && exchangeCoin.value > 0){
        continue;
      }
      noBikeCoins.push(exchangeCoin);
    }
    if(onbc){
      noBikeCoins.sort((a, b) => {
        if(onbcDir === "DESC") return ((a[onbc] > b[onbc]) ? -1 : 1);
        return ((a[onbc] > b[onbc]) ? 1 : -1);
      });
    }
  }
  
  const toggleOrder = (clickVal,orderVal,orderFunc,dirVal,dirFunc) => {
    let newOrder = null;
    let newDir = null;
    if(clickVal === orderVal){
      if(dirVal !== "DESC"){
        newOrder = clickVal;
        newDir = "DESC";
      }
    }else{
      newOrder = clickVal;
      newDir = "ASC";
    }
    orderFunc(newOrder);
    dirFunc(newDir);
  }

  const sell = (apiKeyId,bikeId,coin,amount,quoteAsset) => {
    marketOrder(apiKeyId,bikeId,"sell",coin,quoteAsset,amount)
  }
  
  return (
    <Modal className="hera-coin-modal-main" show={show} handleClose={handleClose}>
      <div className="hera-coin-modal">
        <div>Api Key Id: {apiKeyId}</div>
        <div className="block">
          <div>
            <label>Low Val</label>
            <SwitchToggle isOn={showLowVals} onClickFunc={() => {setShowLowVals(!showLowVals)}} />
          </div>
          <div>
            <label>Exchange</label>
            <SwitchToggle isOn={showExchangeCoinsVals} onClickFunc={() => {setShowExchangeCoinsVals(!showExchangeCoinsVals)}} />
          </div>
          <div>
            <label>Bikes</label>
            <SwitchToggle isOn={showBikeCoinsVals} onClickFunc={() => {setShowBikeCoinsVals(!showBikeCoinsVals)}} />
          </div>
          <div>
            <label>No Bikes</label>
            <SwitchToggle isOn={showNoBikeCoinsVals} onClickFunc={() => {setShowNoBikeCoinsVals(!showNoBikeCoinsVals)}} />
          </div>
        </div>
       
        {(showExchangeCoinsVals)?<div className="coins">
          <label>Exchange Coins {exchangeCoins.length}</label>
          <table>
            <tbody>
              <tr>
                <th onClick={() => {toggleOrder("coin",oec,setOec,oecDir,setOecDir)}}>Coin</th>
                <th onClick={() => {toggleOrder("value",oec,setOec,oecDir,setOecDir)}}>Value</th>
                <th onClick={() => {toggleOrder("amount",oec,setOec,oecDir,setOecDir)}}>Amount</th>
                <th>Sell</th>
              </tr>
              {
                exchangeCoins.map((v,i) => {
                  let amount = v.amount;
                return(
                  <tr key={i}>
                    <td>{v.coin}</td>
                    <td>{v.value}</td>
                    <td>{amount}</td>
                    <td><button onClick={() => {sell(apiKeyId,null,v.coin,amount,quoteAsset)}}>Sell</button></td>
                  </tr>
                )})
              }
            </tbody>
          </table>
        </div>:null } 

        {(showNoBikeCoinsVals)?<div className="coins">
          <label>No Bike Coins {noBikeCoins.length}</label>
          <table>
            <tbody>
              <tr>
                <th onClick={() => {toggleOrder("coin",onbc,setOnbc,onbcDir,setOnbcDir)}}>Coin</th>
                <th onClick={() => {toggleOrder("value",onbc,setOnbc,onbcDir,setOnbcDir)}}>Value</th>
                <th onClick={() => {toggleOrder("amount",onbc,setOnbc,onbcDir,setOnbcDir)}}>Amount</th>
                <th>Sell</th>
              </tr>
              {
                noBikeCoins.map((v,i) => {

                return(
                  <tr key={i}>
                    <td>{v.coin}</td>
                    <td>{v.value}</td>
                    <td>{parseFloat(v.amount).toFixed(8)}</td>
                    <td><button onClick={() => {sell(apiKeyId,v.bikeId,v.coin,v.amount,quoteAsset)}}>Sell</button></td>
                  </tr>
                )})
              }
            </tbody>
          </table>
        </div>:null}  

        {(showBikeCoinsVals)?<div className="coins">
          <label>Bike Coins {bikeCoins.length}</label>
          <table>
            <tbody>
              <tr>
                <th onClick={() => {toggleOrder("bikeId",obc,setObc,obcDir,setObcDir)}}>Bike Id</th>
                <th onClick={() => {toggleOrder("coin",obc,setObc,obcDir,setObcDir)}}>Coin</th>
                <th onClick={() => {toggleOrder("value",obc,setObc,obcDir,setObcDir)}}>Value</th>
                <th onClick={() => {toggleOrder("amount",obc,setObc,obcDir,setObcDir)}}>Amount</th>
                <th onClick={() => {toggleOrder("createdDate",obc,setObc,obcDir,setObcDir)}}>Created Date</th>
                <th>Sell</th>
              </tr>
              {
                bikeCoins.map((v,i) => {

                return(
                  <tr key={i}>
                    <td>{v.bikeId}</td>
                    <td>{v.coin}</td>
                    <td>{v.value}</td>
                    <td>{parseFloat(v.amount).toFixed(8)}</td>
                    <td>
                      <div>{DateUtil.dateTimeSince(v.createdDate)}</div>
                      <div>{DateUtil.prettifyDateWithTime(v.createdDate)}</div>
                      <div>{new Date(v.createdDate).getTime()}</div>

                    </td>
                    <td>
                      <button className="button" onClick={() => {sell(apiKeyId,v.bikeId,v.coin,v.amount,quoteAsset)}}>
                        Sell
                      </button>
                    </td>
                  </tr>
                )})
              }
            </tbody>
          </table>
        </div>:null}  

        
                  
        <div className="market-order">
            <label>Market Order Coin</label>
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <label>Type</label>
                  <select value={orderType} onChange={(e) => {setOrderType(e.target.value)}}>
                    <option value="sell">Sell</option>
                    <option value="buy">Buy</option>
                  </select>
                </div>
                <div>
                  <label>Coin</label>
                  <input type="text" value={coin} onChange={(e) => setCoin(e.target.value)} />
                </div>
                <div>
                  <label>Quote Asset</label>
                  <input type="text" value={quoteAsset} onChange={(e) => setQuoteAsset(e.target.value)} />
                </div>
                <div>
                  <label>Amount</label>
                  <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <Button type="submit" status={(loading)?"loading":"" }/>
            </form>
          </div>

      </div>
    </Modal>
  );

};

export default HeraCoinModal;