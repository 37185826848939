import React,{useEffect, useState} from 'react';
import './side-item.scss';
import * as StringUtil from '../../../utils/string';
import { request, unSubRequest } from '../../../utils/request';
import { toast } from 'react-toastify';
import Loading from '../../common/loading/loading';

const SideItem = ({value,active,}) => {

  useEffect(() => {
    return () => {
      unSubRequest("demeter-patch-"+value);
    }
  },[value])

  const [loading,setLoading] = useState(false);

  const updateSymbols = (exchange) => {
    let endpoint = "/symbols";

    let data = {
      exchange: exchange
    }

    setLoading(true);
    request("demeter-patch-"+value,endpoint,"PATCH", data, {
      then: function(res){
        toast.success(StringUtil.prettifyCamelCase(exchange)+" Done");
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  return (
    <div className="side-item" onClick={() => {
      if(active){
        updateSymbols(value);
      }
    }}>
      <div>{StringUtil.prettifyCamelCase(value)}</div>
      <div className="icon">
        <Loading show={loading} style={(active)?"white":"default"}  size="18px"/>
      </div>
    </div>
  );

};

export default SideItem;