import React,{useEffect,useState} from 'react';
import './dashboard.scss';
import './syntax.scss';

import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import Button from '../../elements/button/button';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import usePersistState from '../../../utils/use-persist-state';
import Highlight from "react-highlight";
import RegressionCheck from './regression-check';
import useUserState from '../../../store/user';
import AccountStatusEnum from '../../../enums/account-status';

const Dashboard = () => {

  const {user} = useUserState();
  const [results,setResults] = useState([]);
  const [pingLog,setPingLog] = useState();
  const [loading,setLoading] = useState(false);

  const [algo,setAlgo] = usePersistState(false,"dashAlgo","boolean");
  const [totalAlgo,setTotalAlgo] = usePersistState(false,"dashtotalAlgo","boolean");
  const [pullAlgo,setPullAlgo] = usePersistState(false,"dashpullAlgo","boolean");
  const [trendOptions,setTrendOptions] = usePersistState(false,"dashtrendOptions","boolean");

  useEffect(() => {
    console.log("pingLog",pingLog);
  },[pingLog])

  useEffect(() => {
    console.log("results",results);
  },[results])
  
  useEffect(() => {
    fetchAlgos();
    return () => {
      unSubRequest("fetch-algos");
    }
  },[]);

  const fetchAlgos = () => {
    request("fetch-algos","/fetch-algos/full","GET", {}, {
      then: function(res){ setResults(res.data.res);},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    }) 
  }

  const fetchPingLog = () => {
    setLoading(true);
    request("get-ping-log","/get-ping-log","GET", {}, {
      then: function(res){ setPingLog(res.data.res);},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    }) 
  }
  const requestNewPingLogs = () => {
    setLoading(true);
    request("ping-log","/ping-log","GET", {}, {
      then: function(res){toast.success("Finished Ping Log")},
      catch: function(err){toast.error(err.message);},
      finally: function(){fetchPingLog();setLoading(false);}
    }) 
  }

  return (
    <div className="dashboard">
      <h3>Dashboard</h3>
      <div className="buttons">
        <Button status={(loading)?"loading":""} type="button" onClick={
          () => {fetchPingLog()}}>Fetch Ping Log</Button>
        <Button status={(loading)?"loading":""} type="button" onClick={
          () => {requestNewPingLogs()}}>Request New Ping Logs</Button>
      </div>

      <RegressionCheck/>

      {(user.status >= AccountStatusEnum.SuperAdmin)?
      <div>
        <div className='toggles'>
          <div>
            <label>algo</label>
            <SwitchToggle isOn={algo} onClickFunc={() => {setAlgo(!algo)}} />
          </div>
          <div>
            <label>totalAlgo</label>
            <SwitchToggle isOn={totalAlgo} onClickFunc={() => {setTotalAlgo(!totalAlgo)}} />
          </div>
          <div>
            <label>pullAlgo</label>
            <SwitchToggle isOn={pullAlgo} onClickFunc={() => {setPullAlgo(!pullAlgo)}} />
          </div>
          <div>
            <label>trendOptions</label>
            <SwitchToggle isOn={trendOptions} onClickFunc={() => {setTrendOptions(!trendOptions)}} />
          </div>
        </div>

        <div>
          <label>Algo Versions</label>
          <table className="results">
            <tbody>
              <tr>
                <th className="version">V</th>
                <th className="name">Name</th>
                <th className="notes">Notes</th>
                {(algo)?<th className="algo">algo</th>:null}
                {(totalAlgo)?<th className="algo">totalAlgo</th>:null}
                {(pullAlgo)?<th className="algo">pullAlgo</th>:null}
                {(trendOptions)?<th className="algo">trendOptions</th>:null}

              </tr>
              {
                results.map((v,i) => {

                  let localAlgo = v.algo
                  let localTotalAlgo = v.totalAlgo
                  let localPullAlgo = v.pullAlgo

                  localAlgo = localAlgo.replace(/async.*vars;/gs,"");
                  localAlgo = localAlgo.replace(/d.res.score.*/gs,"");
                  localAlgo = localAlgo.trim();
                  localAlgo = "  "+localAlgo

                  localTotalAlgo = localTotalAlgo.replace(/async.*vars;/gs,"");
                  localTotalAlgo = localTotalAlgo.replace(/return d.*/gs,"");
                  localTotalAlgo = localTotalAlgo.trim();
                  localTotalAlgo = "  "+localTotalAlgo


                  localPullAlgo = localPullAlgo.replace(/async.*vars;/gs,"");
                  localPullAlgo = localPullAlgo.replace(/d.res.*/gs,"");
                  localPullAlgo = localPullAlgo.trim();
                  localPullAlgo = "  "+localPullAlgo


                  // localAlgo = localAlgo.replace(/\s+/g," ");

                  return(
                    <tr key={i}>
                      <td className="version">{v.version}</td>
                      <td className="name">{v.name}</td>
                      <td className="notes">{v.notes}</td>
                      {(algo)?<td className="algo"><Highlight language="javascript">{localAlgo}</Highlight></td>:null}
                      {(totalAlgo)?<td className="algo"><Highlight language="javascript">{localTotalAlgo}</Highlight></td>:null}
                      {(pullAlgo)?<td className="algo"><Highlight language="javascript">{localPullAlgo}</Highlight></td>:null}
                      {(trendOptions)?<td className="algo"><Highlight language="javascript">{v.trendOptions}</Highlight></td>:null}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      :null}
    </div>
  );

};

export default Dashboard;