
import {Environment,EnvironmentEnum} from './env';
import {SnsTypeTextEnum} from '../enums/sns-type';

export const ConfigVariables = {
  PersistLocalStateBuild: 2,
  SnsAuthMethods: [SnsTypeTextEnum.Google],
}

const Config = {
  Common:{
    ApiBaseUrl: "http://localhost:3070",
    ZeusApiBaseUrl: "",
    HeraApiBaseUrl: "",
    ArtemisApiBaseUrl: "",
    ApiBaseSocket: "ws://localhost:3070",
    ZeusApiBaseSocket: "",
    HeraApiBaseSocket: "",
    ArtemisApiBaseSocket: "",
    AppName: "Olympus",
    SupportEmailName: "mail",
    SupportEmailDomain: "olympusbot.com",
  },
  Keys:{
    StripePublicKey: "pk_test_51GqiwwCrETN7D82MGHEvNP7W4p9fULfvCpnEsMyVEBmW4yozC4dNt0kAkv2eiVCTkQiJ2O3sx1kmESXwNrZdLokE00ftcmpH3o",
    // Normal Prices
    // StripeAnnualPriceId: "price_1HC3pyCrETN7D82MG1A3PZcC",
    // StripeMonthlyPriceId: "price_1HC3q8CrETN7D82MoGbwqmMf",
    //Early Bird Prices
    StripeAnnualPriceId: "price_1HCAeUCrETN7D82MEfWzgueZ",
    StripeMonthlyPriceId: "price_1HCAemCrETN7D82MVnoMy5J7",
    
    GoogleAnalytics: "UA-175184641-2",

    RecaptchaPubKey: "6Lf6fvccAAAAAL0eCyCXdJgXz4OVrsiKvPROqRzS",

  },
};
Config.Common.ZeusApiBaseUrl = Config.Common.ApiBaseUrl;
Config.Common.ZeusApiBaseSocket = Config.Common.ApiBaseSocket;
Config.Common.HeraApiBaseUrl = Config.Common.ApiBaseUrl;
Config.Common.HeraApiBaseSocket = Config.Common.ApiBaseSocket;
// Config.Common.ArtemisApiBaseUrl = Config.Common.ApiBaseUrl;
// Config.Common.ArtemisApiBaseSocket = Config.Common.ApiBaseSocket;

if(Environment === EnvironmentEnum.Production){
  Config.Common.ApiBaseUrl = "https://api.olympusbot.com";
  Config.Common.ApiBaseSocket = "wss://api.olympusbot.com";
  Config.Common.ZeusApiBaseUrl = "https://zeus.olympusbot.com";
  Config.Common.ZeusApiBaseSocket = "wss://zeus.olympusbot.com";
  Config.Common.HeraApiBaseUrl = "https://hera.olympusbot.com";
  Config.Common.HeraApiBaseSocket = "wss://hera.olympusbot.com";
  // Config.Common.ArtemisApiBaseUrl = "https://artemis.olympusbot.com";
  // Config.Common.ArtemisApiBaseSocket = "wss://artemis.olympusbot.com";

  // Config.Keys.StripePublicKey = "pk_live_12VositNMno0m6PeI5jJAyZf00URItkqc1";
  // Normal Prices
  // Config.Keys.StripeAnnualPriceId = "price_1GzTG6CrETN7D82MFguc2s56";
  // Config.Keys.StripeMonthlyPriceId = "price_1GzTG5CrETN7D82MRmLFK0Tz";
  // Early Bird Prices
  // Config.Keys.StripeAnnualPriceId = "price_1HCAhfCrETN7D82M9irEnYsy";
  // Config.Keys.StripeMonthlyPriceId = "price_1HCAh5CrETN7D82M2a6qSBgl";
  // Config.Keys.GoogleAnalytics = "UA-175184641-1";
}

export default Config;
