export const dataPieces = {
  price:1,
  volume:2,
  volitility:3,
}
export const comparators = {
  greaterThanOrEqualTo:1,
  lessThanOrEqualTo:2,
  averagesWithin:3,
  averagesOver:4,
}
export const timeIntervals = {
  days:1,
  hours:2,
  minutes:3,
}
export const conditionTypes = {
  buy:1,
  sell:2,
}

export const Interval = {
  Minute5:60*5*1000,
  Minute15:60*15*1000,
  Hour1:60*60*1000,
  Day1:60*60*1000*24,

}