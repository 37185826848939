import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import {Environment,EnvironmentEnum} from '../config/env';


import {
  english,
  japanese,
} from './all';

const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
let language = subdomain;
if(Environment === EnvironmentEnum.Local){
  language = localStorage.getItem("language");
}

export const i18nInitOptions = {
  resources: {
    en: { translation: english },
    ja: { translation: japanese },
    // de: { translation: german },
    // es: { translation: spanish },
    // fr: { translation: french },
    // zh: { translation: chinese },
  },
  lng: (language)?language:"en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nInitOptions);

export default i18n;
export const t = (arg) => i18n.t(arg);