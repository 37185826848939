const EnvironmentEnum = {
  Local: "Local",
  Development: "Development",
  Release: "Release",
  Production: "Production",
};

let env = EnvironmentEnum.Local;
if (process.env.NODE_ENV === 'production') {
  env = EnvironmentEnum.Production;
}

const Environment = env;

module.exports = {Environment,EnvironmentEnum};