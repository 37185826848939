import React,{useEffect, useState} from 'react';
import Loading from '../../common/loading/loading';
import { toast } from 'react-toastify';
import { request, unSubRequest } from '../../../utils/request';
import './athena.scss';
import Button from '../../elements/button/button';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import * as DateUtil from '../../../utils/date';
import * as StringUtil from '../../../utils/string';
import * as NumberUtil from '../../../utils/number';
import {Interval} from '../../../enums/enums';
import usePersistState from '../../../utils/use-persist-state';
import { Environment, EnvironmentEnum } from '../../../config/env';

import {ReactComponent as AthenaSvg} from '../../../css/imgs/greek-gods/athena.svg';

const shortName = {
  closeLinear: "cl",
  closePoly: "cp",
  closeExp: "ce",
  volumeLinear: "ll",
  volumePoly: "lp",
  volumeExp: "le",
  lowLinear: "ll",
  lowPoly: "lp",
  lowExp: "le",
  highLinear: "hl",
  highPoly: "hp",
  highExp: "he",
  lowDiffLinear: "ldl",
  lowDiffPoly: "ldp",
  lowDiffExp: "lde",
  highDiffLinear: "hdl",
  highDiffPoly: "hdp",
  highDiffExp: "hde",
}

const Servers = {
  Local: "",
  Main: "https://api.olympusbot.com",
  // Hera: "https://hera.olympusbot.com",
  // Zeus: "https://zeus.olympusbot.com",
  // Artemis: "https://artemis.olympusbot.com",
}

let ignoreRowItems = [
  "closeArray","percentageArray","openTime","close",
  "volumeArray","prepCloseArray","prepVolumeArray",
  "openTimes","closes",
]

let ignoreSecondRow = [
  "closeArray","percentageCloseArray",
  "volumeArray","percentageVolumeArray",
]

const Athena = () => {

  const epochTimenow = new Date().getTime();

  const [loading,setLoading] = useState(false);
  const [startTime,setStartTime] = useState(DateUtil.epochToDatetimeLocal(epochTimenow,true));
  // const [startTimeEpoch,setStartTimeEpoch] = usePersistState(epochTimenow,"athenaTimenow","number");
  const [startTimeEpoch,setStartTimeEpoch] = useState(epochTimenow);
  const [trends,setTrends] = usePersistState(60,"athenaTrends","number");
  const [ponies,setPonies] = usePersistState(3,"athenaPonies","number");
  const [futures,setFutures] = usePersistState(60,"athenaFutures","number");
  const [minQuoteVolume,setMinQuoteVolume] = usePersistState(0,"athenaMinQuoteVolume","number");
  const [algo,setAlgo] = usePersistState(1,"athenaAlgo","number");

  const [binance,setBinance] = usePersistState(true,"athenaBinance","boolean");
  const [kucoin,setKucoin] = usePersistState(true,"athenaKucoin","boolean");
  // const [poloniex,setPoloniex] = usePersistState(true,"athenaPoloniex","boolean");
  const [ascendex,setAscendex] = usePersistState(true,"athenaAscendex","boolean");

  const [table,setTable] = usePersistState("KlineMinuteFifteen","athenaTable");
  const [interval,setInterval] = usePersistState(Interval.Hour1,"athenaInterval","number");
  const [step,setStep] = usePersistState(Interval.Hour1,"athenaStep","number");
  const [server,setServer] = usePersistState(true,"athenaServer");

  const [results,setResults] = useState([]);
  const [averages,setAverages] = useState({});
  const [resStartTime,setResStartTime] = useState(0);
  const [resKlineTime,setResKlineTime] = useState(0);
  const [resPastTime,setResPastTime] = useState(0);
  const [resFutureTime,setResFutureTime] = useState(0);
  const [picks,setPicks] = useState({});
  const [picksTime,setPicksTime] = useState(0);
  const [picksArguments,setPicksArguments] = useState({});

  const [orderBy,setOrderBy] = useState("symbol");
  const [orderAsc,setOrderAsc] = useState(true);

  useEffect(() => {
    return () => {
      unSubRequest("athena");
      unSubRequest("update-all-klines");
      unSubRequest("athena-view-picks");
    }
  },[])

  const submitHandler = (e) => {
    e.preventDefault();
    fetchResults(startTimeEpoch);
  }

  const next = () => {alterTime("add")}
  const prev = () => {alterTime("sub")}
  const alterTime = (type) => {
    let d = new Date(startTime);
    let increment = 1;
    if(table === "KlineMinuteFive"){
      increment = 5;
    }else if(table === "KlineMinuteFifteen"){
      increment = 15;
    }else if(table === "KlineHourOne"){
      increment = 60;
    }
    if(type === "sub"){
      d.setTime(d.getTime() - (increment * 60 * 1000));
    }else{
      d.setTime(d.getTime() + (increment * 60 * 1000));
    }

    let str = d.getFullYear()+"-";
    str += (((d.getMonth()+1)<10)?"0":"")+(d.getMonth()+1)+"-";
    str += ((d.getDate()<10)?"0":"")+d.getDate()+"T"
    str += ((d.getHours()<10)?"0":"")+d.getHours()+":";
    str += ((d.getMinutes()<10)?"0":"")+d.getMinutes();

    setStartTime(str);
    setStartTimeEpoch(DateUtil.datetimeToEpoch(str,true));
    fetchResults(DateUtil.datetimeToEpoch(str,true));
  }

  const fetchResults = (startTime) => {
    let endpoint = "/athena";

    let exchanges = [];
    if(binance){exchanges.push("binance")};
    if(kucoin){exchanges.push("kucoin")};
    if(ascendex){exchanges.push("ascendex")};

    let data = {
      startTime: startTime,
      trends,
      table,
      interval,
      step,
      futures,
      minQuoteVolume,
      exchanges,
      algo,
    }

    setLoading(true);
    request("athena",endpoint,"POST", data, {
      then: function(res){
        console.log("res",res);

        let obj = res.data.res.results;
        let arr = Object.keys(obj).map((key, i) => {
          let newObj = {symbol: key}
          for(let key2 in obj[key]){
            newObj[key2] = obj[key][key2];
          }
          return newObj;
        });
        // setAverages(res.data.res.averages);
        setResults(arr);
        setResStartTime(res.data.res.startTime)
        setResKlineTime(res.data.res.klineTime)
        setResPastTime(res.data.res.pastTime)
        setResFutureTime(res.data.res.futureTime)
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  const updateKlines = (quick) => {
    let endpoint = "/update-select-klines";
    // if(Environment === EnvironmentEnum.Production){
      endpoint = server + endpoint;
    // }
    console.log("server",server);
    console.log("endpoint",endpoint);

    let exchanges = [];
    if(binance){exchanges.push("binance")};
    if(kucoin){exchanges.push("kucoin")};
    if(ascendex){exchanges.push("ascendex")};

    let data = {
      exchanges: exchanges,
      table: table,
      quick: quick,
    }

    console.log("data",data);
    console.log("endpoint",endpoint);

    setLoading(true);
    request("update-select-klines",endpoint,"POST", data, {
      then: function(res){toast.success("Klines crawled");},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    }) 
  }

  let sortedResults = results;
  sortedResults.sort((a, b) => {
    let aVal = a['symbol'];
    let bVal = b['symbol'];
    if(orderBy.includes("-")){
      let arr = orderBy.split("-");
      if(arr[0] === "futures"){
        aVal = a[arr[0]][arr[1]]['change'];
        bVal = b[arr[0]][arr[1]]['change'];
      }else if(arr[0] === "volatility"){
        aVal = a[arr[0]][arr[1]];
        bVal = b[arr[0]][arr[1]];
      }else{
        let currentIndex = 0;
        if(
          a !== undefined && a[arr[0]] !== undefined && a[arr[0]].length >= 1 && a[arr[0]][0][arr[1]] !== undefined &&
          b !== undefined && b[arr[0]] !== undefined && b[arr[0]].length >= 1 && b[arr[0]][0][arr[1]] !== undefined
        ){
          aVal = a[arr[0]][currentIndex][arr[1]];
          bVal = b[arr[0]][currentIndex][arr[1]];
        }
      }
    }

    return (orderAsc) ? ((aVal > bVal) ? 1 : -1) : ((aVal < bVal) ? 1 : -1)
  })
  // console.log("sortedResults",sortedResults);

  const getPicks = () => {
    let endpoint = "/athena-view-picks";
    
    let exchanges = [];
    if(binance){exchanges.push("binance")};
    if(kucoin){exchanges.push("kucoin")};
    // if(poloniex){exchanges.push("poloniex")};
    if(ascendex){exchanges.push("ascendex")};

    let data = {
      startTime: parseInt(startTimeEpoch),
      trends,
      table,
      interval,
      futures,
      exchanges,
      ponies,
      algo,
      gridStepInterval: step,
      minQuoteVolume,
    }

    setLoading(true);
    request("athena-view-picks",endpoint,"POST", data, {
      then: function(res){
        console.log("res",res.data.res);
        setPicksArguments(res.data.res.arguments);
        setPicks(res.data.res.picks);
        setPicksTime(res.data.res.time);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    }) 
  }

  return (
    <div className="athena">
      <h3>Athena</h3>

      <div className='god-icon'><AthenaSvg/></div>

      <div className="athena-content">

        <form onSubmit={submitHandler}>
          <div className="easy-forms">
            <div className="blocks">
              <div>
                <label>Start Time (UTC)</label>
                <input type="datetime-local" value={startTime} onChange={(e) => {
                  setStartTime(e.target.value);
                  setStartTimeEpoch(DateUtil.datetimeToEpoch(e.target.value,true));
                }}/>
              </div>
              <div>
                <label>Epoch Time</label>
                <input type="text" value={startTimeEpoch} onChange={(e) => {
                  setStartTimeEpoch(e.target.value)
                  setStartTime(DateUtil.epochToDatetimeLocal(e.target.value,true));
                }}/>
              </div>
            </div>

            <div className="blocks short">
              <div>
                <label>Trends</label>
                <input type="number" value={trends} onChange={(e) => {setTrends(e.target.value)}}/>
              </div>
              <div>
                <label>Ponies</label>
                <input type="number" value={ponies} onChange={(e) => {setPonies(e.target.value)}}/>
              </div>
              <div>
                <label>Futures</label>
                <input type="number" value={futures} onChange={(e) => {setFutures(e.target.value)}}/>
              </div>
              <div>
                <label>Min Q Vol</label>
                <input type="number" value={minQuoteVolume} onChange={(e) => {setMinQuoteVolume(e.target.value)}}/>
              </div>
              <div>
                <label>Algo Version</label>
                <input type="number" value={algo} onChange={(e) => {setAlgo(e.target.value)}}/>
              </div>
              
            </div>
            <div className="blocks">
              <div>
                <label>Binance</label>
                <SwitchToggle isOn={binance} onClickFunc={() => {setBinance(!binance)}}/>
              </div>
              <div>
                <label>Kucoin</label>
                <SwitchToggle isOn={kucoin} onClickFunc={() => {setKucoin(!kucoin)}}/>
              </div>
              {/* <div>
                <label>Poloniex</label>
                <SwitchToggle isOn={poloniex} onClickFunc={() => {setPoloniex(!poloniex)}}/>
              </div> */}
              <div>
                <label>Ascendex</label>
                <SwitchToggle isOn={ascendex} onClickFunc={() => {setAscendex(!ascendex)}}/>
              </div>
              <div>
                <label>Table</label>
                <select value={table} onChange={(e) => {setTable(e.target.value)}}>
                  <option value="KlineMinuteOne">Kline Minute One</option>
                  <option value="KlineMinuteFive">Kline Minute Five</option>
                  <option value="KlineMinuteFifteen">Kline Minute Fifteen</option>
                  <option value="KlineHourOne">Kline Hour One</option>
                </select>
              </div>
              <div>
                <label>Trend Interval</label>
                <select value={interval} onChange={(e) => {setInterval(e.target.value)}}>
                  {Object.keys(Interval).map((key, i) => (
                      <option key={i} value={Interval[key]}>{key}</option>
                  ))}
                </select>
              </div>
              <div>
                <label>Step</label>
                <select value={step} onChange={(e) => {setStep(e.target.value)}}>
                  {Object.keys(Interval).map((key, i) => (
                    <option key={i} value={Interval[key]}>{key}</option>
                  ))}
                </select>
              </div>
              <div>
                <label>Update Full Klines</label>
                <Button type="button" status={(loading)?"loading":""} onClick={() => {updateKlines(false)}}>
                  Update Full Klines
                </Button>
              </div>
              <div>
                <label>Update Quick Klines</label>
                <Button type="button" status={(loading)?"loading":""} onClick={() => {updateKlines(true)}}>
                  Update Quick Klines
                </Button>
              </div>
              <div>
                <label>Server </label>
                <select value={server} onChange={(e) => {setServer(e.target.value)}}>
                  {Object.keys(Servers).map((key, i) => (
                    <option key={i} value={Servers[key]}>{key}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="buttons">
              <Button type="submit" value={"Analyze"}/>
              <Button type="button" onClick={() => {prev()}}>Prev</Button>
              <Button type="button" onClick={() => {next()}}>Next</Button>
              <Button type="button" onClick={() => {getPicks()}}>View Picks</Button>
            </div>
          </div>
        </form>

        <Loading show={loading} />

        <div className="picks">
          <label>Picks {picksTime} <button onClick={() => {console.log("picksArguments",picksArguments)}}>Console Log Picks Arguments</button></label>
          <div className="picks-list">
          {
            Object.keys(picks).map((key, i) => {
              let pickExchange = picks[key];
              if(pickExchange.length === 0){return null}
              return (
                <div className="pick-exchange" key={i}>
                  <label>{key}</label>
                  <table>
                    <tbody>
                      <tr>
                        <th>Symbol</th>
                        <th>Score</th>
                        <th>RC</th>
                      </tr>
                      {
                        Object.keys(pickExchange).map((key2, i2) => {
                          const pick = pickExchange[key2];
                          // console.log("pick",pick);
                          return(
                            <tr className="pick" key={i2}>
                              <td className="symbol">{pick.symbol.replace("-USDT","")}</td>
                              <td className="score">{pick.score}</td>
                              <td className="real-change">{pick.realChange}%</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <button onClick={() => {console.log("pickExchange",pickExchange)}}>Console Log Results</button>
                  
                </div>
              )
            })
          }
          </div>
        </div>

        <div className="result-times">
          <div>
            <div>Start Time</div>
            <input type="text" value={resStartTime} disabled={true}/>
            <input type="datetime-local" value={DateUtil.epochToDatetimeLocal(resStartTime,true)} disabled={true}/>
          </div>
          <div>
            <div>Kline Time</div>
            <input type="text" value={resKlineTime} disabled={true}/>
            <input type="datetime-local" value={DateUtil.epochToDatetimeLocal(resKlineTime,true)} disabled={true}/>
          </div>
          <div>
            <div>Past Time</div>
            <input type="text" value={resPastTime} disabled={true}/>
            <input type="datetime-local" value={DateUtil.epochToDatetimeLocal(resPastTime,true)} disabled={true}/>
          </div>
          <div>
            <div>Future Time</div>
            <input type="text" value={resFutureTime} disabled={true}/>
            <input type="datetime-local" value={DateUtil.epochToDatetimeLocal(resFutureTime,true)} disabled={true}/>
          </div>
        </div>

        <div>
          <label>Coins: {sortedResults.length}</label>
        </div>

        <table className="symbols">
          <tbody>
            <tr className="header">
              {(sortedResults.length > 0)?Object.keys(sortedResults[0]).map((key, i) => {
                if(ignoreRowItems.indexOf(key) !== -1) return null;
                if(key === "symbol"){
                  return(
                    <th key={i} className="clickable hlo" onClick={() => {setOrderBy("symbol");setOrderAsc((orderBy === "symbol")?(!orderAsc):false)}}>
                      <div className="main-header">Symbol</div>
                    </th>
                  )
                }
                if(key === "futures"){
                  return(
                    <th key={i}>
                      <div className="main-header">Futures</div>
                      <div className="sub-headers">
                      {
                        Object.keys(sortedResults[Object.keys(sortedResults)[0]][key]).map((key2,i2) => {
                          let str = key+"-"+key2;
                          return (
                            <div className="sub-header clickable hlo" key={i2} title={key2}
                              onClick={() => {setOrderBy(str);setOrderAsc((orderBy === str)?(!orderAsc):false)}}
                              >
                              {parseInt(key2)+1}
                            </div>
                          )
                        })
                      }
                      </div>
                    </th>
                  )
                }
                if(key === "volatility"){
                  let str1 = key+"-lowCloseDiff";
                  let str2 = key+"-highCloseDiff";
                  return(
                    <th key={i}>
                      <div className="main-header">volatility</div>
                      <div className="sub-headers">
                        <div className="sub-header clickable hlo" title="Low to Close Price difference. 100 = same as close"
                            onClick={() => {setOrderBy(str1);setOrderAsc((orderBy === str1)?(!orderAsc):false)}}>
                              low
                        </div>
                        <div className="sub-header clickable hlo" title="High to Close Price difference. 100 = same as close"
                            onClick={() => {setOrderBy(str2);setOrderAsc((orderBy === str2)?(!orderAsc):false)}}>
                              high
                        </div>
                      </div>
                    </th>
                  )
                }
                if(key === "openTime" || key === "close" || key === "closeArray" || key === "prepCloseArray"){
                  return(
                    <th key={i}>
                      <div className="main-header">{key}</div>
                    </th>
                  )
                }
                return(
                  <th key={i}>
                    <div className="main-header">{key}</div>
                    <div className="sub-headers">
                      {
                        Object.keys(sortedResults[Object.keys(sortedResults)[0]][key][0]).map((key2,i2) => {
                          let str = key+"-"+key2;
                          if(ignoreSecondRow.indexOf(key2) !== -1) return null;
                          return (
                            <div className="sub-header clickable hlo" key={i2} title={key2}
                              onClick={() => {setOrderBy(str);setOrderAsc((orderBy === str)?(!orderAsc):false)}}
                              >
                              {shortName[key2]}
                            </div>
                          )
                        })
                      }
                    </div>
                  </th>
              )}):<th></th>}
              <th>Inspect</th>
            </tr>
            {/* <tr className="symbol">
              <td>Averages</td>
              {Object.keys(averages).map((key, i) => {
                if(key === "futures"){
                  return(
                    <td className="layer-one" key={i}>
                      <div className="layer-two">
                        {averages[key].map((val2, i2) => {
                          return(
                            <div key={i2} className="holder">
                              {Math.round(val2*100)/100}
                            </div>
                          )
                        })} 
                      </div>
                    </td>
                  );
                }
                if(key === "volatility"){
                  return (
                    <td className="layer-one" key={i}>
                      <div className="layer-two">
                        <div className="holder">{Math.round(averages[key]['lowCloseDiff']*100)/100}</div>
                        <div className="holder">{Math.round(averages[key]['highCloseDiff']*100)/100}</div>
                      </div>
                    </td>
                  )
                }
                return(
                  <td className="layer-one" key={i}>
                    <div className="layer-two">
                      {Object.keys(averages[key]).map((key2, i2) => {
                        return(
                          <div key={i2} className="">
                            {Math.round(averages[key][key2]*100)/100}
                          </div>
                        )
                      })} 
                    </div>
                  </td>
                )
              })}
            </tr> */}
            {sortedResults.map((key, i) => (
              <tr className="symbol" key={i}>
                {Object.keys(sortedResults[i]).map((key2, i2) => {
                  if(ignoreRowItems.indexOf(key2) !== -1) return null;
                  if(key2 === "symbol") return(<td key={i2}>{sortedResults[i][key2]}</td>);

                  
                  if(key2 === "futures"){
                    return(
                      <td className="layer-one" key={i2}>
                        <div className="layer-two">
                          {Object.keys(sortedResults[i][key2]).map((key3, i3) => {
                            return(
                              <div key={i3} className={"holder "+key3}>
                                {Math.round(sortedResults[i][key2][key3]['change']*100)/100}
                              </div>
                            )
                          })} 
                        </div>
                      </td>
                    );
                  }
                  if(key2 === "volatility"){
                    return(
                      <td className="layer-one" key={i2}>
                        <div className="layer-two">
                          <div className="holder">{sortedResults[i][key2]['lowCloseDiff']}</div>
                          <div className="holder">{sortedResults[i][key2]['highCloseDiff']}</div>
                        </div>
                      </td>
                    );
                  }
                  if(key2 === "openTime" || key2 === "close"){
                    return(
                      <td className="layer-one" key={i2}>
                      {key2}
                        <div className="layer-two">
                          <div className="holder-big">{sortedResults[i][key2]}</div>
                        </div>
                      </td>
                    );
                  }
                  return(
                    <td className="layer-one" key={i2}>
                      <div className="layer-two">
                        {Object.keys(sortedResults[i][key2][0]).map((key3, i3) => {
                          if(ignoreSecondRow.indexOf(key3) !== -1) return null;
                          return(
                            <div key={i3} className="">
                              {
                                sortedResults[i][key2].map((v,i4) => {
                                  let className = "active";
                                  let value = sortedResults[i][key2][i4][key3];
                                  if(key3 === "volumeLinear" || key3 === "volumeExp" || key3 === "volumePoly"){
                                    value = Math.round(value);
                                    value = NumberUtil.numberWithCommas(value);
                                  }
                                  return(
                                    <div className={"holder "+className+" "+key3} key={i4}>
                                      {value}
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )
                        })} 
                      </div>
                    </td>
                  )
                })}
                <td><button onClick={() => {console.log("symbol",sortedResults[i])}}>Console Log</button></td>
              </tr>
            ))}  
          </tbody>
        </table>
      </div>
    </div>
  );

};

export default Athena;