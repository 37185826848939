import React from 'react';
import './home.scss';

const Home = ({history}) => {

  console.log("history",history)

  return (
    <div className="home">
      Home Page
    </div>
  );
};

export default Home;