import React from 'react';
import HephaestusSimulator from './hephaestus-simulator';
import './hephaestus.scss';

import {ReactComponent as HephaestusSvg} from '../../../css/imgs/greek-gods/hephaestus.svg';

const Hephaestus = () => {

  return (
    <div className="hephaestus">
      <h3>Hephaestus</h3>
      <div className='god-icon'><HephaestusSvg/></div>
      <HephaestusSimulator />
    </div>
  );

};

export default Hephaestus;