import React,{useState,useEffect} from 'react';
import './zeus-modal.scss';
import Modal from '../../common/modal/modal';
import Button from '../../elements/button/button';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import { clone } from '../../../utils/clone';

const ZeusModal = ({show,handleClose}) => {

  const [loading,setLoading] = useState(false);
  const [buyDepths,setBuyDepths] = useState([]);
  const [sellDepths,setSellDepths] = useState([]);

  useEffect(() => {
    return () => {
      unSubRequest("zeus-get-depths");
      unSubRequest("zeus-set-depths");
    }
  },[])

  useEffect(() => {
    getDepths();
  },[])
  
  const getDepths = () => {
    setLoading(true);
    request("zeus-update-depths","/zeus-update-depths","POST", {}, {
      then: function(res){
        setBuyDepths(res.data.res.buyDepth);
        setSellDepths(res.data.res.sellDepth);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){ setLoading(false); }
    });
  }

  const saveDepths = () => {

    let data = {
      buyDepths,
      sellDepths
    }
    setLoading(true);
    request("zeus-set-depths","/zeus-set-depths","POST", data, {
      then: function(res){
        getDepths();
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){ setLoading(false); }
    });
  }


  const addRow = (type) => {
    let item = {
      fall: 0,
      amount: 0,
      lagAmount: 0.999,
      lagPrice: 0.999,
      priceHover: 0,
    }
    let cloneBuys = clone(buyDepths);
    let cloneSells = clone(sellDepths);

    if(type === "buyDepth") cloneBuys.push(item);
    if(type === "sellDepth") cloneSells.push(item);

    setBuyDepths(cloneBuys);
    setSellDepths(cloneSells);
  }

  const deleteDepth = (type,index) => {
    let cloneBuys = clone(buyDepths);
    let cloneSells = clone(sellDepths);

    if(type === "buyDepth") cloneBuys.splice(index, 1)
    if(type === "sellDepth") cloneSells.splice(index, 1)

    setBuyDepths(cloneBuys);
    setSellDepths(cloneSells);
  }

  const editDepth = (type,index,name,value) => {
    let cloneBuys = clone(buyDepths);
    let cloneSells = clone(sellDepths);

    if(type === "buyDepth") cloneBuys[index][name] = value;
    if(type === "sellDepth") cloneSells[index][name] = value;

    setBuyDepths(cloneBuys);
    setSellDepths(cloneSells);
  }

  return (
    <Modal className="zeus-modal-container" show={show} handleClose={handleClose}>
      <div className="zeus-modal">
        <h4>Edit Depths</h4>
        <div className="buy-depths">
          <label>Buy Depths</label>
          <div className="depth-container">
          {
            buyDepths.map((v,i) => {
              return(
                <div key={i}>
                  <div>
                    <label>Fall</label>
                    <input type="text" value={v.fall} onChange={(e) => {editDepth("buyDepth",i,"fall",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Amount</label>
                    <input type="text" value={v.amount} onChange={(e) => {editDepth("buyDepth",i,"amount",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Lag Amount</label>
                    <input type="text" value={v.lagAmount} onChange={(e) => {editDepth("buyDepth",i,"lagAmount",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Lag Price</label>
                    <input type="text" value={v.lagPrice} onChange={(e) => {editDepth("buyDepth",i,"lagPrice",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Price Hover</label>
                    <input type="text" value={v.priceHover} onChange={(e) => {editDepth("buyDepth",i,"priceHover",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Delete</label>
                    <Button onClick={() => {deleteDepth("buyDepth",i)}}>Delete</Button>
                  </div>
                </div>
              )
            })
          }
          <Button status="add" onClick={() => {addRow("buyDepth")}}>New Buy Depth</Button>
          </div>
        </div>
        <div className="sell-depths">
          <label>Sell Depths</label>
          <div className="depth-container">
          {
            sellDepths.map((v,i) => {
              return(
                <div key={i}>
                  <div>
                    <label>Fall</label>
                    <input type="text" value={v.fall} onChange={(e) => {editDepth("sellDepth",i,"fall",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Amount</label>
                    <input type="text" value={v.amount} onChange={(e) => {editDepth("sellDepth",i,"amount",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Lag Amount</label>
                    <input type="text" value={v.lagAmount} onChange={(e) => {editDepth("sellDepth",i,"lagAmount",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Lag Price</label>
                    <input type="text" value={v.lagPrice} onChange={(e) => {editDepth("sellDepth",i,"lagPrice",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Price Hover</label>
                    <input type="text" value={v.priceHover} onChange={(e) => {editDepth("sellDepth",i,"priceHover",e.target.value)}}/>
                  </div>
                  <div>
                    <label>Delete</label>
                    <Button onClick={() => {deleteDepth("sellDepth",i)}}>Delete</Button>
                  </div>
                </div>
              )
            })
          }
            <Button status="add" onClick={() => {addRow("sellDepth")}}>New Sell Depth</Button>
          </div>
        </div>
        <div className="buttons">
          <Button status={(loading)?"loading":"refresh"} onClick={() => {getDepths()}}>Refresh Current Depths</Button>
          <Button status={(loading)?"loading":"save"} onClick={() => {saveDepths()}}>Save Current Depths</Button>
        </div>
        
      </div>
    </Modal>
    
  );

};

export default ZeusModal;