import React,{useEffect, } from 'react';
import { toast } from 'react-toastify';
import { request, unSubRequest } from '../../../utils/request';
import './demeter.scss';
import * as DateUtil from '../../../utils/date';
import * as NumberUtil from '../../../utils/number';
import Table from '../..//elements/table/table';
import SideItem from './side-item';

import {ReactComponent as DemeterSvg} from '../../../css/imgs/greek-gods/demeter.svg';

const Demeter = () => {

  useEffect(() => {
    return () => {
      unSubRequest("demeter-toggle");
    }
  },[]);

  const toggleSymbol = (symbol,exchange,toggle,) => {
    let endpoint = "/symbol-toggle";

    let data = {
      exchange: exchange,
      symbol: symbol,
      toggle: toggle,
    }

    // setLoading(true);
    request("demeter-toggle",endpoint,"PATCH", data, {
      then: function(res){
        // toast.success("Done:"+exchange+" "+symbol+" "+toggle);
        // refreshTable();
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        // setLoading(false);
      }
    }) 
  }

  // const deleteSymbol = () => {
  //   toast.info("I need code");
  //   // refreshTable()
  // }

  const sideItems = [
    {display: "All",conditions:[]},
    {
      display: "ascendex",value:"ascendex", conditions:[{action: "=",column:"exchange", table:"symbols",value: "ascendex",}], 
      custom:({active,value}) => <SideItem active={active} value={value} />,
    },{
      display: "binance", value:"binance", conditions:[{action: "=",column:"exchange", table:"symbols",value: "binance",}], 
      custom:({active,value}) => <SideItem active={active} value={value} />
    },{
      display: "ftx", value:"ftx", conditions:[{action: "=",column:"exchange", table:"symbols",value: "ftx",}], 
      custom:({active,value}) => <SideItem active={active} value={value} />
    },{
      display: "gateio", value:"gateio", conditions:[{action: "=",column:"exchange", table:"symbols",value: "gateio",}], 
      custom:({active,value}) => <SideItem active={active} value={value} />
    },{
      display: "kucoin", value:"kucoin", conditions:[{action: "=",column:"exchange", table:"symbols",value: "kucoin",}], 
      custom:({active,value}) => <SideItem active={active} value={value} />
    },{
      display: "okex", value:"okex", conditions:[{action: "=",column:"exchange", table:"symbols",value: "okex",}], 
      custom:({active,value}) => <SideItem active={active} value={value} />
    },{
      display: "poloniex", value:"poloniex", conditions:[{action: "=",column:"exchange", table:"symbols",value: "poloniex",}], 
      custom:({active,value}) => <SideItem active={active} value={value} />
    },
  ];

  const columns = [
    {table: "symbols",value: "id",hide: true, index:true},
    {table: "symbols",value: "symbol",default: true,index:true},
    {table: "symbols",value: "baseAsset",index:true},
    {table: "symbols",value: "quoteAsset",index:true},
    {table: "symbols",value: "exchangeActive",index:true,
      custom: ({v,cv}) => (
        <div className={"label-"+((v[cv]?"active":"inactive"))}>
          {(v[cv]?"Active":"Inactive")}
        </div>
      ),
    },
    {table: "symbols",value: "botActive",ignoreRowClick: true,index:true,
      custom: ({v,cv,i,uppdateResultState}) => (
        <div className={"clickable label-"+((v[cv]?"active":"inactive"))} onClick={() => {
          let newValue = (v[cv]?0:1);
          uppdateResultState(i,cv,newValue);
          toggleSymbol(v["symbolsSymbol"],v["symbolsExchange"],newValue);
        }}>
          {(v[cv]?"Active":"Inactive")}
        </div>
      ),
    },
    {table: "symbols",value: "quoteAssetVolume",index:true,
      custom: ({v,cv}) => <div>{NumberUtil.numberWithCommas(Math.round(v[cv]*100)/100)}</div>,
    },
    {table: "symbols",value: "exchange",index:true
    },
    {table: "symbols",value: "createdDate",index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,
    },
    {table: "symbols",value: "lastUpdatedDate",index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,
    },
  ]

  return (
    <div className="demeter">
      <h3>Demeter</h3>

      <div className='god-icon'><DemeterSvg/></div>
      <div className='demeter-content'>
        <Table 
          displayName="Demeter"
          name="DemeterTable"
          sideItems={sideItems}
          joinOptions={[]}
          rowOnClickFunc={(value) => {
            
          }}
          columns={columns}
          limit={250}
        />
      </div>



    </div>
  );

};

export default Demeter;