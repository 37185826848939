import React,{ useEffect,useState } from 'react';
import Button from '../../elements/button/button';
import './hera.scss';
import {request, unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import * as DateUtil from '../../../utils/date';
import * as StringUtil from '../../../utils/string';
import Loading from '../../common/loading/loading';
import HeraResultItem from './hera-result-item';
import HeraCoinModal from './hera-coin-modal';

import useUserState from '../../../store/user';

import {ReactComponent as HeraSvg} from '../../../css/imgs/greek-gods/hera.svg';

const Hera = () => {

  const {user} = useUserState();
  const [results,setResults] = useState({});
  const [loading,setLoading] = useState(false);
  const [showModal,setShowModal] = useState(false);
  const [viewBikeCoin,setViewBikeCoin] = useState(null);
  const [tickers,setTickers] = useState({});
  const [tickersLastUpdated,setTickersLastUpdated] = useState(0);

  useEffect(() => {
    console.log("user",user);
  },[user])

  // useEffect(() => {
  //   console.log("results",results);
  // },[results]);

  // useEffect(() => {
  //   console.log("tickers",tickers);
  // },[tickers])

  useEffect(() => {
    refreshHera();
    return () => {
      unSubRequest("hera-start");
      unSubRequest("hera-stop");
      unSubRequest("fetch-tickers");
    }
  },[]);

  useEffect(() => {
    fetchTickers();
    const interval = setInterval(fetchTickers, 1000);
    return () => clearInterval(interval)
  },[])

  useEffect(() => {
    setShowModal(viewBikeCoin !== null)
  },[viewBikeCoin])

  const startHera = () => {
    request("hera-start","/hera-start","POST", {}, {
      then: function(res){toast.success("Hera Started");},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  } 

  const stopHera = () => {
    request("hera-stop","/hera-stop","POST", {}, {
      then: function(res){toast.info("Hera Stopped");},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const fetchTickers = () => {
    request("fetch-tickers","/fetch-tickers","GET", {}, {
      then: function(res){
        setTickers(res.data.res.tickers);
        setTickersLastUpdated(res.data.res.lastUpdatedTickers);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const getHeraStatus = () => {
    request("hera-status","/hera-status","POST", {}, {
      then: function(res){
        let lastUpdate = res.data.res.lastUpdate;
        lastUpdate = DateUtil.dateTimeSince(lastUpdate,1);
        toast.info("Hera Last update: "+lastUpdate); 
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const refreshHera = () => {
    setLoading(true);
    request("hera-refresh","/hera-refresh","POST", {}, {
      then: function(res){ 
        console.log("res.data.res",res.data.res);
        setResults(res.data.res); 
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){ setLoading(false); }
    });
  }

  // 

  return (
    <div className="hera">
      <div className="header">
        <h3>Hera</h3>
        <Button type="button" onClick={() => {startHera()}}>Start</Button>
        <Button type="button" onClick={() => {stopHera()}}>Stop</Button>
        <Button type="button" onClick={() => {getHeraStatus()}}>Status</Button>
        <Button type="button" onClick={() => {refreshHera()}}>Refresh</Button>
      </div>

      <div className='god-icon'><HeraSvg/></div>

      <div className="hera-content">

        <div>
          <label>Last Updated Tickers</label>
          <div>{DateUtil.dateTimeSince(tickersLastUpdated,1)}</div>
        </div>

        <Loading show={loading} />
        <div className="results">
          {
            Object.keys(results).map(function(ownerKey, i) {
            
              return(
                <div className="result" key={i}>
                  <label className="owner">{StringUtil.capitalize(ownerKey)}</label>
                  <div>
                    <table>
                      <tbody>
                      <tr>
                        <th>Identifier</th>
                        <th>Exchange Value</th>
                        <th>Bike Value</th>
                        <th>USDT Value</th>
                        <th>Bikes</th>
                        <th>Avg Amount</th>
                        <th>Coins</th>
                        <th>Log</th>
                      </tr>
                      {results[ownerKey].map((v2, i2) => <HeraResultItem key={i2} value={v2} setViewBikeCoin={setViewBikeCoin} tickers={tickers}/> )}
                      </tbody>
                    </table>
                  </div> 
                </div>
              )
            })
          }
          
        </div>
        <HeraCoinModal 
          value={viewBikeCoin}
          show={showModal} 
          handleClose={() => {setShowModal(false)}}
          tickers={tickers}/>
      </div>
    </div>
  );

};

export default Hera;