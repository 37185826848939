import React,{useState,useEffect} from 'react';
import './reset-password.scss';

import useUserState from '../../../../store/user';
import {request,unSubRequest} from '../../../../utils/request';
import CenterDiv from '../../../common/center-div/center-div';
import PasswordInput from '../../../elements/password-input/password-input';
import Button from '../../../elements/button/button';
import Loading from '../../../common/loading/loading';
import {toast} from 'react-toastify';
import { Redirect } from 'react-router';
import {Helmet} from 'react-helmet';
import Seo from '../../../../data/seo';

// Mostly the same as verify-email - separate to keep me sane
const ResetPassword = ({tokenArg}) => {

  const {setUser} = useUserState();
  const [email,setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [buttonStatus,setButtonStatus] = useState("");
  const [loading,setLoading] = useState(false);
  const [verifyingToken,setVerifyingToken] = useState(false);
  const [verifiedToken,setVerifiedToken] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");
  const [emailSent,setEmailSent] = useState(false);
  const [token] = useState(tokenArg);
  const [redirect,setRedirect] = useState(false);

  useEffect(() => {
    return () => {
      unSubRequest("reset-password-get");
      unSubRequest("reset-password-request");
      unSubRequest("reset-password-patch");
    }
  },[]);

  useEffect(() => {
    if(token !== undefined && token !== null && token !== ""){
      const endpoint = "/reset-password/"+token;
      setVerifyingToken(true);
      request("reset-password-get",endpoint,"GET", {}, {
        then: function(res){
          setVerifiedToken(true);
        },
        catch: function(err){
          setErrorMessage(err.message);
        },
        finally: function(){
          setVerifyingToken(false);
        }
      });
    }
  },[token]);

  useEffect(() => {
    setButtonStatus((loading)?"loading":"");
  },[loading]);

  const handleRequestEmailSubmit = (e) => {
    e.preventDefault();
    if(email === ""){
      return false;
    }
    const data = {
      email: email
    };
    setLoading(true);
    setErrorMessage("");
    setEmailSent(false);
    request("reset-password-request","/reset-password-request","POST", data, {
      then: function(res){
        setEmailSent(true);
      },
      catch: function(err){
        setErrorMessage(err.message);
      },
      finally: function(){
        setLoading(false);
      }
    });
  }

  const handleSetNewPassword = (e) => {
    e.preventDefault();
    if(newPassword === ""){
      return false;
    }
    const data = {
      password: newPassword,
      token: token,
    };
    setLoading(true);
    setErrorMessage("");
    request("reset-password-patch","/reset-password","PATCH", data, {
      then: function(res){
        toast.success("Successfully Set New Password!");
        setUser({
          id: res.data.res.user.id,
          username: res.data.res.user.username,
          email: res.data.res.user.email,
          status: res.data.res.user.status,
          passSet: res.data.res.user.passSet,
          joinDate: res.data.res.user.joinDate,
          auth: {
            id: res.data.res.auth.id,
            token: res.data.res.auth.token,
          }
        });
        setRedirect(true);
      },
      catch: function(err){
        setErrorMessage(err.message);
      },
      finally: function(){
        setLoading(false);
      }
    });
  }

  if(redirect){
    return <Redirect to="/my-account" />
  }

  if(verifyingToken){
    return(
      <CenterDiv>
        <div className="reset-password">
          <h1>Verifying Token </h1>
          <Loading show={true} size={"48px"}/>
        </div>
      </CenterDiv>
    )
  }

  if(verifiedToken){
    return(
      <CenterDiv>
        <div className="verify-email">
          <h1>Set New Password</h1>
          {(errorMessage !== "")?<ul className="errors">
            <li>{errorMessage}</li>
          </ul>:""}
          <form onSubmit={handleSetNewPassword}>
            <label>New Password</label>
            <PasswordInput disabled={loading} value={newPassword} placeholder="New Password" onChange={(e) => {setNewPassword(e.target.value)}}/>
            <Button disabled={loading} type="submit" status={buttonStatus} value="Reset Password" />
          </form>
        </div>
      </CenterDiv>
    )
  }

  return (
    <CenterDiv>
      <div className="reset-password">
        <Helmet>
          <title>{Seo.ResetPassword.Title}</title>
          <meta name="description" content={Seo.ResetPassword.Desc} />
          <meta name="keywords" content={Seo.ResetPassword.Keywords} />
        </Helmet>
        <h1>Reset Password</h1>
        {(emailSent)?<ul className="success">
          <li>Reset Password Email sent! Please check your email. </li>
        </ul>:""}
        {(errorMessage !== "")?<ul className="errors">
          <li>{errorMessage}</li>
        </ul>:""}
        <p>Forgot your password? Type in your email below to receive a reset link.</p>
        <form onSubmit={handleRequestEmailSubmit}>
          <label>Email</label>
          <input disabled={loading} type="email" placeholder="Email" value={email} onChange={(e) => {setEmail(e.target.value)}} />
          <Button disabled={loading} type="submit" status={buttonStatus} value="Reset Password" />
        </form>
      </div>
    </CenterDiv>
  );
};

export default ResetPassword;