import React,{useEffect, useState}  from 'react';
import './navi.scss';

import NaviMain from './navi-main';
import NaviMobile from './navi-mobile';
import NaviGrayOutScreen from './navi-gray-out-screen';
import NaviLanguageModal from './navi-language-modal';

import useHeaderState from '../../store/header';

const Navi = () => {

  const [toggleWidth,setToggleWidth] = useState((localStorage.naviToggleWidth === "true")?true:false);
  const {header} = useHeaderState();
  const [showLanguageModal,setShowLanguageModal] = useState(false);

  const menuClass = (header.menuOpen) ? " open" : " close";
  const toggleClass = (toggleWidth)?" toggle-width-active":"";

  useEffect(() => {
    localStorage.setItem("naviToggleWidth",toggleWidth);
  },[toggleWidth]);

  return (
    <div className={"navi"+menuClass+toggleClass}>
      <NaviMobile />
      <NaviMain setShowLanguageModal={setShowLanguageModal} menuClass={menuClass} toggleWidth={toggleWidth} setToggleWidth={setToggleWidth} />
      <NaviGrayOutScreen />
      <NaviLanguageModal show={showLanguageModal} handleClose={()=>{setShowLanguageModal(false)}}/>
    </div>
  );

};

export default Navi;