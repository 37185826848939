import React from 'react';
import './navi-logo.scss';
import { Link } from 'react-router-dom';
import {ReactComponent as LogoSvg} from '../../css/imgs/logo.svg';

const NaviLogo = () => {

  return (
    <div className={"navi-logo"}>
      <Link to="/">
        <LogoSvg />
        <span className="text">Olympus</span>
      </Link>
    </div>
  );

};

export default NaviLogo;