import React from 'react';
import './css/styles/app.scss';
import './css/styles/common.scss';
import './css/styles/toastify.scss';

import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import history from './store/history';

import Navi from './components/navi/navi';
import EntryLogin from './components/views/entry/entry-login';
import EntryCreateAccount from './components/views/entry/entry-create-account';
import Totp from './components/views/entry/totp/totp';
import TotpReset from './components/views/entry/totp/totp-reset';
import NewDevice from './components/views/entry/new-device/new-device';
import Dashboard from './components/views/dashboard/dashboard';
import Home from './components/views/home/home';
import NotFound from './components/views/not-found/not-found';
import MyAccount from './components/views/my-account/my-account';
import PrivacyPolicy from './components/views/legal/privacy-policy/privacy-policy';
import TermsOfUse from './components/views/legal/terms-of-use/terms-of-use';
import Swiperino from './components/views/swiperino/swiperino';
import Test from './components/views/test/test';
import TestTwo from './components/views/test-two/test-two';

import Zeus from './components/views/zeus/zeus';
import Hera from './components/views/hera/hera';
import Hephaestus from './components/views/hephaestus/hephaestus';
import Athena from './components/views/athena/athena';
import Artemis from './components/views/artemis/artemis';
import Hermes from './components/views/hermes/hermes';
import Achilles from './components/views/achilles/achilles';
import Demeter from './components/views/demeter/demeter';
import Aphrodite from './components/views/aphrodite/aphrodite';
import Ares from './components/views/ares/ares';
import SnsAuth from './components/views/entry/sns-auth/sns-auth';


import ResetPassword from './components/views/email-related/reset-password/reset-password';
import VerifyEmail from './components/views/email-related/verify-email/verify-email';
import ChangeEmail from './components/views/email-related/change-email/change-email';
import GoogleAuth from './components/views/auth/google-auth';
import FacebookAuth from './components/views/auth/facebook-auth';

// import Admin from './components/views/admin/admin';
// import Paypal from './components/views/admin/paypal/paypal';
// import Stripe from './components/views/admin/stripe/stripe';

import LoginRoute from './components/common/routes/login-route';
import AuthRoute from './components/common/routes/auth-route';
import NormRoute from './components/common/routes/norm-route';
import ScrollToTop from './components/common/routes/scroll-to-top';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer,Flip } from 'react-toastify';

import AccountStatusEnum from './enums/account-status';
import SnsTypeEnum from './enums/sns-type'

import packageJson from '../package.json';
import Dawicoin from './components/views/dawicoin/dawicoin';
import Dionysus from './components/views/dionysus/dionysus';

console.log("App Version: ",packageJson.version);
console.log("React Version: ",React.version);

const App = () => {

  const subDirectory = "/";

  const NaviDiv = withRouter(({location}) => {
    // if(location.pathname === "/"){
    //   return (<div></div>)
    // }
    return (
      <Navi />
    )
  })

  return (
    <BrowserRouter basename={subDirectory} >
      <ScrollToTop history={history}/>
      <div className={"app"}>
        <NaviDiv />
        <div id="content">
          <Switch>
            <NormRoute exact path="/" component={(props) => <Home history={props.history} />} />  
            <LoginRoute path="/login(.*)" component={() => {return <EntryLogin />} } />
            <LoginRoute path="/create-account(.*)" component={() => {return <EntryCreateAccount />} } />

            <NormRoute exact path="/dashboard" component={(props) => <Dashboard history={props.history}/>} />
            <NormRoute exact path="/batch-test" component={(props) => <Dashboard history={props.history}/>} />

            <LoginRoute path="/totp" component={Totp} />
            <LoginRoute path="/new-device" component={NewDevice} />
            <NormRoute path="/totp-reset" component={TotpReset} />
            
            <AuthRoute path="/my-account" minStatus={AccountStatusEnum.MiniAdmin} component={MyAccount} />
            <AuthRoute path="/swiperino" minStatus={AccountStatusEnum.Admin} component={Swiperino} />

            <AuthRoute path="/zeus" minStatus={AccountStatusEnum.MiniAdmin} component={Zeus} />
            <AuthRoute path="/hera" minStatus={AccountStatusEnum.MiniAdmin} component={Hera} />
            <AuthRoute path="/hephaestus" minStatus={AccountStatusEnum.MiniAdmin} component={Hephaestus} />
            <AuthRoute path="/dionysus" minStatus={AccountStatusEnum.MiniAdmin} component={Dionysus} />
            <AuthRoute path="/athena" minStatus={AccountStatusEnum.MiniAdmin} component={Athena} />
            <AuthRoute path="/artemis" minStatus={AccountStatusEnum.MiniAdmin} component={Artemis} />
            <AuthRoute path="/hermes" minStatus={AccountStatusEnum.MiniAdmin} component={Hermes} />
            <AuthRoute path="/achilles" minStatus={AccountStatusEnum.MiniAdmin} component={Achilles} />
            <AuthRoute path="/demeter" minStatus={AccountStatusEnum.MiniAdmin} component={Demeter} />
            <AuthRoute path="/aphrodite" minStatus={AccountStatusEnum.MiniAdmin} component={Aphrodite} />
            <AuthRoute path="/ares" minStatus={AccountStatusEnum.MiniAdmin} component={Ares} />

            <NormRoute path="/test" component={Test} />
            <NormRoute path="/test-two" component={TestTwo} />
            <NormRoute path="/dawicoin" component={Dawicoin} />


            <NormRoute path="/test-auth" minStatus={AccountStatusEnum.MiniAdmin} component={Test} />

            <LoginRoute exact path="/verify-email" component={VerifyEmail} /> 
            <LoginRoute path="/verify-email/:token" component={(props) => <VerifyEmail tokenArg={props.match.params.token}/>} /> 
            <NormRoute exact path="/reset-password" component={ResetPassword} /> 
            <NormRoute path="/reset-password/:token" component={(props) => <ResetPassword tokenArg={props.match.params.token} />} /> 
            <NormRoute exact path="/verify-change-email" component={ChangeEmail} />

            <NormRoute path="/verify-change-email/:token" component={(props) => <ChangeEmail tokenArg={props.match.params.token} />} /> 

            {/* <Route path="/google-auth" component={GoogleAuth} /> */}
            {/* <Route path="/facebook-auth" component={FacebookAuth} /> */}
            <LoginRoute path="/google-auth" component={() => <SnsAuth snsType={SnsTypeEnum.Google}/>} />
            <LoginRoute path="/facebook-auth" component={() => <SnsAuth snsType={SnsTypeEnum.Facebook}/>} />

            <NormRoute path="/privacy-policy" component={PrivacyPolicy} />
            <NormRoute path="/terms-of-use" component={TermsOfUse} />

            {/* <AuthRoute exact path="/admin" minStatus={AccountStatusEnum.Admin} component={Admin} /> */}
            {/* <AuthRoute exact path="/admin/paypal" minStatus={AccountStatusEnum.Admin} component={Paypal} /> */}
            {/* <AuthRoute exact path="/admin/stripe" minStatus={AccountStatusEnum.Admin} component={Stripe} /> */}

            <Route component={NotFound} />  
          </Switch>
        </div>
        <ToastContainer 
          position="top-right"
          autoClose={4000}
          transition={Flip}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          /> 
      </div>
    </BrowserRouter> 
  );
}

export default App;

