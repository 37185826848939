import React, { useEffect, useState } from 'react';
import './test-two.scss';

const TestTwo = () => {

  // 0.000024 = .50 cents
  // 0.00001 = .21 cents (100 sats)
  // 0.000001 = .021 cents (100 sats)
  // 0.0000001 = .0021 cents (10 sats)
  // 0.00000001 = .00021 cents (1 sat)

  


  // 1 sat // 10k
  // 10 sat // 10k * 10 = 100k
  // 100 sat // 10k * 10 = 100k




  let btcValue = 21000;
  let totalMarketCap = "4,000,000,000";
  let totalSupply = "8,000,000,000";
  totalMarketCap = parseInt(totalMarketCap.replace(/,/g,""));
  totalSupply = parseInt(totalSupply.replace(/,/g,""));

  console.log("totalMarketCap",totalMarketCap);
  console.log("totalSupply",totalSupply);

  const generateOrders = () => {

  }




  return (
    <div className="test-two">
      <h3>Test Two</h3>


      {/* <div className='thumbnail'>
        <div className='image'>
          <img src="https://api.dawicoin.com/images/DAWI-big.png"/>
        </div>
        <div className='text'>
          Set Up Payment Button on Dawicoin.com
        </div>
      </div> */}
    </div>
  );
};

export default TestTwo;