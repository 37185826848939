import React,{useState} from 'react';
import './swiperino.scss';
import {request} from '../../../utils/request';
import { useEffect } from 'reactn';
import {getPercentageChange} from '../../../utils/calculate';

import {countriesWithPopulation} from '../../../data/countries';

const Swiperino = () => {

  const [minDate,setMinDate] = useState("1-1-20");
  const [maxDate,setMaxDate] = useState("12-1-20");

  let totalPopulation = 7800000000;

  totalPopulation -= 1392730000;//china;
  totalPopulation -= 1352617328;//india;
  // totalPopulation -= 1392730000;//china;

  const [resultsOne,setResultsOne] = useState([]); 
  const [resultsTwo,setResultsTwo] = useState([]); 
  const [resultsThree,setResultsThree] = useState([]); 

  useEffect(() => {
    console.log("resultsOne",resultsOne);
  },[resultsOne]);

  const handleSubmit = (e) => {
    e.preventDefault();

    

    let endpoint = "/swiperino?minDate="+minDate+"&maxDate="+maxDate+"&minLikes=";

    request("swiperino-one",endpoint+"0","GET", {}, {
      then: function(res){
        setResultsOne(res.data.res.stats);
      },
      catch: function(err){},
      finally: function(){}
    });

    request("swiperino-two",endpoint+"1","GET", {}, {
      then: function(res){
        setResultsTwo(res.data.res.stats);
      },
      catch: function(err){},
      finally: function(){}
    });

    request("swiperino-three",endpoint+"201","GET", {}, {
      then: function(res){
        setResultsThree(res.data.res.stats);
      },
      catch: function(err){},
      finally: function(){}
    });

  }

  const getBetterPercentageChange = (newVal,prevVal) => {
    let foo = getPercentageChange(newVal,prevVal);

    foo = foo + 100;
    foo = Math.round(foo*100)/100;

    foo = foo+"%";
    return foo;
  }

  return (
    <div className="swiperino">
      <h1>Swiperino</h1>

      {/* <button>New data added, refresh</button> */}

      <form onSubmit={handleSubmit}>
        <label>Min Date</label>
        <input type="text" value={minDate} onChange={(e) => {setMinDate(e.target.value)}} />

        <label>Min Date</label>
        <input type="text" value={maxDate} onChange={(e) => {setMaxDate(e.target.value)}} />
        <input type="submit" value="Update"/>
      </form>
      
      <div className="results">
        <div className="countries">
          <label>Countries</label>
          <ul>
            {
              countriesWithPopulation.map((v,i) => {
              return(
                <li key={i}>
                  <div>{v.country}</div>
                  <div>{v.code}</div>
                  <div>{v.population}</div>
                  <div>{getBetterPercentageChange(v.population,totalPopulation)}</div>
                </li>
              )
              })
            }
          </ul>

        </div>
        <div className="results-one">
          <label>0 likes</label>
          <ul>
          {
            Object.keys(resultsOne).map((v,i) => {
              return (
                <li key={i}>
                  <div>{v}</div>
                  <div>{resultsOne[v]}</div>
                  <div>{getBetterPercentageChange(resultsOne[v],resultsOne['total'])}</div>
                  <div>-</div>
                </li>
              )
            })
          }
          </ul>
        </div>
        <div className="results-two">
          <label>1+ likes</label>
          <ul>
          {
            Object.keys(resultsTwo).map((v,i) => {
              return (
                <li key={i}>
                  <div>{v}</div>
                  <div>{resultsTwo[v]}</div>
                  <div>{getBetterPercentageChange(resultsTwo[v],resultsTwo['total'])}</div>
                  <div>{getBetterPercentageChange(resultsTwo[v],resultsOne[v])}</div>
                </li>
              )
            })
          }
          </ul>
        </div>
        <div className="results-three">
          <label>201+ likes</label>
          <ul>
          {
            Object.keys(resultsThree).map((v,i) => {
              return (
                <li key={i}>
                  <div>{v}</div>
                  <div>{resultsThree[v]}</div>
                  <div>{getBetterPercentageChange(resultsThree[v],resultsThree['total'])}</div>
                  <div>{getBetterPercentageChange(resultsThree[v],resultsTwo[v])}</div>
                </li>
              )
            })
          }
          </ul>
        </div>

      </div>

    </div>
  );
};

export default Swiperino;