import React,{useEffect, useState} from 'react';
import './dionysus.scss';
import DateEpochInput from '../../elements/date-epoch-input/date-epoch-input';
import usePersistState from '../../../utils/use-persist-state';
import Button from '../../elements/button/button';
import Loading from '../../common/loading/loading';
import { toast } from 'react-toastify';
import { request, unSubRequest } from '../../../utils/request';
import * as DateUtil from '../../../utils/date';
import * as NumberUtil from '../../../utils/number';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import {ReactComponent as DionysusSvg} from '../../../css/imgs/greek-gods/dionysus.svg';
import { clone } from '../../../utils/clone';


let DayOneKlineInterval = 60*60*1000*24;

const Dionysus = () => {

  const [loading,setLoading] = useState(false);
  const [exchange,setExchange] = usePersistState("binance","dioExchange");
  const [table,setTable] = usePersistState("binance","dioTable");
  const [countRows,setCountRows] = usePersistState(false,"dioCountRows","boolean");
  const [results,setResults] = useState([]);
  const [rowCount,setRowCount] = useState(0);


  const [orderBy,setOrderBy] = useState(null);
  const [orderAsc,setOrderAsc] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    showKlineLength();
  }

  const showKlineLength = () => {
    let endpoint = "/show-kline-length";

    let data = {
      exchange,
      table,
      countRows,
    }

    setLoading(true);
    request("dio-search-klines",endpoint,"POST", data, {
      then: function(res){
        setResults(res.data.res.symbols);
        setRowCount(res.data.res.rowCount);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  const klineBotCrawler = (toggle,table) => {
    let endpoint = "/kline-crawler/"+toggle+"/"+table;

    setLoading(true);
    request("dio-kline-crawler",endpoint,"GET", {}, {
      then: function(res){
        console.log("res",res);
        let klineCrawlerRunning = res.data.res.klineCrawlerRunning;
        if(klineCrawlerRunning){
          toast.success("Running: "+klineCrawlerRunning);
        }else{
          toast.info("Running: "+klineCrawlerRunning);
        }
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  let symbols = clone(results);

  if(orderBy){
    symbols.sort((a, b) => {
      let aVal = a[orderBy];
      let bVal = b[orderBy];
      return (orderAsc) ? ((aVal > bVal) ? 1 : -1) : ((aVal < bVal) ? 1 : -1)
    })
  }

  return (
    <div className="dionysus">
      <h3>Dionysus</h3>
      <div className='god-icon'><DionysusSvg/></div>

      <div className="dionysus-content">
        <form onSubmit={handleSubmit}>
          <div className="block">
            <div>
              <label>Exchange</label>
              <select value={exchange} onChange={(e) => {setExchange(e.target.value)}}>
                <option>Ascendex</option>
                <option>Binance</option>
                <option>Kucoin</option>
                <option>Poloniex</option>
              </select>
            </div>
            <div>
              <label>Table</label>
              <select value={table} onChange={(e) => {setTable(e.target.value)}}>
                <option value="KlineMinuteOne">Minute One</option>
                <option value="KlineMinuteFive">Minute Five</option>
                <option value="KlineMinuteFifteen">Minute Fifteen</option>
                <option value="KlineHourOne">Hour One</option>
              </select>
            </div>
            <div>
              <label>Count Rows</label>
              <SwitchToggle isOn={countRows} onClickFunc={() => {setCountRows(!countRows)}}/>
            </div>
          </div>
          <div className="buttons">
            <Button status={loading?"loading":""} type="submit" value={"View Klines Length"} />
            

          </div>
          <div className='kline-crawler'>
            <label>Kline Crawler</label>
            <div className='buttons'>
              <Button status={loading?"loading":""} type="button" onClick={() => {
                klineBotCrawler("start",table);
              }}>Start</Button>
              <Button status={loading?"loading":""} type="button" onClick={() => {
                klineBotCrawler("stop",table);
              }}>Stop</Button>
              <Button status={loading?"loading":""} type="button" onClick={() => {
                klineBotCrawler("status",table);
              }}>Status</Button>
            </div>
          </div>
        </form>

        <div className='results'>
          <div>Length: {results.length}</div>
          <div>Rows: {NumberUtil.numberWithCommas(rowCount)}</div>
          <table>
            <tbody>
              <tr className='hlo'>
                <th>N</th>
                <th className={orderBy === "symbol"?"active":""} onClick={() => {setOrderBy("symbol");setOrderAsc((orderBy === "symbol")?(!orderAsc):false)}}>Symbol</th>
                <th className={orderBy === "length"?"active":""} onClick={() => {setOrderBy("length");setOrderAsc((orderBy === "length")?(!orderAsc):false)}}>Length</th>
                <th className={orderBy === "earliestTime"?"active":""} onClick={() => {setOrderBy("earliestTime");setOrderAsc((orderBy === "earliestTime")?(!orderAsc):false)}}>Earliest Time</th>
                <th className={orderBy === "latestTime"?"active":""} onClick={() => {setOrderBy("latestTime");setOrderAsc((orderBy === "latestTime")?(!orderAsc):false)}}>Latest Time</th>
              </tr>
              {symbols.map((v,i) => {

                let length = 0;
                if(v.length){
                  length = v.length / DayOneKlineInterval;
                  length = Math.floor(length);
                }

                return(
                  <tr key={i}>
                    <td className='left'>{i+1}</td>
                    <td className='left'>{v.symbol}</td>
                    <td>{length} Days</td>
                    <td>
                      <div>{DateUtil.prettifyDate(v.earliestDate)}</div>
                      <div className='tiny'>{v.earliestTime}</div>
                    </td>
                    <td>
                      <div>{DateUtil.prettifyDate(v.latestDate)}</div>
                      <div className='tiny'>{v.latestTime}</div>
                      <div className='tiny'>{DateUtil.dateTimeSince(v.latestDate)}</div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

};

export default Dionysus;