
export const bookValue = (totalAssets, totalLiabilities) => {
  if(totalAssets === null || totalAssets === undefined || totalLiabilities === null || totalLiabilities == null){
    return null;
  }
  return totalAssets - totalLiabilities;
}

export const marketCapAll = (financials, index, stockPrice) => {
  let {value} = findPreviousDateDataIfAvailable(financials, index, "basicAverageShares"); 
  let basicAverageShares = value;

  return marketCap(basicAverageShares, stockPrice);
}

export const marketCap = (basicAverageShares, stockPrice) => {
  if(basicAverageShares === null || basicAverageShares === undefined || basicAverageShares === 0 || stockPrice === null || stockPrice === 0 || stockPrice === ""){
    return null;
  }
  return Math.round(basicAverageShares * parseFloat(stockPrice));
}

export const priceToBookAll = (totalAssets, totalLiabilities, financials, index, stockPrice) => {
  let tv = bookValue(totalAssets, totalLiabilities);
  let mc = marketCapAll(financials, index, stockPrice);
  return priceToBook(tv,mc);
}

export const priceToBook = (bookValue, marketCap) => {
  if(bookValue === null || bookValue === 0 || marketCap === null || marketCap === 0){
    return null;
  }

  let ptb = marketCap/bookValue;
  let res = Math.round(ptb*100)/100;
  return res;
}

export const basicEps = (financials, index, netIncome) => {return eps(financials, index, netIncome,"basic",false)}
export const dilutedEps = (financials, index, netIncome) => {return eps(financials, index, netIncome,"diluted",false)}
export const eps = (financials, index, netIncome, type) => {
  if(netIncome === null || netIncome === "" || netIncome === undefined){
    return null;
  }
  type = (type !== undefined && type === "diluted")?"dilutedAverageShares":"basicAverageShares";

  let {value} = findPreviousDateDataIfAvailable(financials, index, type); 
  let basicAverageShares = value;

  let res = parseInt(netIncome) / parseInt(basicAverageShares)
  return (Math.round(res*100))/100;
}

export const pegAll = (financials, index, stockPrice) => {
  let netIncome1 = 0;
  let netIncome2 = 0;

  for (let n = 0; n < 4; n++) {
    if(financials["netIncome"][index+n] !== undefined){
      netIncome1 += financials["netIncome"][index+n];
    }else{
      return null;
    }
  }

  let eps1 = basicEps(financials, index, netIncome1);

  for (let m = 4; m < 8; m++) {
    if(financials["netIncome"][index+m] !== undefined){
      netIncome2 += financials["netIncome"][index+m];
    }else{
      return null;
    }
  }

  let eps2 = basicEps(financials, index, netIncome2);

  let epsGrowthPercentage = ((eps1/eps2)-1)*100;
  if(isNaN(epsGrowthPercentage) || !isFinite(epsGrowthPercentage) || epsGrowthPercentage <= 0){
    return null;
  }

  // console.log("index",index);
  // console.log("stockPrice",stockPrice);
  // console.log("eps1",eps1);
  // console.log("eps2",eps2);
  // console.log("epsGrowthPercentage",epsGrowthPercentage);
  let pegR = peg(stockPrice,eps1,epsGrowthPercentage);
  // console.log("peg",pegR);
  return pegR
}
export const peg = (stockPrice, eps, epsGrowthPercentage) => {
  // (Stock Price/EPS TTM) / EPS TTM Growth Percentage
  let pegRatio = (stockPrice/eps)/epsGrowthPercentage
  return Math.round(pegRatio*100)/100;
}

export const priceToEarningsAll = (financials, index, stockPrice) => {
  // uses ttm
  let ttmNetIncome = 0;
  for (let i = 0; i < 4; i++) {
    if(financials["netIncome"][index+i] !== undefined){
      ttmNetIncome += financials["netIncome"][index+i];
    }else{
      return null;
    }
  }

  // let stockPrice = financials["closePrice"][index];

  let be = basicEps(financials, index, ttmNetIncome);
  let pe = priceToEarnings(be, stockPrice);
  return pe;
}

export const priceToEarnings = (basicEps, stockPrice) => {
  if(basicEps === null ||  stockPrice === null || stockPrice === 0 || stockPrice === ""){
    return null;
  }
  if(basicEps <= 0){
    return null;
  }

  let pe = parseFloat(stockPrice)/basicEps;
  return Math.round(pe*100)/100;
}

export const findPreviousDateDataIfAvailable = (financials, index, keyName) => {
  let value = financials[keyName][index];
  let usingPrevious = false;

  if(value === null){
    usingPrevious = true;
    let x = 1;
    while(value === null){
      let afterValue = financials[keyName][index + x];
      if(afterValue !== null){
        value = afterValue;
        break;
      }
      x++;
    }
  }

  return {value, usingPrevious};
}


export const fetchProperValueByKey = (keyP,index,financials) => {

  let key = keyP;
  let i = index;

  // console.log("fetchProperValueByKey received index",index,"key.name",key.name);

  let displayValue = 0;

  if(key.name === "basicEps"){
    // console.log("basicEps index",index);
    displayValue = basicEps(financials,i,financials["netIncome"][i]);
  }else if(key.name === "dilutedEps"){
    displayValue = dilutedEps(financials,i,financials["netIncome"][i]);
  }else if(key.name === "bookValue"){
    // totalAssets minus totalLiabilities
    displayValue = bookValue(financials["totalAssets"][i],financials["totalLiabilities"][i]);
  }else if(key.name === "marketCap"){
    displayValue = marketCapAll(financials,i,financials["closePrice"][i]);
  }else if(key.name === "priceToBook"){
    displayValue = priceToBookAll(
                        financials["totalAssets"][i],
                        financials["totalLiabilities"][i],
                        financials,
                        i,
                        financials["closePrice"][i]);
  }else if(key.name === "priceToEarnings"){
    displayValue = priceToEarningsAll(financials,i,financials["closePrice"][i]);
  
  }else if(key.name === "basicAverageShares" || key.name === "dilutedAverageShares"){
    let {value} = findPreviousDateDataIfAvailable(financials,i,key.name);
    displayValue = value;
  }else if(key.name === "peg"){
    displayValue = pegAll(financials,i,financials["closePrice"][i]);
  }else{
    if(financials[key.name] === undefined){
      // console.log("key.name("+key.name+") is undefined");
      displayValue = 0;
    }else{  
      displayValue = financials[key.name][i];
    }
    
  }

  return displayValue
}

export const getPercentageChange = (newValue, previousValue) => {

  if(newValue === null || previousValue === null || isNaN(newValue) || isNaN(previousValue)){
    return null;
  }
  
  if(newValue <= 0 || previousValue <= 0){
    return null;
  }

  let value = newValue/previousValue;
  value = value - 1;

  value = Math.round(value*10000)/100;

  return value;
}