import React from 'react';
import './navi-footer.scss';
import {ReactComponent as LanguageSvg} from '../../css/imgs/icon-language.svg';
import HoverDiv from '../elements/hover-div/hover-div';
import { t } from "../../translations/i18n";

const NaviFooter = ({hoverCallback,setShowLanguageModal}) => {

  let displayText = t("navi.language")
  return (
    <div className={"navi-footer"}>
      <HoverDiv hoverCallback={(ref,isHovered) => hoverCallback(ref,isHovered,displayText)} className="navi-footer-button" 
        onClick={() => {setShowLanguageModal(true)}}>
        <span className="icon"><LanguageSvg/></span>
        <span className="text">{displayText}</span>
      </HoverDiv>
    </div>
  );

};

export default NaviFooter;