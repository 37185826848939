import React,{ useEffect, useState, useRef } from 'react';
import './zeus.scss';

import Config from '../../../config/config';
import {request, unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import Button from '../../elements/button/button';
import {clone} from '../../../utils/clone';
import SocketState, {SocketStateArr} from '../../../enums/socket-state';
import * as DateUtil from '../../../utils/date';
import ZeusModal from './zeus-modal';

const Zeus = () => {

  const [showModal,setShowModal] = useState(false);
  const [bids,setBids] = useState([]);
  const [asks,setAsks] = useState([]);
  const [allBids,setAllBids] = useState([]);
  const [allAsks,setAllAsks] = useState([]);
  const [timenow,setTimenow] = useState(new Date());
  const [updated,setUpdated] = useState(new Date());
  const [socket,setSocket] = useState(null);
  const socketRef = useRef(null);
  socketRef.current = socket;

  const [localSocketOn,setLocalSocketOn] = useState(false);
  const [btsSocketLoading,setBtsSocketLoading] = useState(false);
  const [btsSocketOn,setBtsSocketOn] = useState(false);
  const [btsSocketTime,setBtsSocketTime] = useState(new Date());
  const [zeusOn,setZeusOn] = useState(false);
  const [zeusTime,setZeusTime] = useState(new Date());

  const [balance,setBalance] = useState({
    balance:{
      btc:0,usd:0,usdc:0,
    },
    available:{
      btc:0,usd:0,usdc:0,
    }
  });
  const [loadingOrders,setLoadingOrders] = useState(false);
  const [openOrders,setOpenOrders] = useState([]);

  const [socketData,setSocketData] = useState({});
  let statusInterval = useRef(null);
  statusInterval.current = 0;

  // const [lastestBtcUsdPrice,setLastestBtcUsdPrice] = useState(0);
  const [lastestBtcUsdcPrice,setLastestBtcUsdcPrice] = useState(0);

  // useEffect(() => {
  //   console.log("socketData",socketData);
  // },[socketData])

  useEffect(() => {
    startLocalSocket();
    return () => {
      unSubRequest("zeus-status");
      unSubRequest("zeus-start");
      unSubRequest("zeus-stop");
    }
  },[]);

  useEffect(() => {
    setTimeout(() => {
      setTimenow(new Date());
    },250);
  },[timenow])

  useEffect(() => {
    return () => {
      stopLocalSocket(socketRef.current);
    }
  },[socketRef]);

  const startLocalSocket = () => {
    let socket = new WebSocket(Config.Common.ZeusApiBaseSocket + '/socket');
    socket.addEventListener('open', function (event) {
      socket.send("Hiya Zeus");
    });
    socket.addEventListener('message', function (event) {
      setSocketData(JSON.parse(event.data))
    });

    socket.addEventListener('close', function (event) {
      socket.close();
    });
    setLocalSocketOn(true);
    setSocket(socket);
  }
  const stopLocalSocket = (socket) => {
    if(socket !== null){
      socket.close();
    }
    setSocket(null);
    setLocalSocketOn(false);
  }

  useEffect(() => {
    if(socketData !== null){
      console.log("socketData",socketData);
      setBtsSocketOn(socketData.bitstampSocketState === SocketState.Open);
      setBtsSocketTime(socketData.bitstampSocketStateTime);
      setZeusOn(socketData.zeusState === 1);
      let asks = [];
      let bids = [];
      if(socketData.subscriptionsData !== undefined && socketData.subscriptionsData['order_book_btcusdc'] !== undefined){
        asks = socketData.subscriptionsData['order_book_btcusdc']['data']['asks'];
        bids = socketData.subscriptionsData['order_book_btcusdc']['data']['bids'];

        // let btcusdPrice = 0;
        let btcusdcPrice = 0;
        // if(socketData.subscriptionsData['live_trades_btcusd'] !== undefined){
        //   btcusdPrice = socketData.subscriptionsData['live_trades_btcusd'].data.price;
        // }
        if(socketData.subscriptionsData['live_trades_btcusdc'] !== undefined){
          btcusdcPrice = socketData.subscriptionsData['live_trades_btcusdc'].data.price;
        } 
        // setLastestBtcUsdPrice(btcusdPrice);
        setLastestBtcUsdcPrice(btcusdcPrice);      

      }
      setAsks(asks);
      setBids(bids);

      setZeusOn(socketData.zeusState);
      setZeusTime(socketData.zeusStateTime);
      setAllAsks(socketData.zeusAsks);

      let zeusBids = (socketData.zeusBids !== undefined)?socketData.zeusBids:[];
      let zeusAsks = (socketData.zeusAsks !== undefined)?socketData.zeusAsks:[];
      setAllBids(zeusBids);
      setAllAsks(zeusAsks);
      setOpenOrders(socketData.zeusOpenOrders);
      setBalance(socketData.zeusBalance);


      setUpdated(new Date());
    }
  },[socketData]);

  const startZeus = () => {
    request("zeus-start","/zeus-start","POST", {}, {
      then: function(res){toast.success("Zeus Started");},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  } 

  const stopZeus = () => {
    request("zeus-stop","/zeus-stop","POST", {}, {
      then: function(res){toast.info("Zeus Stopped");},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const startBtsStream = () => {
    setBtsSocketLoading(true);
    request("zeus-open-bts-stream","/bitstamp-open-stream","GET", {}, {
      then: function(res){toast.success("Bitstamp Stream Open Request received. Opening.");},
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setBtsSocketLoading(false);
      }
    });
  } 

  const stopBtsStream = () => {
    request("zeus-close-bts-stream","/bitstamp-close-stream","GET", {}, {
      then: function(res){toast.info("Bitstamp Stream Close Request received. Closing.");},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const displayLiveTrade = (timenow,socketData,ticker) => {
    let tickerData = null;
    if(socketData.subscriptionsData !== undefined){
      if(socketData.subscriptionsData['live_trades_'+ticker] !== undefined){
        tickerData = socketData.subscriptionsData['live_trades_'+ticker]
      }
    }

    let str = ticker.toUpperCase()+": - ";
    let timer = "";
    let amount = "";
    if(tickerData !== null){
      let timenowSeconds = timenow.getTime()/1000
      
      let timeOccured = timenowSeconds - parseInt(tickerData.data.timestamp);
      timeOccured = Math.round(timeOccured*100)/100;

      str = ticker.toUpperCase()+": "+tickerData.data.price;
      timer = timeOccured+" seconds ago";
      amount = tickerData.data.amount;
    }

    return(
      <label className="display-live-price">
        <div className="price">{str}</div>
        <div className="detail">
          <div className="timer">{timer}</div>
          <div className="amount">{amount} BTC</div>
        </div>
      </label>
    )
  }

  const clearAllOrders = () => {
    setLoadingOrders(true);
    request("zeus-bitstamp-cancel-orders","/bitstamp-cancel-orders","GET", {}, {
      then: function(res){toast.info("All Orders Canceled");setOpenOrders([])},
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoadingOrders(false);
      }
    }) 
  }

  
  let localUpdateSeconds = Math.round((timenow - updated)/10)/100;
  let btsTime = new Date(btsSocketTime);
  let btsUpdateSeconds = Math.round((timenow - btsTime)/10)/100;
  let zeusSecTime = new Date(zeusTime);
  let zeusUpdateSeconds = Math.round((timenow - zeusSecTime)/10)/100;

  let currentTotalBalance = 0
  if(balance !== undefined && balance.balance !== undefined){
    currentTotalBalance = (balance.balance.usd+balance.balance.usdc+(balance.balance.btc*lastestBtcUsdcPrice));
  }

  let liveTrades = {
    btcusd:[],
    btcusdc:[],
  } 
  if(socketData.liveTrades !== undefined){
    liveTrades.btcusd = clone(socketData.liveTrades.btcusd).reverse();
    liveTrades.btcusdc = clone(socketData.liveTrades.btcusdc).reverse();
  }

  return (
    <div className="zeus">
      <div className="zeus-header">
        <h3>Zeus</h3>
        <div className="buttons"> 
          <div className="button-time">
            <Button 
              type="button" 
              parentClassName={(zeusOn)?"active":""}
              onClick={() => {(!zeusOn)?startZeus():stopZeus()}}>
              {(!zeusOn)?"Start":"Stop"} Zeus
            </Button>
            <div className="time">
              {zeusUpdateSeconds} secs ago
            </div>
          </div>
          <div className="button-time">
            <Button 
              type="button" 
              parentClassName={(localSocketOn)?"active":""}
              onClick={() => {(!localSocketOn)?startLocalSocket():stopLocalSocket(socket)}}>
              {(!localSocketOn)?"Open":"Close"} API Stream
            </Button>
            <div className="time">
              {localUpdateSeconds} secs ago
            </div>
          </div>
          <div className="button-time">
            <Button 
              type="button" 
              parentClassName={btsSocketOn?"active":""}
              status={(btsSocketLoading)?"loading":""}
              onClick={() => {(!btsSocketOn)?startBtsStream():stopBtsStream()}}>
              {(!btsSocketOn)?"Open":"Close"} BTS Stream
            </Button>
            <div className="time">
              {btsUpdateSeconds} secs ago
            </div>
          </div>
          <div className="button-time">
            <Button 
              type="button" 
              parentClassName={"delete"}
              status={(loadingOrders)?"loading":""}
              onClick={() => {clearAllOrders()}}>
              Clear All Orders
            </Button>
            <div className="time">
              -
            </div>
          </div>
          <div className="button-time">
            <Button 
              type="button" 
              status={(loadingOrders)?"loading":""}
              onClick={() => {setShowModal(true)}}>
              Edit Depths
            </Button>
            <div className="time">
              -
            </div>
          </div>
        </div>
      </div>
      
      
      <div className="zeus-feed">
        <div className="live-feed">
          <label>BTS Socket State: {(socketData !== null)?SocketStateArr[socketData.bitstampSocketState]:"-"}</label>

          <label>USD Balance: {parseInt(currentTotalBalance)}</label>
          <pre>
            {JSON.stringify(balance, null, 2)}
          </pre>

          <label>Open Orders</label>
          <pre>
            {JSON.stringify(openOrders, null, 2)}
          </pre>
          
        </div>


        <div className="order-book">
          <div>
            <label>{displayLiveTrade(timenow,socketData,"btcusd")}</label>
            <label>{displayLiveTrade(timenow,socketData,"btcusdc")}</label>
          </div>  
          <div className="asks-bids">   
            <div className="bids">
              <label>Bids Stream</label>
              <div className="bids-table-container">
                <table>
                  <tbody>
                    <tr className="header">
                      <th>Price</th>
                      <th>Amount</th>
                    </tr>
                    {
                      bids.map((v,i) => {
                        let ourOrder = false;
                        for (let i = 0; i < openOrders.length; i++) {
                          const order = openOrders[i];
                          if(parseFloat(order.price) === parseFloat(v[0])){
                            ourOrder = true;
                          }
                        }
                        return(
                          <tr key={i} className={(ourOrder)?"active":""}>
                            <td>{v[0]}</td>
                            <td>{v[1]}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="asks">
              <label>Asks Stream</label>
              <div className="asks-table-container">
                <table>
                  <tbody>
                    <tr className="header">
                      <th>Price</th>
                      <th>Amount</th>
                    </tr>
                    {
                      asks.map((v,i) => {
                        let ourOrder = false;
                        for (let i = 0; i < openOrders.length; i++) {
                          const order = openOrders[i];
                          if(parseFloat(order.price) === parseFloat(v[0])){
                            ourOrder = true;
                          }
                        }

                        return(
                          <tr key={i} className={(ourOrder)?"active":""}>
                            <td>{v[0]}</td>
                            <td>{v[1]}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>   
          <div className="live-trades">
            <div className="one">
              <label>Live Trades Stream (btcusd)</label>
              <div className="table">
                <table>
                  <tbody>
                    <tr className="header">
                      <th>Price</th>
                      <th>Amount</th>
                      <th>Time</th>
                      <th>Type</th>
                    </tr>
                    {
                      liveTrades['btcusd'].map((v,i) => {
                        return(
                          <tr key={i} className={(false)?"active":""}>
                            <td>{v.price}</td>
                            <td>{(Math.round(v.amount*10000)/10000)}</td>
                            <td>{DateUtil.dateTimeSinceEpoch(v.timestamp,0)}</td>
                            <td>{(v.type===0)?"Buy":"Sell"}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div className="two">
              <label>Live Trades Stream (btcusdc)</label>
              <div className="table">
              <table>
                  <tbody>
                    <tr className="header">
                      <th>Price</th>
                      <th>Amount</th>
                      <th>Time</th>
                      <th>Type</th>
                    </tr>
                    {
                      liveTrades['btcusdc'].map((v,i) => {
                        return(
                          <tr key={i} className={(false)?"active":""}>
                            <td>{v.price}</td>
                            <td>{(Math.round(v.amount*10000)/10000)}</td>
                            <td>{DateUtil.dateTimeSinceEpoch(v.timestamp,0)}</td>
                            <td>{(v.type===0)?"Buy":"Sell"}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="asks-bids">   
            <div className="bids">
              <label>All Bids</label>
              <div className="bids-table-container">
                <table>
                  <tbody>
                    <tr className="header">
                      <th>Price</th>
                      <th>Amount</th>
                    </tr>
                    {
                      allBids.map((v,i) => {
                        let ourOrder = false;
                        for (let i = 0; i < openOrders.length; i++) {
                          const order = openOrders[i];
                          if(parseFloat(order.price) === parseFloat(v[0])){
                            ourOrder = true;
                          }
                        }
                        return(
                          <tr key={i} className={(ourOrder)?"active":""}>
                            <td>{v[0]}</td>
                            <td>{v[1]}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="asks">
              <label>All Asks</label>
              <div className="asks-table-container">
                <table>
                  <tbody>
                    <tr className="header">
                      <th>Price</th>
                      <th>Amount</th>
                    </tr>
                    {
                      allAsks.map((v,i) => {
                        let ourOrder = false;
                        for (let i = 0; i < openOrders.length; i++) {
                          const order = openOrders[i];
                          if(parseFloat(order.price) === parseFloat(v[0])){
                            ourOrder = true;
                          }
                        }

                        return(
                          <tr key={i} className={(ourOrder)?"active":""}>
                            <td>{v[0]}</td>
                            <td>{v[1]}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ZeusModal show={showModal} handleClose={() => {setShowModal(false)}}/>
    </div>
  );
};

export default Zeus;