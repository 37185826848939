import { navi } from './views/navi';

// TODO @Trevor 
// In the event a translation is not there, it will display nothing. Implement a fallback language.

/**
 * Converts our message structure to only contain one locale's text. This is required by our i18n library
 *
 * @param {Object} messages A key/value object containing several localisation texts
 * @param {String} locale The key of the locale text we want in the output
 */
const convertToSingularLocale = (messages, locale ) => {
  let singleLocale = Object.assign({}, messages)

  for (let property in singleLocale) {
    if (property === locale) {
      return singleLocale[property]
    } else if (typeof singleLocale[property] === 'object') {
      singleLocale[property] = convertToSingularLocale(singleLocale[property], locale)
    }
  }

  return singleLocale
}

const translations = {
  general: {
    languageAbbreviation: {
      de: "de",
      es: "es",
      en: "en",
      ja: "ja",
      fr: "fr",
      zh: "zh",
    },
  },

  navi: navi,
}

// export const german = convertToSingularLocale(translations, 'de')
// export const spanish = convertToSingularLocale(translations, 'es')
export const english = convertToSingularLocale(translations, 'en')
export const japanese = convertToSingularLocale(translations, 'ja')
// export const french = convertToSingularLocale(translations, 'fr')
// export const chinese = convertToSingularLocale(translations, 'zh')

export const availableLanguages = [
  { name: "English", nativeName:"English", code: "en" },
  { name: "Japanese", nativeName:"日本語", code: "ja" },
]