import React,{ useState,useEffect } from 'react';
import './artemis-result.scss';
import Button from '../../elements/button/button';
import { toast } from 'react-toastify';
import { request, } from '../../../utils/request';
import * as DateUtil from '../../../utils/date';
import * as NumberUtil from '../../../utils/number';
import * as StringUtil from '../../../utils/string';

const ArtemisResult = ({data}) => {
  const [loading,setLoading] = useState(false);
  const [crawled,setCrawled] = useState(false);
  const [deleted,setDeleted] = useState(false);

  useEffect(() => {
    // console.log("data",data);
    setCrawled(false);
  },[data])

  const crawlTime = (
          startTime,
          duration,
          minQuoteAssetVolume,
          intervalStep,
          trendLength,
          trendStep,
          table,
          ponies,
          lag,
          stopLossPercentage,
          dataExchanges,
          moneyExchanges,
          algo,
    ) => {
    let endpoint = "/artemis-time";
    let name = "artemis-time-"+startTime;


    // let moneyExchangesPass = {};
    // if(moneyExchanges.indexOf("binance") !== -1){moneyExchangesPass.binance = 1000};
    // if(moneyExchanges.indexOf("kucoin") !== -1){moneyExchangesPass.kucoin = 1000};
    // if(moneyExchanges.indexOf("poloniex") !== -1){moneyExchangesPass.poloniex = 1000};
    // if(moneyExchanges.indexOf("ascendex") !== -1){moneyExchangesPass.ascendex = 1000};
    // if(moneyExchanges.indexOf("okex") !== -1){moneyExchangesPass.okex = 1000};
    // if(moneyExchanges.indexOf("gateio") !== -1){moneyExchangesPass.gateio = 1000};


    let data = {
      startTime,
      duration,
      minQuoteAssetVolume: minQuoteAssetVolume,
      intervalStep: intervalStep,
      trendLength,
      trendStep,
      table,
      ponies,
      lag,
      stopLossPercentage,
      dataExchanges,
      moneyExchanges,
      algo,
    }

    setLoading(true);
    request(name,endpoint,"POST", data, {
      then: function(res){setCrawled(true);},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    }) 
  }

  let dateTime = "Total";
  let epochTime = 0;
  if(data.arguments.startTime !== "total"){
    dateTime = DateUtil.prettifyDateWithTime(data.arguments.startTime)
    epochTime = data.arguments.startTime
  }
  
  const deleteBatchResults = (id) => {
    if(id === undefined || id < 1) return;
    setLoading(true);
    request("delete-hephaestus-batch-results","/hephaestus-batch-results","DELETE", {id}, {
      then: function(res){
        toast.success("Deleted batch Results")
        setDeleted(true);
        setCrawled(false);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  return (
    <div className="artemis-result">
      <label>{dateTime}</label>
      <label>{epochTime}</label>
      {(data.exists && !deleted)?
      <div className="gains">
        <table>
          <tbody>
          {(data.batchResults.total !== undefined)?
            Object.keys(data.batchResults.total).map((v,i) => {
              let gain = data.batchResults.total[v];

              if(parseFloat(gain.money) <= 0) return null;

              return (
                <tr key={i} className="gain">
                  <td className="label">{StringUtil.capitalize(v)}:</td>
                  <td className="percentage">{NumberUtil.numberWithCommas(gain.percentage)}%</td>
                  <td className="value">${NumberUtil.numberWithCommas(gain.money)}</td>
                </tr>
              )
            }):null
          }
          </tbody>
        </table>
        <Button onClick={() => {deleteBatchResults(data.batchId)}}>Delete Batch Results</Button>
        <button onClick={() => {console.log("data",data)}}>Console Log Batch Result</button>
      </div>
      :
      <div>
        Not yet crawled
        <Button status={(loading)?"loading":""} disabled={crawled} type="button" onClick={() => {
          crawlTime(
            data.arguments.startTime,
            data.arguments.duration,
            data.arguments.minQuoteAssetVolume,
            data.arguments.hodlLength,
            data.arguments.trendLength,
            data.arguments.trendStep,
            data.arguments.table,
            data.arguments.poniesToRide,
            100,//lag,
            100,//stopLossPercentage,
            data.arguments.dataExchanges,
            data.arguments.moneyExchanges,
            data.arguments.algo,
          )
          }}>{(crawled)?"Crawled":"Crawl"}</Button>
      </div>
      }    
    </div>
  );

};

export default ArtemisResult;