import React,{useState,useEffect} from 'react';
import { request, unSubRequest } from '../../../utils/request';
import './achilles-simulator.scss';
import {toast} from 'react-toastify'
import Loading from '../../common/loading/loading';
import Button from  '../../elements/button/button';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import * as DateUtil from '../../../utils/date';
import * as NumberUtil from '../../../utils/number';
import * as StringUtil from '../../../utils/string';
import usePersistState from '../../../utils/use-persist-state';

const AchillesSimulator = () => {

  let timenow = new Date();
  let getTimezoneOffset = (timenow.getTimezoneOffset() * -1) * 60 * 1000;

  const oneMinute = 60 * 1000;

  const [eye,setEye] = usePersistState(10,"achillesEye","boolean");
  const [loading,setLoading] = useState(false);
  const [startTime,setStartTime] = usePersistState("2020-01-01T02:00","achillesStartTime");
  const [epochStartTime,setEpochStartTime] = useState(DateUtil.datetimeToEpoch(startTime)+getTimezoneOffset);
  const [duration,setDuration] = usePersistState(10,"achillesDuration","number");
  const [hodlLength,setHodlLength] = usePersistState(10,"achillesHodlLength","number");
  const [minQuoteAssetVolume,setMinQuoteAssetVolume] = usePersistState(150,"achillesMinQuoteAssetVolume","number");
  const [gains,setGains] = useState({})
  const [resultsElapsedTime,setResultsElapsedTime] = useState(-1);
  const [theGrid,setTheGrid] = useState({});

  const [batchId,setBatchId] = useState(-1);

  const [table,setTable] = usePersistState("KlineHourOne","achillesTable");
  const [poniesToRide,setPoniesToRide] = usePersistState(3,"achillesPoniesToRide","number");
  const [lag,setLag] = usePersistState(100,"achillesLag","number");
  const [trendLength,setTrendLength] = usePersistState(8,"achillesTrendLength","number");
  const [trendStep,setTrendStep] = usePersistState(oneMinute * 60,"achillesTrendStep","number");
  const [stopLossPercentage,setStopLossPercentage] = usePersistState(100,"achillesStopLossPercentage","number");
  const [algo,setAlgo] = usePersistState(1,"achillesAlgo","number");

  const [showPonies,setShowPonies] = usePersistState(true,"achillesShowPonies","boolean");
  const [inspect,setInspect] = usePersistState(true,"achillesInspect","boolean");
  const [rapid,setRapid] = usePersistState(true,"achillesRapid","boolean");
  const [desc,setDesc] = usePersistState(true,"achillesDesc","boolean");
  const [picks,setPicks] = usePersistState(true,"achillesPicks","boolean");
  const [realWinners,setRealWinners] = usePersistState(true,"achillesRealWinners","boolean");

  const [binanceExchange,setBinanceExchange] = usePersistState(true,"achillesBinanceExchange","boolean");
  const [kucoinExchange,setKucoinExchange] = usePersistState(true,"achillesKucoinExchange","boolean");
  const [poloniexExchange,setPoloniexExchange] = usePersistState(true,"achillesPoloniexExchange","boolean");
  const [ascendexExchange,setAscendexExchange] = usePersistState(true,"achillesAscendexExchange","boolean");

  const [binanceMoney,setBinanceMoney] = usePersistState(1000,"achillesBinanceMoney","number");
  const [kucoinMoney,setKucoinMoney] = usePersistState(1000,"achillesKucoinMoney","number");
  const [poloniexMoney,setPoloniexMoney] = usePersistState(1000,"achillesPoloniexMoney","number");
  const [okexMoney,setOkexMoney] = usePersistState(1000,"achillesOkexMoney","number");
  const [gateioMoney,setGateioMoney] = usePersistState(1000,"achillesGateioMoney","number");
  const [ascendexMoney,setAscendexMoney] = usePersistState(1000,"achillesAscendexMoney","number");
  const [huobiMoney,setHuobiMoney] = usePersistState(1000,"achillesHuobiMoney","number");
  const [ftxMoney,setFtxMoney] = usePersistState(1000,"achillesFtxMoney","number");

  useEffect(() => {
    return () => {
      unSubRequest("achilles-simulate");
    }
  },[])

  const submitHandler = (e) => {
    e.preventDefault();
    fetchResults(true);
  }

  const fetchResults = (batch) => {
    let endpoint = "/achilles-simulate";
    let name = "achilles-simulate";

    let dataExchanges = [];
    if(binanceExchange) dataExchanges.push("binance");
    if(kucoinExchange) dataExchanges.push("kucoin");
    if(poloniexExchange) dataExchanges.push("poloniex");
    if(ascendexExchange) dataExchanges.push("ascendex");

    let moneyExchanges = {
      binance: binanceMoney,
      kucoin: kucoinMoney,
      poloniex: poloniexMoney,
      okex: okexMoney,
      gateio: gateioMoney,
      ascendex: ascendexMoney,
      huobi: huobiMoney,
      ftx: ftxMoney,
    }

    let data = {
      startTime: parseInt(epochStartTime),
      duration,
      minQuoteAssetVolume: parseInt(minQuoteAssetVolume),
      table,
      hodlLength,
      poniesToRide,
      trendLength,
      trendStep,
      lag,
      stopLossPercentage,
      dataExchanges,
      moneyExchanges,
      algo,
      inspect,
      rapid,
      batch,
    }

    setLoading(true);
    request(name,endpoint,"POST", data, {
      then: function(res){
        let results = res.data.res;
        console.log("results",results);
        setResultsElapsedTime(results.elapsedTime);
        setGains(results.gains);
        setTheGrid(results.theGrid);
        setBatchId(results.batchId)
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  const deleteBatchResults = (id) => {
    if(id < 1) return;
    setLoading(true);
    request("delete-achilles-simulate-results","/achilles-simulate-results","DELETE", {id}, {
      then: function(res){
        toast.success("Deleted batch Results");
        setResultsElapsedTime(-1);
        setGains({});
        setTheGrid({});
        setBatchId(-1)
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  return (
    <div className="achilles-simulator">
      <form className="cond-form" onSubmit={submitHandler}>
        <div className="easy-forms">
          <div>
            <label>Start Time (UTC)</label>
            <input type="datetime-local" value={startTime} onChange={(e) => {
              setStartTime(e.target.value);
              setEpochStartTime(DateUtil.datetimeToEpoch(e.target.value,true));
            }}/>
          </div>
          <div className="epoch-time">
            <label>Epoch Time</label>
            <input type="text" value={epochStartTime} onChange={(e) => {
              setEpochStartTime(e.target.value);
              setStartTime(DateUtil.epochToDatetimeLocal(e.target.value,true));
            }}/>
          </div>
        </div>
        <div className="easy-forms">
          <div>
            <label>Duration</label>
            <select value={duration} onChange={(e) => {setDuration(e.target.value)}}>
              <option value={oneMinute * 60 * 12}>12 hours</option>
              <option value={oneMinute * 60 * 24 * 1}>1 day</option>
              <option value={oneMinute * 60 * 24 * 3}>3 days</option>
              <option value={oneMinute * 60 * 24 * 5}>5 days</option>
              <option value={oneMinute * 60 * 24 * 7}>1 week</option>
              <option value={oneMinute * 60 * 24 * 7 * 2}>2 weeks</option>
              <option value={oneMinute * 60 * 24 * 7 * 3}>3 weeks</option>
              <option value={oneMinute * 60 * 24 * 30 * 1}>1 month</option>
              <option value={oneMinute * 60 * 24 * 30 * 2}>2 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 3}>3 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 4}>4 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 5}>5 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 6}>6 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 7}>7 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 8}>8 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 9}>9 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 10}>10 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 11}>11 months</option>
              <option value={oneMinute * 60 * 24 * 365}>1 year</option>
              <option value={parseInt(oneMinute * 60 * 24 * 365 * 1.5)}>1.5 years</option>
              <option value={oneMinute * 60 * 24 * 365 * 2}>2 years</option>
            </select>
          </div>
          <div className={(eye)?"blur":""}>
            <label>hodlLength</label>
            <select value={hodlLength} onChange={(e) => {setHodlLength(e.target.value)}}>
              <option value={oneMinute}>1 minute</option>
              <option value={oneMinute * 3}>3 minutes</option>
              <option value={oneMinute * 5}>5 minutes</option>
              <option value={oneMinute * 15}>10 minutes</option>
              <option value={oneMinute * 15}>15 minutes</option>
              <option value={oneMinute * 30}>30 minutes</option>
              <option value={oneMinute * 60}>1 hour</option>
              <option value={oneMinute * 60 * 2}>2 hours</option>
              <option value={oneMinute * 60 * 3}>3 hours</option>
              <option value={oneMinute * 60 * 4}>4 hours</option>
              <option value={oneMinute * 60 * 5}>5 hours</option>
              <option value={oneMinute * 60 * 6}>6 hours</option>
              <option value={oneMinute * 60 * 7}>7 hours</option>
              <option value={oneMinute * 60 * 8}>8 hours</option>
              <option value={oneMinute * 60 * 9}>9 hours</option>
              <option value={oneMinute * 60 * 10}>10 hours</option>
              <option value={oneMinute * 60 * 11}>11 hours</option>
              <option value={oneMinute * 60 * 12}>12 hours</option>
              <option value={oneMinute * 60 * 24}>24 hours</option>
              <option value={oneMinute * 60 * 36}>36 hours</option>
              <option value={oneMinute * 60 * 24 * 2}>2 days</option>
              <option value={oneMinute * 60 * 24 * 3}>3 days</option>
              <option value={oneMinute * 60 * 24 * 5}>5 days</option>
              <option value={oneMinute * 60 * 24 * 7}>1 week</option>
            </select>
          </div>
          <div className={(eye)?"blur":""}>
            <label>trendLength</label>
            <input type="number" value={trendLength} onChange={(e) => {setTrendLength(e.target.value)}}/>
          </div>
          <div className={(eye)?"blur":""}>
            <label>trendStep</label>
            <select value={trendStep} onChange={(e) => {setTrendStep(e.target.value)}}>
              <option value={oneMinute}>1 minute</option>
              <option value={oneMinute * 5}>5 minutes</option>
              <option value={oneMinute * 15}>15 minutes</option>
              <option value={oneMinute * 60}>1 hour</option>
            </select>
          </div>
          
        </div>
        <div className="easy-forms">
          <div className={(eye)?"blur":""}>
            <label>ponies</label>
            <input type="number" value={poniesToRide} onChange={(e) => {setPoniesToRide(e.target.value)}}/>
          </div>
          <div className={(eye)?"blur":""}>
            <label>lag</label>
            <input type="number" value={lag} onChange={(e) => {setLag(e.target.value)}}/>
          </div>
          <div className={(eye)?"blur":""}>
            <label>stopLoss</label>
            <input type="number" value={stopLossPercentage} onChange={(e) => {setStopLossPercentage(e.target.value)}}/>
          </div>
          <div>
            <label>Algo</label>
            <input type="number" value={algo} onChange={(e) => {setAlgo(e.target.value)}}/>
          </div>
          <div className={"quote-asset-vol "+((eye)?"blur":"")}>
            <label>Min Q Volume</label>
            <input type="number" value={minQuoteAssetVolume} onChange={(e) => {setMinQuoteAssetVolume(e.target.value)}}/>
          </div>
          <div>
            <label>Hidden</label>
            <SwitchToggle isOn={eye} onClickFunc={() => {setEye(!eye)}}/>
          </div>
        </div>
        <div className="easy-forms">
          <div>
            <label>Binance</label>
            <SwitchToggle isOn={binanceExchange} onClickFunc={() => {setBinanceExchange(!binanceExchange)}}/>
          </div>
          <div>
            <label>Kucoin</label>
            <SwitchToggle isOn={kucoinExchange} onClickFunc={() => {setKucoinExchange(!kucoinExchange)}}/>
          </div>
          <div>
            <label>Poloniex</label>
            <SwitchToggle isOn={poloniexExchange} onClickFunc={() => {setPoloniexExchange(!poloniexExchange)}}/>
          </div>
          <div>
            <label>Ascendex</label>
            <SwitchToggle isOn={ascendexExchange} onClickFunc={() => {setAscendexExchange(!ascendexExchange)}}/>
          </div>
          <div>
            <label>Table</label>
            <select value={table} onChange={(e) => {setTable(e.target.value)}}>
              <option value="KlineMinuteOne">Minute One</option>
              <option value="KlineMinuteFive">Minute Five</option>
              <option value="KlineMinuteFifteen">Minute Fifteen</option>
              <option value="KlineHourOne">Hour One</option>
            </select>
          </div>
        </div>  
        <div className="easy-forms">
          <div>
            <label>Binance</label>
            <input type="number" value={binanceMoney} onChange={(e) => {setBinanceMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Kucoin</label>
            <input type="number" value={kucoinMoney} onChange={(e) => {setKucoinMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Poloniex</label>
            <input type="number" value={poloniexMoney} onChange={(e) => {setPoloniexMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Ascendex</label>
            <input type="number" value={ascendexMoney} onChange={(e) => {setAscendexMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Okex</label>
            <input type="number" value={okexMoney} onChange={(e) => {setOkexMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Gateio</label>
            <input type="number" value={gateioMoney} onChange={(e) => {setGateioMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Huobi</label>
            <input type="number" value={huobiMoney} onChange={(e) => {setHuobiMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Ftx</label>
            <input type="number" value={ftxMoney} onChange={(e) => {setFtxMoney(e.target.value)}}/>
          </div>
        </div>

        <div className="easy-forms">
          <div>
            <label>Ponies</label>
            <SwitchToggle isOn={showPonies} onClickFunc={() => {setShowPonies(!showPonies)}}/>
          </div>
          <div>
            <label>Desc</label>
            <SwitchToggle isOn={desc} onClickFunc={() => {setDesc(!desc)}}/>
          </div>
          <div>
            <label>Picks</label>
            <SwitchToggle isOn={picks} onClickFunc={() => {setPicks(!picks)}}/>
          </div>
          <div>
            <label>RWinners</label>
            <SwitchToggle isOn={realWinners} onClickFunc={() => {setRealWinners(!realWinners)}}/>
          </div>
          <div>
            <label>Inspect</label>
            <SwitchToggle isOn={inspect} onClickFunc={() => {setInspect(!inspect)}}/>
          </div>
          <div>
            <label>Rapid</label>
            <SwitchToggle isOn={rapid} onClickFunc={() => {setRapid(!rapid)}}/>
          </div>
        </div>
        <div className="buttons">
          {/* <Button type="button" onClick={() => {
            // fetchResults(false);
            toast.info("Yeah, one doesn't work yet")
          }}>Simulate One</Button> */}
          <Button type="submit" value="Batch Simulate" />
        </div>
      </form>


      <div className="results-div">
        <Loading show={loading} />
        <div className="gains">
          <div>
            <label>Gains {resultsElapsedTime} secs</label>
            <Button parentClassName="delete-batch-results" status={(loading)?"loading":"delete"} onClick={()=>{deleteBatchResults(batchId)}}>Delete Batch Results</Button>
            {/* <label><button onClick={() => {console.log("forgeResult",forgeResult)}}>Console Log Forge Result</button></label> */}
          </div>
          <div className="gains-container">
            {
              (gains['total'])?
                <div className="gain">
                  <label>Total</label>
                  <label>0</label>
                  <table>
                    <tbody>
                    {
                      Object.keys(gains['total']).map((v2,i2) => {
                        if(!gains['total'][v2]) return null;
                        if(v2 === "details") return null;
                        let gain = gains['total'][v2];

                        if(parseFloat(gain.money) <= 0) return null;

                        return (
                          <tr key={i2} className="gain">
                            <td className="label">{StringUtil.capitalize(v2)}:</td>
                            <td className="percentage">{NumberUtil.numberWithCommas(gain.percentage)}%</td>
                            <td className="value">${NumberUtil.numberWithCommas(Math.round(gain.money*100)/100)}</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                  <button onClick={() => {console.log("gains['total']",gains['total'])}}>log gains</button>
                </div>
              :null
            }
            {
              Object.keys(gains).map((v,i) => {
                if(v === "total") return null;
                return(
                  <div key={i} className="gain">
                    <label>{v}</label>
                    <label>{parseInt(v)/60000}</label>
                    <table key={i}>
                      <tbody>
                      {
                        Object.keys(gains[v]).map((v2,i2) => {
                          if(!gains[v][v2]) return null;
                          if(v2 === "details") return null;

                          let gain = gains[v][v2];

                          console.log("gain",gain);

                          if(parseFloat(gain.money) <= 0) return null;
                          

                          return (
                            <tr key={i2} className="gain">
                              <td className="label">{StringUtil.capitalize(v2)}:</td>
                              <td className="percentage">{NumberUtil.numberWithCommas(gain.percentage)}%</td>
                              <td className="value">${NumberUtil.numberWithCommas(Math.round(gain.money*100)/100)}</td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </table>
                    <button onClick={() => {console.log("gains[v]",gains[v])}}>log gains</button>
                    <button onClick={() => {console.log("theGrid[v]",theGrid[v])}}>log grid</button>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );

};
// 
export default AchillesSimulator;