import { useEffect } from "react";

//  const useDebouncedEffect = (func, delay, deps) => {
//   const callback = useCallback(func, deps);

//   useEffect(() => {
//     const handler = setTimeout(() => {
//       callback();
//     }, delay);

//     return () => {
//       clearTimeout(handler);
//     };
//   }, [callback, delay,]);
// }

const useDebouncedEffect = (effect, delay ,deps) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps || [], delay]);
}

export default useDebouncedEffect;

