import React from 'react';
import {ReactComponent as DashboardSvg} from '../../css/imgs/icon-dashboard.svg';
import {ReactComponent as HephaestusSvg} from '../../css/imgs/greek-gods/hephaestus.svg';
import {ReactComponent as ZeusSvg} from '../../css/imgs/greek-gods/zeus.svg';
// import {ReactComponent as HadesSvg} from '../../css/imgs/greek-gods/hades.svg';
// import {ReactComponent as PoseidonSvg} from '../../css/imgs/greek-gods/poseidon.svg';
import {ReactComponent as HeraSvg} from '../../css/imgs/greek-gods/hera.svg';
import {ReactComponent as AresSvg} from '../../css/imgs/greek-gods/ares.svg';
import {ReactComponent as AphroditeSvg} from '../../css/imgs/greek-gods/aphrodite.svg';
import {ReactComponent as AchillesSvg} from '../../css/imgs/greek-gods/achilles.svg';
import {ReactComponent as AthenaSvg} from '../../css/imgs/greek-gods/athena.svg';
import {ReactComponent as ArtemisSvg} from '../../css/imgs/greek-gods/artemis.svg';
import {ReactComponent as HermesSvg} from '../../css/imgs/greek-gods/hermes.svg';
import {ReactComponent as DemeterSvg} from '../../css/imgs/greek-gods/demeter.svg';
import {ReactComponent as DionysusSvg} from '../../css/imgs/greek-gods/dionysus.svg';

import AccountStatusEnum from '../../enums/account-status';

const naviRoutes = [
  {
    "name": "dashboard-nav-item", 
    "display": "Dashboard", 
    "path": "/dashboard",
    "icon": <DashboardSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "demeter", 
    "display": "Demeter", 
    "desc": "Exchange Symbols",
    "path": "/demeter",
    "icon": <DemeterSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "aphrodite", 
    "display": "Aphrodite",
    "desc": "Kline Health", 
    "path": "/aphrodite",
    "icon": <AphroditeSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "dionysus", 
    "display": "Dionysus",
    "desc": "Kline Length", 
    "path": "/dionysus",
    "icon": <DionysusSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "hera", 
    "display": "Hera", 
    "desc": "RV Bot",
    "path": "/hera",
    "icon": <HeraSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  // {
  //   "name": "hades", 
  //   "display": "Hades", 
  //   "desc": "",
  //   "path": "/hades",
  //   "icon": <HadesSvg/>,
  //   "admin": true,
  // },
  // {
  //   "name": "poseidon", 
  //   "display": "Poseidon", 
  //   "desc": "",
  //   "path": "/poseidon",
  //   "icon": <PoseidonSvg/>,
  //   "admin": true,
  // },
  {
    "name": "achilles", 
    "display": "Achilles",
    "desc": "Batch Simulator", 
    "path": "/achilles",
    "icon": <AchillesSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "hephaestus", 
    "display": "Hephaestus", 
    "desc": "Singular Simulator",
    "path": "/hephaestus",
    "icon": <HephaestusSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "athena", 
    "display": "Athena", 
    "desc": "Move Inspector",
    "path": "/athena",
    "icon": <AthenaSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "artemis", 
    "display": "Artemis",
    "desc": "Sim Results", 
    "path": "/artemis",
    "icon": <ArtemisSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "ares", 
    "display": "Ares", 
    "desc": "Real Hera Moves",
    "path": "/ares",
    "icon": <AresSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "hermes", 
    "display": "Hermes", 
    "desc": "Move Analysis",
    "path": "/hermes",
    "icon": <HermesSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  {
    "name": "zeus", 
    "display": "Zeus", 
    "desc": "Bitstamp DB",
    "path": "/zeus",
    "icon": <ZeusSvg/>,
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  // {
  //   "name": "dawicoin", 
  //   "display": "Dawicoin",
  //   "path": "/dawicoin",
  //   "minStatus": AccountStatusEnum.MiniAdmin,
  // },
  {
    "name": "test", 
    "display": "Test",
    "path": "/test",
    "minStatus": AccountStatusEnum.MiniAdmin,
  },
  // {
  //   "name": "test-two", 
  //   "display": "Test Two",
  //   "path": "/test-two",
  //   "minStatus": AccountStatusEnum.MiniAdmin,
  // },
];

export default naviRoutes;