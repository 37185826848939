import React,{useState} from 'react';
import './navi-language-modal.scss';
import {availableLanguages} from '../../translations/all';
import Modal from '../common/modal/modal';
import {Environment,EnvironmentEnum} from '../../config/env';
import Config from '../../config/config';
import { useTranslation } from "react-i18next";

const NaviLanguageModal = ({show,handleClose}) => {

  const [redirect,setRedirect] = useState(null);
  const { t } = useTranslation();

  if(redirect !== null){
    window.location.href = redirect;
  }

  return(
    <Modal className="navi-language-modal" show={show} handleClose={handleClose}>
      <div>

        <h2>{t('navi.changeLanguage')}</h2>
        <ul className="available-languages-list">
          {availableLanguages.map((d,i) => {
            return(
              <li key={i} onClick={() => {
                let redirectLink = "";
                if(Environment === EnvironmentEnum.Local){
                  localStorage.setItem("language",d.code);
                  redirectLink = window.location.href;
                }else{
                  const loc = window.location
                  const protomatch = /^(https?|ftp):\/\//; 
                  const noProtocolFrontEndUrl = Config.Common.FrontEndUrl.replace(protomatch, '');

                  redirectLink = loc.protocol+"//";
                  if(d.code !== "en"){
                    redirectLink += d.code+".";
                  }
                  redirectLink += noProtocolFrontEndUrl;
                  redirectLink += loc.pathname;
                }
                setRedirect(redirectLink);
              }}>{d.nativeName} / {d.name}</li>
            )
          })}
        </ul>
      </div>
    </Modal>
  )
};

export default NaviLanguageModal;