import React,{ useState } from 'react';
import './regression-check.scss';

import Regression from 'regression';
import { useEffect } from 'reactn';

const RegressionCheck = () => {

  const [order,setOrder] = useState(2);
  const [precision,setPrecision] = useState(2);
  const inputInit = "100,105,110,115,120,125,130,125,120,115,110";
  const [input,setInput] = useState((localStorage.getItem("regressionCheckInput") !== null)?localStorage.getItem("regressionCheckInput"):inputInit);
  const [results,setResults] = useState({
    linear: 0,
    poly: 0,
    exp: 0,
  })

  useEffect(() => {
    localStorage.setItem("regressionCheckInput",input);
  },[input]);

  const submitHandler = (e) => {
    e.preventDefault();

    let res = input.split(",");
    let regArray = [];
    for (let i = 0; i < res.length; i++) {
      const element = parseInt(res[i]);
      regArray.push([i,element]);
    }

    const regl = Regression.linear(regArray, { order: parseInt(order),precision: parseInt(precision) });
    const regp = Regression.polynomial(regArray, { order: parseInt(order),precision: parseInt(precision) });
    const rege = Regression.exponential(regArray, { order: parseInt(order),precision: parseInt(precision) });

    const lr = regl.predict(regArray.length)[1];
    const pr = regp.predict(regArray.length)[1];
    const er = rege.predict(regArray.length)[1];

    setResults({
      linear: lr,
      poly: pr,
      exp: er,
    })
  }

  return (
    <div className="regression-check">
      <form onSubmit={submitHandler}>
        <div className="one">
          <div className="big">
            <label>Input</label>
            <input type="text" value={input} onChange={(e)=>{setInput(e.target.value)}}/>
          </div>
        </div>
        <div className="one">
          <div className="small">
            <label>Order</label>
            <input type="text" value={order} onChange={(e)=>{setOrder(e.target.value)}}/>
          </div>
          <div className="small">
            <label>Precision</label>
            <input type="text" value={precision} onChange={(e)=>{setPrecision(e.target.value)}}/>
          </div>
          <input className="button" type="submit"/>
          <div className="results">
            <div>l:{results.linear}</div>
            <div>p:{results.poly}</div>
            <div>e:{results.exp}</div>
          </div>
        </div>
      </form>
      
    </div>
  );

};

export default RegressionCheck;