import React,{useState,useEffect,} from 'react';
import './new-device.scss';

import Button from '../../../elements/button/button';
import CenterDiv from '../../../common/center-div/center-div';
import { Redirect } from 'react-router-dom';
import * as BrowserUtil from '../../../../utils/browser';
import {toast} from 'react-toastify';
import {request,unSubRequest} from '../../../../utils/request';
import useUserState from '../../../../store/user';

const NewDevice = () => {

  const {setUser} = useUserState();
  const [loading,setLoading] = useState(false);
  const token = BrowserUtil.getQueryVariable("token");
  const [value,setValue] = useState("");
  const [redirect,setRedirect] = useState(null);

  useEffect(() => {
    return () => {
      unSubRequest("login-device")
    }
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    totpLoginRequest();
  }

  const totpLoginRequest = () => {
    if(value === "") return;

    const data = {
      deviceLoginToken: token,
      deviceSecret: value,
    };
    if(localStorage.getItem("device")){
      data.deviceToken = localStorage.device;
    }

    setLoading(true);
    request("login-device","/login-device","POST", data, {
      then: function(res){
        let results = res.data.res;
        console.log("results 2",results);
        if(results.auth && results.user){
          toast.success("Success! Logging you in 😎");
          if(results.newDeviceToken) localStorage.setItem("device",results.newDeviceToken);
          let holdUser = results.user;
          holdUser.auth = results.auth;
          setUser(holdUser);
        }else{
          toast.error("Error: Auth Tokens not returned. Please contact us if this happens.");
        }
      },
      catch: function(err){
        let newRedirect = (err.redirect)?"/login":redirect;
        setRedirect(newRedirect);
        toast.error(err.message);
      },
      finally: function(){setLoading(false);}
    });
  }

  if(redirect){
    return <Redirect to={redirect}/>
  }

  return (
    <CenterDiv>
      <div className="totp-component">
        <h1>Confirm New Device</h1>
        <span className="text">We don't recognize this device, we've sent an authorization code to your email address to confirm it's really you. </span>
        <form onSubmit={handleSubmit}>
          <label>New Device Authorization Code</label>
          <input type="text" value={value} onChange={(e) => {setValue(e.target.value)}}/>
          <span className="hint">Enter the code sent to your email. </span>
          <Button status={(loading)?"loading":""} type="submit"/>
        </form>
      </div>
    </CenterDiv>
    
  );

};

export default NewDevice;