import React from 'react';
import './dawicoin.scss';
import * as NumberUtil from '../../../utils/number';

const Dawicoin = () => {

  let btcCurrentTickerPrice = 21000;
  // let totalMarketCap = "8,000,000,000";
  let totalSupply = "8,000,000,000";
  // totalMarketCap = parseInt(totalMarketCap.replace(/,/g,""));
  totalSupply = parseInt(totalSupply.replace(/,/g,""));


  let prices = [];
  let startPrice = 0.0000001;
  let incmrentPrice = 0.00000001;
  let endPrice = 0.0001;
  let currentPrice = startPrice;
  while(currentPrice <= endPrice){
    prices.push(currentPrice);
    currentPrice += incmrentPrice;
    currentPrice = NumberUtil.valFloatPointFix(currentPrice);
  }



  let totalBtcValue = 0;
  let totalUsdValue = 0;
  let totalDawi = 0;
  let orders = 0;
  return (
    <div className="dawicoin-comp">
      <h3>dawicoin-comp</h3>

      <table>
        <tbody>
          <tr>
            <th>Price</th>
            <th>Price (USD)</th>
            <th>Amount</th>
            <th>BTC Value</th>
            <th>USD Value</th>
            <th>Total BTC Value</th>
            <th>Total USD Value</th>
            <th>Total Dawi</th>
            <th>Market Cap</th>
            <th>Diluted Market Cap</th>
            <th>Orders</th>
          </tr>
          {prices.map((v,i) => {
            orders++;
            let price = v;
            let usdPrice = price * btcCurrentTickerPrice;
            let amount = 120000;  
            totalDawi += amount;          
            let btcValue = price * amount;
            btcValue = NumberUtil.valFloatPointFix(btcValue);

            let usdValue = btcValue * btcCurrentTickerPrice;
            usdValue = NumberUtil.valFloatPointFix(usdValue);

            totalBtcValue = totalBtcValue + btcValue
            totalBtcValue = NumberUtil.valFloatPointFix(totalBtcValue);

            totalUsdValue = totalUsdValue + usdValue
            totalUsdValue = NumberUtil.valFloatPointFix(totalUsdValue);

            return(
              <tr key={i}>
                <td>{price.toFixed(8)}</td>
                <td>{NumberUtil.formatDollar(usdPrice)}</td>
                <td>{NumberUtil.numberWithCommas(amount)}</td>
                <td>{btcValue.toFixed(8)}</td>
                <td>{NumberUtil.formatDollar(usdValue)}</td>
                <td>{totalBtcValue.toFixed(8)}</td>
                <td>{NumberUtil.formatDollar(totalUsdValue)}</td>
                <td>{NumberUtil.numberWithCommas(totalDawi)}</td>
                <td>{NumberUtil.formatDollar(totalDawi * usdPrice)}</td>
                <td>{NumberUtil.formatDollar(totalSupply * usdPrice)}</td>
                <td>{orders}</td>
              </tr>
            )
          })

          }
        </tbody>
      </table>


      {/* <div className='thumbnail'>
        <div className='image'>
          <img src="https://api.dawicoin.com/images/DAWI-big.png"/>
        </div>
        <div className='text'>
          Set Up Payment Button on Dawicoin.com
        </div>
      </div> */}
    </div>
  );
};

export default Dawicoin;