import React,{useState, useEffect} from 'react';
import './entry.scss';

import CenterDiv from '../../common/center-div/center-div';
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import SignIn from './sign/sign-in';
import SignUp from './sign/sign-up';
import SnsButton from '../../elements/sns-button/sns-button';
import Logo from '../../common/logo/logo';
import {ReactComponent as WarningSvg} from '../../../css/imgs/icon-warning.svg';

import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import Config,{ConfigVariables} from '../../../config/config';

const Entry = ({option,code,loginSuccessCallback,createAccountSuccessCallback}) => {
  let slideInit = 1;
  if(option !== null && option === "create-account"){;
    slideInit = 2;
  }

  if(code){
    localStorage.setItem("referralCode",code);
  }

  const snsOptions = ConfigVariables.SnsAuthMethods

  const [slide,setSlide] = useState(slideInit);
  const [check,setCheck] = useState(false);
  const checkUpdateFunc = (check) => { setCheck(check); }

  // const [merchantReferralInfo,setMerchantReferralInfo] = useState(null);
  // const [loading,setLoading] = useState(false);

  useEffect(() => {
    handleReferralCode();
    return () => {
      unSubRequest("merchant-affiliate");
    }
  },[]);

  const handleReferralCode = () => {
    if(localStorage.getItem("refPriceUid")){
      const refPriceUid = localStorage.getItem("refPriceUid");
      // setLoading(true);
      request("merchant-affiliate","/merchant-affiliate/"+refPriceUid,"GET", {}, {
        then: (res) => {
          // setMerchantReferralInfo(res.data.res);
        },
        catch: (err) => { toast.error(err.message)
          localStorage.removeItem("refPriceUid");
        },
        finally: () => {
          // setLoading(false);
        }
      });
    }
  }

  return (
    <div className="entry-comp">
      {/* {(merchantReferralInfo || loading)?
      <CenterDiv>
        <div className="merchant-ref">
          <p>After signing in you'll be able to continue purchasing {(merchantReferralInfo && merchantReferralInfo.storeName)?merchantReferralInfo.productName:"loading..."} from {(merchantReferralInfo && merchantReferralInfo.storeName)?merchantReferralInfo.storeName:"loading..."}</p>
        </div>
      </CenterDiv>:null} */}
      <CenterDiv>
        <div className="entry">
          <div className="entry-header">
            <Logo options="large"/>
          </div>
          {(Config.Sandbox)?
            <div className='sandbox-note'>
              <div className='sandbox-note-header'>
                <WarningSvg />
                <div className='text'>Sandbox Environment</div>
                <WarningSvg />
              </div>
              <p>This is the Sandbox Environment. It's meant for merchants & developers to test.</p>
              <p><a href={Config.Common.LiveFrontEndUrl+"/login"}>Regular Users should use the live version. Click here to be redirected there. </a></p>
            </div>
          :null}
          <CarouselHeader className="entry-options hlo" slideTo={slide}>
            <div onClick={() => {setSlide(1)}}>Sign In</div>
            <div onClick={() => {setSlide(2)}}>Create Account</div>
          </CarouselHeader>
          <Carousel slideTo={slide}>
            <div className="sign-in-slide"><SignIn successCallback={loginSuccessCallback}/></div>
            <div className="sign-up-slide"><SignUp successCallback={createAccountSuccessCallback} checkUpdateFunc={checkUpdateFunc}/></div>
          </Carousel>

          {(snsOptions.length <= 0)?null:
            <div className="entry-sns">
              <div className="line-div">
                <span className="line"></span>
                <span className="text">or</span>
                <span className="line"></span>
              </div>
              {snsOptions.map((d,i) => {return(<SnsButton key={i} snsType={d} checkNeeded={slide === 2 && !check} />)})}
            </div>
          }
          
        </div>
      </CenterDiv>
    </div>
    
  );

};

export default Entry;