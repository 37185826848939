import React from 'react';
import './hera-result-item.scss';
// import * as NumberUtil from '../../../utils/number';
import {clone} from '../../../utils/clone'


const HeraResultItem = ({value,setViewBikeCoin,tickers}) => {
  // console.log("HeraResultItem")
  // console.log("value",value);

  let quoteAsset = "USDT";
  let quoteAssetValue = 0;
  let id = value.owner+"-"+value.exchange+"-"+value.apiKeyName;

  let exchangeValue = 0;
  let bikeValue = 0;
  for (let i = 0; i < value.exchangeCoins.length; i++) {
    const exchangeCoin = value.exchangeCoins[i];
    if(exchangeCoin.coin === quoteAsset){
      bikeValue += exchangeCoin.amount;
      quoteAssetValue += exchangeCoin.amount;
      exchangeValue += exchangeCoin.amount; continue;
    }
    let symbolKey = exchangeCoin.coin+"-"+quoteAsset;
    let liveValue = tickers[symbolKey];
    if(!liveValue){
      // console.log("no data symbolKey",symbolKey)
      continue;
    } 
    let value2 = liveValue * exchangeCoin.amount;
    exchangeValue += parseFloat(value2);
  }

  for (let i = 0; i < value.bikeCoins.length; i++) {
    const bikeCoin = value.bikeCoins[i];
    let symbolKey = bikeCoin.coin+"-"+quoteAsset;
    let liveValue = tickers[symbolKey];
    if(!liveValue){
      // console.log("no data symbolKey",symbolKey)
      continue;
    } 
    let value2 = liveValue * bikeCoin.amount;
    bikeValue += parseFloat(value2);
  }

  let avgBikeValue = (bikeValue && value.bikeCount)?bikeValue/value.bikeCount:0

  quoteAssetValue = parseFloat(quoteAssetValue).toFixed(2);
  exchangeValue = parseFloat(exchangeValue).toFixed(2);
  bikeValue = parseFloat(bikeValue).toFixed(2);
  avgBikeValue = parseFloat(avgBikeValue).toFixed(2);

  return (
    <tr className="hera-result-item">
      <td className="result-id">{id}</td>
      <td className="result-exchange-value">{exchangeValue}</td>
      <td className="result-bike-value">{bikeValue}</td>
      <td className="result-bike-value">{quoteAssetValue}</td>
      <td className="result-bikes">{value.bikeCount}</td>
      <td className="result-avg-amount">{avgBikeValue}</td>
      <td className="result-expand-coins"><button onClick={() => {setViewBikeCoin(clone(value))}}>Expand</button></td>
      <td><button onClick={() => {console.log(value)}}>Log</button></td>
    </tr>
  );

};

export default HeraResultItem;

