import React,{useEffect, useState} from 'react';
import './hermes.scss';
import DateEpochInput from '../../elements/date-epoch-input/date-epoch-input';
import usePersistState from '../../../utils/use-persist-state';
import Button from '../../elements/button/button';
import { toast } from 'react-toastify';
import { request, unSubRequest } from '../../../utils/request';

const Hermes = () => {

  const oneMinute = 60 * 1000;

  let rangeStartInit = 1483232400000;//jan 1 2017 1am
  // let rangeStartInit = 1501545600000;//aug 1 2017 1am
  let rangeEndInit = new Date().getTime();

  // const [loading,setLoading] = useState(false);
  const [rangeStart,setRangeStart] = useState(rangeStartInit);
  const [rangeEnd,setRangeEnd] = useState(rangeEndInit);

  const [trendLength,setTrendLength] = usePersistState(8,"hermesTrendLength","number");
  const [trendStep,setTrendStep] = usePersistState(oneMinute * 60,"hermesTrendStep","number");
  const [table,setTable] = usePersistState("KlineHourOne","hermesTable");
  const [futureDuration,setFutureDuration] = usePersistState(oneMinute * 60 * 12,"hermesFutureDuration","number");

  const [group,setGroup] = useState({});
  const [groupAverages,setGroupAverages] = useState({});


  useEffect(() => {
    return() => {
      unSubRequest("hermes-fetch-regresion-analysis");
      unSubRequest("hermes-analyze-range");
      unSubRequest("hermes-analyze-range-stop");
    }
  },[])


  const handleSubmit = (e) => {
    e.preventDefault();
    fetchRegresion()
  }

  const fetchRegresion = () => {
    let endpoint = "/hermes-fetch-regresion-analysis";
    let data = {
      startTime: rangeStart,
      endTime: rangeEnd,
      trendStep: trendStep,
      trendLength: trendLength,
      futureDuration: futureDuration,
      sqltable: table,
    }
    request("hermes-fetch-regresion-analysis",endpoint,"POST", data, {
      then: function(res){
        console.log("res",res);
        setGroup(res.data.res.group);
        setGroupAverages(res.data.res.groupAverages);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
      }
    }) 
  }

  const crawlRegression = () => {
    let endpoint = "/hermes-analyze-range";
    let data = {
      startTime: rangeStart,
      endTime: rangeEnd,
      trendStep: trendStep,
      trendLength: trendLength,
      futureDuration: futureDuration,
      sqltable: table,
    }
    request("hermes-analyze-range",endpoint,"POST", data, {
      then: function(res){toast.info("finished crawl")},
      catch: function(err){toast.error(err.message);},
      finally: function(){
      }
    })
  }
  const stopCrawlRegression = () => {
    let endpoint = "/hermes-analyze-range-stop";
    request("hermes-analyze-range-stop",endpoint,"POST", {}, {
      then: function(res){toast.info("stopped")},
      catch: function(err){toast.error(err.message);},
      finally: function(){
      }
    })
  }

  return (
    <div className="hermes">
      <h3>Hermes</h3>
      <form onSubmit={handleSubmit}>
        <div className="block">
          <div>
            <label>Range Start</label>
            <DateEpochInput time={rangeStart} setTime={setRangeStart} />
          </div>
          <div>
            <label>Range End</label>
            <DateEpochInput time={rangeEnd} setTime={setRangeEnd} />
          </div>
        </div>
        <div className="block">
          <div>
            <label>Trend Step</label>
            <select value={trendStep} onChange={(e) => setTrendStep(e.target.value)}>
              <option value={oneMinute}>1 minute</option>
              <option value={oneMinute * 5}>5 minutes</option>
              <option value={oneMinute * 15}>15 minutes</option>
              <option value={oneMinute * 60}>1 hour</option>
            </select>
          </div>
          <div>
            <label>Trend Length</label>
            <input type="text" value={trendLength} onChange={(e) => {setTrendLength(e.target.value)}}/>
          </div>
          <div>
            <label>Table</label>
            <select value={table} onChange={(e) => {setTable(e.target.value)}}>
              <option value="KlineMinuteOne">Minute One</option>
              <option value="KlineMinuteFive">Minute Five</option>
              <option value="KlineMinuteFifteen">Minute Fifteen</option>
              <option value="KlineHourOne">Hour One</option>
            </select>
          </div>
          <div>
            <label>Future Duration</label>
            <select value={futureDuration} onChange={(e) => setFutureDuration(e.target.value)}>
              <option value={oneMinute}>1 minute</option>
              <option value={oneMinute * 3}>3 minutes</option>
              <option value={oneMinute * 5}>5 minutes</option>
              <option value={oneMinute * 15}>10 minutes</option>
              <option value={oneMinute * 15}>15 minutes</option>
              <option value={oneMinute * 30}>30 minutes</option>
              <option value={oneMinute * 60}>1 hour</option>
              <option value={oneMinute * 60 * 2}>2 hours</option>
              <option value={oneMinute * 60 * 3}>3 hours</option>
              <option value={oneMinute * 60 * 4}>4 hours</option>
              <option value={oneMinute * 60 * 5}>5 hours</option>
              <option value={oneMinute * 60 * 6}>6 hours</option>
              <option value={oneMinute * 60 * 7}>7 hours</option>
              <option value={oneMinute * 60 * 8}>8 hours</option>
              <option value={oneMinute * 60 * 9}>9 hours</option>
              <option value={oneMinute * 60 * 10}>10 hours</option>
              <option value={oneMinute * 60 * 11}>11 hours</option>
              <option value={oneMinute * 60 * 12}>12 hours</option>
              <option value={oneMinute * 60 * 24}>24 hours</option>
              <option value={oneMinute * 60 * 36}>36 hours</option>
              <option value={oneMinute * 60 * 24 * 2}>2 days</option>
              <option value={oneMinute * 60 * 24 * 3}>3 days</option>
              <option value={oneMinute * 60 * 24 * 5}>5 days</option>
              <option value={oneMinute * 60 * 24 * 7}>1 week</option>
            </select>
          </div>
        </div>
        <div className="buttons">
          <Button type="submit" value={"Fetch"} />
          <Button type="button" onClick={() => {crawlRegression()}}>Start Crawl</Button>
          <Button type="button" onClick={() => {stopCrawlRegression()}}>Stop Crawl</Button>
        </div>
      </form>
      
      <div className="results">
        <label>groupAverages</label>
        <table>
          <tbody>
            <tr>
              <th>label</th>
              {(groupAverages['total'] !== undefined)?Object.keys(groupAverages['total']).map((key2, i2) => {return (<th key={key2}>{key2}</th>)}):null}
              <th>log</th>
            </tr>
            {Object.keys(groupAverages).map((key, i) => {
              let item = groupAverages[key]
              return(
                <tr key={key}>
                  <td>{key}</td>
                  {Object.keys(item).map((key2, i2) => {return (<td key={key2}>{item[key2]}</td>)})}
                  <td><button onClick={() => {console.log(key,group[key])}}>log</button></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Hermes;