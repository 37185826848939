import React from 'react';
import './ares.scss';
import * as DateUtil from '../../../utils/date';
// import * as NumberUtil from '../../../utils/number';
import Table from '../..//elements/table/table';

const Ares = () => {

  const sideItemColumnInfo = {
    column: {
      value:"exchange",
    },
    action: "=",
  }
  const sideItems = [
    // {
    //   value: "all",
    //   ignore: true,
    // },
    // {value: "ascendex", custom:({active,value}) => <SideItem active={active} value={value} />},
    // {value: "binance", custom:({active,value}) => <SideItem active={active} value={value} />},
    // {value: "ftx", custom:({active,value}) => <SideItem active={active} value={value} />},
    // {value: "gateio", custom:({active,value}) => <SideItem active={active} value={value} />},
    // {value: "kucoin", custom:({active,value}) => <SideItem active={active} value={value} />},
    // {value: "okex", custom:({active,value}) => <SideItem active={active} value={value} />},
    // {value: "poloniex", custom:({active,value}) => <SideItem active={active} value={value} />},
  ];

  const columns = [
    {
      table: "heraMoves",
      value: "id",
      hide: true,
    },
    {
      table: "heraMoves",
      value: "bikeId",
      default: true,
    },
    {
      table: "heraMoves",
      value: "details",
      custom: ({v,cv}) => <textarea defaultValue={v[cv]} readOnly></textarea>,
    },
    {
      table: "heraMoves",
      value: "epochTime",
    },
    {
      table: "heraMoves",
      value: "createdDate",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,
    },
  ]

  return (
    <div className="ares">
      <h3>Ares</h3>

      <Table 
        displayName="Ares"
        name="AresTable"
        sideItems={sideItems}
        sideItemColumnInfo={sideItemColumnInfo}
        joinOptions={[
          // {joinType:"LEFT JOIN",leftTable:"symbols",rightTable:"symbolsTestA",leftColumn:"id",rightColumn:"symbolId",},
        ]}
        rowOnClickFunc={(value) => {
          // console.log("row clicked",value);
        }}
        columns={columns}
        limit={250}
      />

    </div>
  );

};

export default Ares;