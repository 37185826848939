import React,{useRef} from 'react';
import './button.scss'

import Loading from '../../common/loading/loading';
import {ReactComponent as VisibilityOnSvg} from '../../../css/imgs/icon-visibility-on.svg';
import {ReactComponent as VisibilityOffSvg} from '../../../css/imgs/icon-visibility-off.svg';
import {ReactComponent as AddSvg} from '../../../css/imgs/icon-add.svg';
import {ReactComponent as EditSvg} from '../../../css/imgs/icon-edit.svg';
import {ReactComponent as SaveSvg} from '../../../css/imgs/icon-save.svg';
import {ReactComponent as DeleteSvg} from '../../../css/imgs/icon-delete.svg';
import {ReactComponent as CloseSvg} from '../../../css/imgs/icon-close.svg';
import {ReactComponent as ContinueSvg} from '../../../css/imgs/icon-arrow-forward.svg';
import {ReactComponent as RefreshSvg} from '../../../css/imgs/icon-refresh.svg';
import {ReactComponent as SearchSvg} from '../../../css/imgs/icon-search.svg';
import {ReactComponent as SearchAdvSvg} from '../../../css/imgs/icon-search-advanced.svg';
import {ReactComponent as CopySvg} from '../../../css/imgs/icon-copy.svg';
import {ReactComponent as FilterSvg} from '../../../css/imgs/icon-filter-list.svg';


// types = ["submit", "button"]
// status = ["loading","showing","hiding","edit","save","add","delete", null, undefined]
const Button = ({type,status,parentClassName,...rest}) => {

  const buttonRef = useRef(null);

  let params = {...rest};
  let disable = false;
  if(status === "loading" || (params.disabled !== undefined && params.disabled === true)){
    disable = true;
  }
  let disabledClass = (disable) ? " disabled" : ""

  let parentClass = (parentClassName !== undefined) ? parentClassName : "";

  const imageElement = () => {

    let element = (<div></div>);

    if(status === "loading"){
      element = <Loading show="true" size="24px"/>
    }else if(status === "showing"){
      element = <VisibilityOffSvg className="light"/>
    }else if(status === "hiding"){
      element = <VisibilityOnSvg className="light"/>
    }else if(status === "edit"){
      element = <EditSvg className="light"/>
    }else if(status === "save"){
      element = <SaveSvg className="light"/>
    }else if(status === "add"){
      element = <AddSvg className="light"/>
    }else if(status === "delete"){
      element = <DeleteSvg className="light"/>
    }else if(status === "close"){
      element = <CloseSvg className="light"/>
    }else if(status === "continue"){
      element = <ContinueSvg className="light"/>
    }else if(status === "refresh"){
      element = <RefreshSvg className="light"/>
    }else if(status === "search"){
      element = <SearchSvg className="light"/>
    }else if(status === "search-advanced"){
      element = <SearchAdvSvg className="light"/>
    }else if(status === "copy"){
      element = <CopySvg className="light"/>
    }else if(status === "filter"){
      element = <FilterSvg className="light"/>
    }

    return element;
  }

  return (
    <div className={"button-element"+disabledClass+" "+parentClass} >
      <div className="background"></div>
      <div className="button-status-image">
        {imageElement()}
      </div>
      {(type === "submit") 
        ? <input type="submit" ref={buttonRef} disabled={disable} {...rest} />
        : <button ref={buttonRef} type="button" disabled={disable} {...rest} />
      }      
    </div>
  );
};

export default Button;