import React,{useState,useEffect} from 'react';
import { request, unSubRequest } from '../../../utils/request';
import './hephaestus-simulator.scss';
import {toast} from 'react-toastify'
import { clone } from '../../../utils/clone';
import Loading from '../../common/loading/loading';
import {prettifyDateWithTime} from '../../../utils/date';
import Button from  '../../elements/button/button';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import * as DateUtil from '../../../utils/date';
import * as NumberUtil from '../../../utils/number';
import * as StringUtil from '../../../utils/string';
import usePersistState from '../../../utils/use-persist-state';



const HephaestusSimulator = () => {

  let timenow = new Date();
  let getTimezoneOffset = (timenow.getTimezoneOffset() * -1) * 60 * 1000;

  const oneMinute = 60 * 1000;

  const [eye,setEye] = usePersistState(10,"achillesEye","boolean");
  const [loading,setLoading] = useState(false);
  const [startTime,setStartTime] = usePersistState("2020-01-01T02:00","hephStartTime");
  const [epochStartTime,setEpochStartTime] = useState(DateUtil.datetimeToEpoch(startTime)+getTimezoneOffset);
  const [duration,setDuration] = usePersistState(10,"hephDuration","number");
  const [moves,setMoves] = useState([])
  const [gains,setGains] = useState({});
  const [minQuoteAssetVolume,setMinQuoteAssetVolume] = usePersistState(150,"hephMinQuoteAssetVolume","number");
  const [resultsElapsedTime,setResultsElapsedTime] = useState(-1);
  const [batchId,setBatchId] = useState(-1);
  const [batchResults,setBatchResults] = useState({});
  const [batchResultsElapsedTime,setBatchResultsElapsedTime] = useState(-1);
  const [forgeResult,setForgeResult] = useState({});
  const [batchForgeArguments,setBatchForgeArguments] = useState({});


  const [hodlLength,setHodlLength] = usePersistState(10,"helpHodlLength","number");
  const [table,setTable] = usePersistState("KlineHourOne","hephTable");
  const [poniesToRide,setPoniesToRide] = usePersistState(3,"hephPoniesToRide","number");
  const [lag,setLag] = usePersistState(100,"hephLag","number");
  const [trendLength,setTrendLength] = usePersistState(8,"hephTrendLength","number");
  const [trendStep,setTrendStep] = usePersistState(oneMinute * 60,"hephTrendStep","number");
  const [stopLossPercentage,setStopLossPercentage] = usePersistState(100,"hephStopLossPercentage","number");
  const [algo,setAlgo] = usePersistState(1,"hephAlgo","number");

  const [showPonies,setShowPonies] = usePersistState(true,"hephShowPonies","boolean");
  const [inspect,setInspect] = usePersistState(true,"hephInspect","boolean");
  const [rapid,setRapid] = usePersistState(true,"helpRapid","boolean");
  const [desc,setDesc] = usePersistState(true,"hephDesc","boolean");
  const [picks,setPicks] = usePersistState(true,"hephPicks","boolean");
  const [realWinners,setRealWinners] = usePersistState(true,"hephRealWinners","boolean");

  const [binanceExchange,setBinanceExchange] = usePersistState(true,"hephBinanceExchange","boolean");
  const [kucoinExchange,setKucoinExchange] = usePersistState(true,"hephKucoinExchange","boolean");
  const [poloniexExchange,setPoloniexExchange] = usePersistState(true,"hephPoloniexExchange","boolean");
  const [ascendexExchange,setAscendexExchange] = usePersistState(true,"hephAscendexExchange","boolean");

  const [binanceMoney,setBinanceMoney] = usePersistState(1000,"hephBinanceMoney","number");
  const [kucoinMoney,setKucoinMoney] = usePersistState(1000,"hephKucoinMoney","number");
  const [poloniexMoney,setPoloniexMoney] = usePersistState(1000,"hephPoloniexMoney","number");
  const [okexMoney,setOkexMoney] = usePersistState(1000,"hephOkexMoney","number");
  const [gateioMoney,setGateioMoney] = usePersistState(1000,"hephGateioMoney","number");
  const [ascendexMoney,setAscendexMoney] = usePersistState(1000,"hephAscendexMoney","number");
  const [huobiMoney,setHuobiMoney] = usePersistState(1000,"hephHuobiMoney","number");
  const [ftxMoney,setFtxMoney] = usePersistState(1000,"hephFtxMoney","number");

  useEffect(() => {
    return () => {
      unSubRequest("hephaestus-simulate");
    }
  },[])

  const submitHandler = (e) => {
    e.preventDefault();
    fetchResults(false);
  }

  const fetchResults = (batch) => {
    let endpoint = "/hephaestus-simulate";
    let name = "hephaestus-simulate";
    if(batch) endpoint = "/hephaestus-batch-simulate";
    if(batch) name = "hephaestus-batch-simulate";

    // let hoursInMilliseconds = new Date(startTime).getTimezoneOffset() * 60 * 1000
    // let utcTime = new Date(new Date(startTime).getTime() - hoursInMilliseconds);

    let dataExchanges = [];
    if(binanceExchange) dataExchanges.push("binance");
    if(kucoinExchange) dataExchanges.push("kucoin");
    if(poloniexExchange) dataExchanges.push("poloniex");
    if(ascendexExchange) dataExchanges.push("ascendex");

    let moneyExchanges = {
      binance: binanceMoney,
      kucoin: kucoinMoney,
      poloniex: poloniexMoney,
      okex: okexMoney,
      gateio: gateioMoney,
      ascendex: ascendexMoney,
      huobi: huobiMoney,
      ftx: ftxMoney,
    }

    let data = {
      startTime: parseInt(epochStartTime),
      duration,
      hodlLength,
      minQuoteAssetVolume: parseInt(minQuoteAssetVolume),
      table,
      poniesToRide,
      trendLength,
      trendStep,
      lag,
      stopLossPercentage,
      dataExchanges,
      moneyExchanges,
      algo,
      inspect,
      rapid,
    }
    console.log("data in",data);

    setLoading(true);
    request(name,endpoint,"POST", data, {
      then: function(res){
        let d = res.data.res;
        console.log("d",d);
        // let movesHold = moves;
        // let gainsHold = gains;
        // let batchHold = batchResults;
        // let elapsedTime = resultsElapsedTime;
        // let elapsedBatchTime = batchResultsElapsedTime;
        // let batchIdHold = batchId;
        // let holdForgeResult = forgeResult;
        // let holdBatchForgeArguments = batchForgeArguments;
        // if(batch){
        //   batchHold = d.batchRes;
        //   elapsedBatchTime = d.elapsedTime;
        //   batchIdHold = (d.batchId !== undefined)?d.batchId:-1;
        //   holdBatchForgeArguments = d.arguments
        // }else{
        //   holdForgeResult = d;
        //   movesHold = clone(d.moves).reverse();
        //   gainsHold = d.gains;
        //   elapsedTime = d.elapsedTime;
        // }
        // setBatchResultsElapsedTime(elapsedBatchTime);
        // setBatchResults(batchHold);
        // setResultsElapsedTime(elapsedTime);
        // setMoves(movesHold);
        // setGains(gainsHold);
        // setBatchId(batchIdHold);
        // setForgeResult(holdForgeResult);
        // setBatchForgeArguments(holdBatchForgeArguments);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  const deleteBatchResults = (id) => {
    if(id < 1) return;
    setLoading(true);
    request("delete-hephaestus-batch-results","/hephaestus-batch-results","DELETE", {id}, {
      then: function(res){
        toast.success("Deleted batch Results");
        setBatchResults({});
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  return (
    <div className="hephaestus-simulator">
      <form className="cond-form" onSubmit={submitHandler}>
        <div className="easy-forms">
          <div>
            <label>Start Time (UTC)</label>
            <input type="datetime-local" value={startTime} onChange={(e) => {
              setStartTime(e.target.value);
              setEpochStartTime(DateUtil.datetimeToEpoch(e.target.value,true));
            }}/>
          </div>
          <div className="epoch-time">
            <label>Epoch Time</label>
            <input type="text" value={epochStartTime} onChange={(e) => {
              setEpochStartTime(e.target.value);
              setStartTime(DateUtil.epochToDatetimeLocal(e.target.value,true));
            }}/>
          </div>
        </div>
        <div className="easy-forms">
          <div>
            <label>Duration</label>
            <select value={duration} onChange={(e) => {setDuration(e.target.value)}}>
              <option value={oneMinute * 60 * 12}>12 hours</option>
              <option value={oneMinute * 60 * 24 * 1}>1 day</option>
              <option value={oneMinute * 60 * 24 * 3}>3 days</option>
              <option value={oneMinute * 60 * 24 * 5}>5 days</option>
              <option value={oneMinute * 60 * 24 * 7}>1 week</option>
              <option value={oneMinute * 60 * 24 * 7 * 2}>2 weeks</option>
              <option value={oneMinute * 60 * 24 * 7 * 3}>3 weeks</option>
              <option value={oneMinute * 60 * 24 * 30 * 1}>1 month</option>
              <option value={oneMinute * 60 * 24 * 30 * 2}>2 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 3}>3 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 4}>4 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 5}>5 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 6}>6 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 7}>7 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 8}>8 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 9}>9 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 10}>10 months</option>
              <option value={oneMinute * 60 * 24 * 30 * 11}>11 months</option>
              <option value={oneMinute * 60 * 24 * 365}>1 year</option>
              <option value={parseInt(oneMinute * 60 * 24 * 365 * 1.5)}>1.5 years</option>
              <option value={oneMinute * 60 * 24 * 365 * 2}>2 years</option>
            </select>
          </div>
          <div className={(eye)?"blur":""}>
            <label>hodlLength</label>
            <select value={hodlLength} onChange={(e) => {setHodlLength(e.target.value)}}>
              <option value={oneMinute}>1 minute</option>
              <option value={oneMinute * 3}>3 minutes</option>
              <option value={oneMinute * 5}>5 minutes</option>
              <option value={oneMinute * 15}>10 minutes</option>
              <option value={oneMinute * 15}>15 minutes</option>
              <option value={oneMinute * 30}>30 minutes</option>
              <option value={oneMinute * 60}>1 hour</option>
              <option value={oneMinute * 60 * 2}>2 hours</option>
              <option value={oneMinute * 60 * 3}>3 hours</option>
              <option value={oneMinute * 60 * 4}>4 hours</option>
              <option value={oneMinute * 60 * 5}>5 hours</option>
              <option value={oneMinute * 60 * 6}>6 hours</option>
              <option value={oneMinute * 60 * 7}>7 hours</option>
              <option value={oneMinute * 60 * 8}>8 hours</option>
              <option value={oneMinute * 60 * 9}>9 hours</option>
              <option value={oneMinute * 60 * 10}>10 hours</option>
              <option value={oneMinute * 60 * 11}>11 hours</option>
              <option value={oneMinute * 60 * 12}>12 hours</option>
              <option value={oneMinute * 60 * 24}>24 hours</option>
              <option value={oneMinute * 60 * 36}>36 hours</option>
              <option value={oneMinute * 60 * 24 * 2}>2 days</option>
              <option value={oneMinute * 60 * 24 * 3}>3 days</option>
              <option value={oneMinute * 60 * 24 * 5}>5 days</option>
              <option value={oneMinute * 60 * 24 * 7}>1 week</option>
            </select>
          </div>
          
          <div>
            <label>trendLength</label>
            <input type="number" value={trendLength} onChange={(e) => {setTrendLength(e.target.value)}}/>
          </div>
          <div>
            <label>trendStep</label>
            <select value={trendStep} onChange={(e) => {setTrendStep(e.target.value)}}>
              <option value={oneMinute}>1 minute</option>
              <option value={oneMinute * 5}>5 minutes</option>
              <option value={oneMinute * 15}>15 minutes</option>
              <option value={oneMinute * 60}>1 hour</option>
            </select>
          </div>
          
        </div>
        <div className="easy-forms">
          <div className={(eye)?"blur":""}>
            <label>ponies</label>
            <input type="number" value={poniesToRide} onChange={(e) => {setPoniesToRide(e.target.value)}}/>
          </div>
          <div className={(eye)?"blur":""}>
            <label>lag</label>
            <input type="number" value={lag} onChange={(e) => {setLag(e.target.value)}}/>
          </div>
          <div className={(eye)?"blur":""}>
            <label>stopLoss</label>
            <input type="number" value={stopLossPercentage} onChange={(e) => {setStopLossPercentage(e.target.value)}}/>
          </div>
          <div>
            <label>Algo</label>
            <input type="number" value={algo} onChange={(e) => {setAlgo(e.target.value)}}/>
          </div>
          <div className={"quote-asset-vol "+((eye)?"blur":"")}>
            <label>Min Q Volume</label>
            <input type="number" value={minQuoteAssetVolume} onChange={(e) => {setMinQuoteAssetVolume(e.target.value)}}/>
          </div>
          <div>
            <label>Hidden</label>
            <SwitchToggle isOn={eye} onClickFunc={() => {setEye(!eye)}}/>
          </div>
        </div>
        <div className="easy-forms">
          <div>
            <label>Binance</label>
            <SwitchToggle isOn={binanceExchange} onClickFunc={() => {setBinanceExchange(!binanceExchange)}}/>
          </div>
          <div>
            <label>Kucoin</label>
            <SwitchToggle isOn={kucoinExchange} onClickFunc={() => {setKucoinExchange(!kucoinExchange)}}/>
          </div>
          <div>
            <label>Poloniex</label>
            <SwitchToggle isOn={poloniexExchange} onClickFunc={() => {setPoloniexExchange(!poloniexExchange)}}/>
          </div>
          <div>
            <label>Ascendex</label>
            <SwitchToggle isOn={ascendexExchange} onClickFunc={() => {setAscendexExchange(!ascendexExchange)}}/>
          </div>
          <div>
            <label>Table</label>
            <select value={table} onChange={(e) => {setTable(e.target.value)}}>
              <option value="KlineMinuteOne">Minute One</option>
              <option value="KlineMinuteFive">Minute Five</option>
              <option value="KlineMinuteFifteen">Minute Fifteen</option>
              <option value="KlineHourOne">Hour One</option>
            </select>
          </div>
        </div>  
        <div className="easy-forms">
          <div>
            <label>Binance</label>
            <input type="number" value={binanceMoney} onChange={(e) => {setBinanceMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Kucoin</label>
            <input type="number" value={kucoinMoney} onChange={(e) => {setKucoinMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Poloniex</label>
            <input type="number" value={poloniexMoney} onChange={(e) => {setPoloniexMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Ascendex</label>
            <input type="number" value={ascendexMoney} onChange={(e) => {setAscendexMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Okex</label>
            <input type="number" value={okexMoney} onChange={(e) => {setOkexMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Gateio</label>
            <input type="number" value={gateioMoney} onChange={(e) => {setGateioMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Huobi</label>
            <input type="number" value={huobiMoney} onChange={(e) => {setHuobiMoney(e.target.value)}}/>
          </div>
          <div>
            <label>Ftx</label>
            <input type="number" value={ftxMoney} onChange={(e) => {setFtxMoney(e.target.value)}}/>
          </div>
        </div>

        <div className="easy-forms">
          <div>
            <label>Ponies</label>
            <SwitchToggle isOn={showPonies} onClickFunc={() => {setShowPonies(!showPonies)}}/>
          </div>
          <div>
            <label>Desc</label>
            <SwitchToggle isOn={desc} onClickFunc={() => {setDesc(!desc)}}/>
          </div>
          <div>
            <label>Picks</label>
            <SwitchToggle isOn={picks} onClickFunc={() => {setPicks(!picks)}}/>
          </div>
          <div>
            <label>RWinners</label>
            <SwitchToggle isOn={realWinners} onClickFunc={() => {setRealWinners(!realWinners)}}/>
          </div>
          <div>
            <label>Inspect</label>
            <SwitchToggle isOn={inspect} onClickFunc={() => {setInspect(!inspect)}}/>
          </div>
          <div>
            <label>Rapid</label>
            <SwitchToggle isOn={rapid} onClickFunc={() => {setRapid(!rapid)}}/>
          </div>
        </div>
        <div className="buttons">
          <Button type="submit" value="Forge" />
          {/* <Button type="button" onClick={() => {fetchResults(true)}}>Batch Forge</Button> */}
        </div>
      </form>
      <div className="results-div">
        <Loading show={loading} />
        <div className="batch-results">
            <label>Batch Results {batchResultsElapsedTime} secs</label>
            <label><button onClick={() => {console.log("batchForgeArguments",batchForgeArguments)}}>Show Batch Forge Arguments</button></label>
            <Button parentClassName="delete-batch-results" status={(loading)?"loading":"delete"} onClick={()=>{deleteBatchResults(batchId)}}>Delete Batch Results</Button>
            <div className="batch-results-container">
            {
              Object.keys(batchResults).map((v,i) => {
                let batchResult = batchResults[v];
                let d = "Total";
                let epochTime = "0";

                if(v !== "total"){
                  d = new Date(parseInt(v));
                  epochTime = d.getTime();
                  d = prettifyDateWithTime(d);
                }

                return (
                  <div key={i} className="batch-result">
                    <label>{d}</label>
                    <label>{epochTime}</label>
                    <table>
                      <tbody>
                        {(batchResult !== undefined && batchResult !== null)?
                          Object.keys(batchResult.gains).map((v2,i2) => {
                            let gain = batchResult.gains[v2];
                            if(gain === null || gain.percentage === undefined) return null
                            return (
                              <tr key={i2} className="gain">
                                <td className="label">{StringUtil.capitalize(v2)}:</td>
                                <td className="percentage">{gain.percentage}%</td>
                                <td className="value">${NumberUtil.numberWithCommas(Math.round(gain.money*100)/100)}</td>
                              </tr>
                            )
                          }):null
                        }
                      </tbody>
                    </table>
                    <button onClick={() => {console.log("batchResult",batchResult)}}>Console Log Batch Result</button>
                  </div>
                )
              })
            }
            </div>
        </div>
        <div className="gains">
          <label>Gains {resultsElapsedTime} secs</label>
          <label><button onClick={() => {console.log("forgeResult",forgeResult)}}>Console Log Forge Result</button></label>
          <table>
            <tbody>
            {
              Object.keys(gains).map((v,i) => {
                let gain = gains[v];
                return (
                  <tr key={i} className="gain">
                    <td className="label">{StringUtil.capitalize(v)}:</td>
                    <td className="percentage">{NumberUtil.numberWithCommas(gain.percentage)}%</td>
                    <td className="value">${NumberUtil.numberWithCommas(Math.round(gain.money*100)/100)}</td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
        <div className="moves">
          <table>
            <tbody>
              <tr>
                <th>time</th>
                <th>totalUsd</th>
                {(showPonies)?<th>ponies</th>:null}
                {(desc)?<th>desc</th>:null}
                {(picks)?<th>picks</th>:null}
                {(realWinners)?<th>realwinners</th>:null}
                <th>Inspect</th>
              </tr>
              {
                moves.map((v,i) => {
                  let d = new Date(v.time);
                  let epochTime = d.getTime();
                  d = prettifyDateWithTime(d);
                  return(
                    <tr key={i}>
                      <td>
                        <div>{d}</div>
                        <div>{epochTime}</div>
                      </td>
                      <td>{NumberUtil.numberWithCommas(parseInt(v.totalUsd))}</td>
                      {(showPonies)?
                        <td>
                          {
                            v.ponies.map((v2,i2) => {
                              return(
                                <div className="pony" key={i2}>
                                  <div>{v2.exchange} {(v2.symbol === "USDT")?v2.symbol:v2.symbol.replace("USDT", "")}</div>
                                  <div>coins: {parseInt(v2.coins)}</div>
                                  <div>usdVal: {parseInt(v2.usdVal)}</div>
                                </div>
                              )
                            })
                          }
                        </td>
                      :null}
                      

                      {(desc)?
                        <td> { v.desc.map((v2,i2) => { return( <div className="desc" key={i2}>{v2}</div> ) }) } </td>
                      :null}
                      {(picks)?
                        <td> { 
                          Object.keys(v.picks).map((v3,i3) => {
                            let exchangePicks = v.picks[v3];
                            return (
                              <div key={i3}>
                                <label>{v3}</label>
                                {
                                  exchangePicks.map((v2,i2) => { 
                                    return( 
                                      <div className="desc" key={i2}>
                                        {v2.symbol.replace("USDT", "")} - s:{v2.score}, rc:{Math.round(v2.realChange*100)/100}, rp:{v2.place}
                                      </div> 
                                    ) 
                                  }) 
                                }
                              </div>
                            )
                          })}
                        </td>
                      :null}
                      {(realWinners)?
                        <td> { v.realWinners.map((v2,i2) => { return( <div className="desc" key={i2}>{v2.symbol.replace("USDT", "")} - s:{v2.score}, c:{Math.round(v2.change*100)/100}, pp:{v2.place}</div> ) }) } </td>
                      :null}

                      <td><button onClick={() => {console.log("Move",v)}}>Console Log</button></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

};
// 
export default HephaestusSimulator;