import React from 'react';
import Entry from './entry';
import './entry.scss';

const EntryCreateAccount = ({code}) => {

  return (
    <div className="entry-create-account">
      <Entry code={code} option="create-account" />
    </div>
  )
};

export default EntryCreateAccount;