import React from 'react';
import { Helmet } from "react-helmet";
import './privacy-policy.scss';
import Config from '../../../../config/config';
import { Link } from 'react-router-dom';
import {useMaskEmail} from '../../../../utils/mask-email';
import Seo from '../../../../data/seo';

const PrivacyPolicy = () => {

  const appName = Config.Common.AppName;
  const {maskedEmail} = useMaskEmail(Config.Common.SupportEmailName,Config.Common.SupportEmailDomain);
  const domain = "stonkify.com";

  return (
    <div className="privacy-policy">
      <Helmet>
        <title>{Seo.PrivacyPolicy.Title}</title>
        <meta name="description" content={Seo.PrivacyPolicy.Desc} />
        <meta name="keywords" content={Seo.PrivacyPolicy.Keywords} />
      </Helmet>
      <h1 id="privacy-policy">Privacy Policy</h1>

      <h2 id="1-scope">1. Scope</h2>
      <p>{appName} is a service that helps you understand US based public companies value. Dreolo LLC ("Dreolo") operates the <a href={"https://"+domain}>{domain}</a> website (“Site”). </p>
      <p>This privacy policy sets out how Dreolo LLC. uses and protects any information that you give us when you use this Service. By accessing our Service you are consenting to the information collection and use practices described in this Privacy Policy. Throughout this policy, when we write "{appName}" or “we” or “us,” we’re referring to Dreolo LLC. (“Dreolo”). </p>
      <p>All data subjects whose personal data is collected, in line with the requirements of the GDPR and United States Law. </p>
      <p>If you do not agree with this policy, do not access or use our Services or interact with any other aspect of our business.</p>

      <h2 id="2-responsibilities">2. Responsibilities</h2>
      <p>All Employees of Dreolo LLC. who interact with data subjects are responsible for ensuring that this notice is drawn to the data subject’s attention and their consent to the processing of their data is secured.</p>

      <h2 id="3-privacy-notice">3. Privacy notice</h2>
      <h3 id="3-1-collection-of-information">3.1 Collection of information</h3>
      <p>The personal information we collect from you may include:</p>
      <ul>
        <li>Email address;</li>
      </ul>
      <p>You are not required to provide any of this information, but if you do not, we may not be able to provide you with the requested services. You can stop sharing that information with us at any time by removing {appName} access to that account.</p>
      <p>We do not store your payment information. If you are a paying customer you will provide your credit card number directly to Stripe. The privacy policy of Stripe will apply to the information you provide on the Stripe website (<a href="https://stripe.com/privacy">https://stripe.com/privacy</a>).</p>
      <h4 id="automatically-collected-information">Automatically collected information</h4>
      <p>Certain data about the devices you use to connect with {appName} and your use of the Site and Service are automatically logged in our systems, including:</p>
      <ul>
        <li>Location information. This is the geographic area where you use your computer and mobile devices (as indicated by an Internet Protocol [IP] address or similar identifier) when interacting with our Site, Software, and/or Services.</li>
        <li>Log data. As with most websites and technology services delivered over the internet, our servers automatically collect data when you access or use our Site and Service and record it in log files. This log data may include browser type and settings, the date and time of use, information about browser configuration, language preferences, and cookie data.</li>
        <li>Usage information. This is information about the {appName} Site and Service you use and how you use them. We may also obtain data from our third-party partners and service providers to analyze how users use our Site and Service. For example, we will know how many users access a specific page on the Site and which links they clicked on. We use this aggregated information to better understand and optimize the Site.</li>
        <li>Cookies. A cookie is a data file placed on a Computer when it is used to visit the Websites.  Cookies may be used for many purposes, including, without limitation, tracking user preferences and web pages visited while using the Websites.  You may be able to remove, reject and/or disable some types of cookies using your browser’s preferences or other programs.  Some features of the Websites may not function properly or may operate slowly if you disable, delete, or refuse to accept cookies.  Some of the cookies we use may be "Flash" cookies.  A Flash cookie is a data file placed on a Computer via the Adobe Flash plug-in that may be built-in to or downloaded by you to your Computer.  While they are harmless, depending on your browser, these cookies may not be deleted when your cookies are deleted or disabled and, in some instances, they may cause your cookies to reappear in your browser.  Please check your browser to determine where these types of cookies are stored and how they may be deleted or disabled.</li>
      </ul>

      <h4 id="the-personal-data-we-collect-will-be-used-for-the-following-purposes-">The personal data we collect will be used for the following purposes:</h4>
      <ul>
        <li>to help us administer our Site and Service, authenticate users for security purposes, provide personalized user features and access, conduct research, develop new features, and improve the features, algorithms, and usability of our Site Service.</li>
        <li>to communicate with you about your use of our Site and Service, product announcements, and Service updates, as well as respond to your requests for assistance, including providing account verification support if you’re having difficulty accessing your account.</li>
        <li>to display User Content associated with your account and make sure it is available to you when you use our Services.</li>
        <li>to calculate aggregate statistics on the number of unique devices using our Site, Software, and/or Services, and to detect and prevent fraud and misuse of those.</li>
      </ul>

      <h4 id="our-legal-basis-for-processing-for-the-personal-data-">Our legal basis for processing for the personal data:</h4>
      <p>If you have consented to our use of information about you for a specific purpose, you have the right to change your mind at any time, but this will not affect any processing that has already taken place. Where we are using your information because we or a third party (e.g. your employer) have a legitimate interest to do so, you have the right to object to that use though, in some cases, this may mean no longer using the Services.</p>

      <h3 id="3-2-consent">3.2 Consent</h3>
      <p>By consenting to this privacy notice you are giving us permission to process your personal data specifically for the purposes identified.</p>

      <h3 id="3-3-disclosure">3.3 Disclosure</h3>
      <p>We only disclose Personal Data to third parties when:</p>
      <ul>
        <li>We use service providers who assist us in meeting business operations needs, including hosting, delivering, and improving our Services. We also use service providers for specific services and functions, including email communication, customer support services, and analytics. These service providers may only access, process, or store Personal Data pursuant to our instructions and to perform their duties to us.</li>
        <li>We determine that the access, preservation, or disclosure of your Personal Data is required by law to protect the rights, property, or personal safety of {appName} and users of our Site and Service, or to respond to lawful requests by public authorities, including national security or law enforcement requests.</li>
        <li>We need to do so in connection with a merger, acquisition, bankruptcy, reorganization, sale of some or all of our assets or stock, public offering of securities, or steps in consideration of such activities (e.g., due diligence). In these cases some or all of your Personal Data may be shared with or transferred to another entity, subject to this Privacy Policy.</li>
      </ul>

      <h3 id="3-4-international-transfer">3.4 International Transfer</h3>
      <p>Information submitted to {appName} is operated by Amazon Web Services. If you post or transfer any Information to or through our Site and Services, you are agreeing to such Information, including Personal Data and User Content, being hosted and accessed in the United States.</p>
      
      <h3 id="3-5-security">3.5 Security</h3>
      <p>{appName} shall process all personal data in the strictest confidentiality and implement the appropriate technical and organizational measures as required by applicable regulations. However, Internet data transmissions, whether wired or wireless, cannot be guaranteed to be 100% secure, and as a result, we cannot ensure the security of Information you transmit to us, including Personal; accordingly, you acknowledge that you do so at your own risk. If {appName} learns of a security system breach, we may attempt to notify you and provide information on protective steps, if available, through the email address that you have provided to us or by posting a notice on the Site. Depending on where you live, you may have a legal right to receive such notices in writing.</p>

      <h3 id="3-6-access-requests">3.6 Access Requests</h3>
      <p>In accordance with GDPR, as our User, you can exercise your data subject rights to correct, modify or delete the Personal Data about you that {appName} holds. If you would like to access, rectify or delete your Personal Data, please send a request to <a href={"mailto:"+maskedEmail}>{maskedEmail}</a> with the following information:</p>
      <ul>
        <li>Your email address; (must be sent from the same email address)</li>
        <li>What you would like to request (a copy of your Personal Data, to amend your Personal Data or to permanently delete your Personal Data).</li>
      </ul>
      <p>We will provide you with this information within one month of receipt of the request.</p>
      <p>In case of submissions to delete Personal Data, we will retain your information for as long as your account is active or as needed to provide you services.</p>

      <h3 id="3-7-children">3.7 Children</h3>
      <p>We do not knowingly collect information from minors. To use the Site and Service, you must be the age of legal majority in your place of residence. By using the Site and Service, you hereby represent that you are at least the age of legal majority in your place of residence. We do not use an application or other mechanisms to determine the age of users of the Site and Service. All information provided to {appName} will be treated as if it was provided by an adult. If, however, we learn that a minor has submitted information about himself/herself to us, we delete the information as soon as possible.</p>

      <h3 id="3-8-changes-to-this-privacy-policy">3.8 Changes to this Privacy Policy</h3>
      <p>We may occasionally update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and revise the "Last update" date at the top of this web page.</p>
      <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. Your continued use of the Site or Service after the posting of the modified Privacy Policy constitutes your agreement to abide and be bound by it. If you object to any modification, your sole recourse is to stop using the Site and Services.</p>
      <p>Everything that is not regulated by this Policy is regulated by the statements of the GDPR.</p>

      <h2 id="contact-us">Contact us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at <a href={"mailto:"+maskedEmail}>{maskedEmail}</a>.</p>

      <Link to="/terms-of-use">Terms of Use</Link>

    </div>
  );
};

export default PrivacyPolicy;