const countries = [
	{
		"label": "Afghanistan",
		"value": "AF",
		"code": "AF"
	}, {
		"label": "Åland Islands",
		"value": "AX",
		"code": "AX"
	}, {
		"label": "Albania",
		"value": "AL",
		"code": "AL"
	}, {
		"label": "Algeria",
		"value": "DZ",
		"code": "DZ"
	}, {
		"label": "American Samoa",
		"value": "AS",
		"code": "AS"
	}, {
		"label": "AndorrA",
		"value": "AD",
		"code": "AD"
	}, {
		"label": "Angola",
		"value": "AO",
		"code": "AO"
	}, {
		"label": "Anguilla",
		"value": "AI",
		"code": "AI"
	}, {
		"label": "Antarctica",
		"value": "AQ",
		"code": "AQ"
	}, {
		"label": "Antigua and Barbuda",
		"value": "AG",
		"code": "AG"
	}, {
		"label": "Argentina",
		"value": "AR",
		"code": "AR"
	}, {
		"label": "Armenia",
		"value": "AM",
		"code": "AM"
	}, {
		"label": "Aruba",
		"value": "AW",
		"code": "AW"
	}, {
		"label": "Australia",
		"value": "AU",
		"code": "AU"
	}, {
		"label": "Austria",
		"value": "AT",
		"code": "AT"
	}, {
		"label": "Azerbaijan",
		"value": "AZ",
		"code": "AZ"
	}, {
		"label": "Bahamas",
		"value": "BS",
		"code": "BS"
	}, {
		"label": "Bahrain",
		"value": "BH",
		"code": "BH"
	}, {
		"label": "Bangladesh",
		"value": "BD",
		"code": "BD"
	}, {
		"label": "Barbados",
		"value": "BB",
		"code": "BB"
	}, {
		"label": "Belarus",
		"value": "BY",
		"code": "BY"
	}, {
		"label": "Belgium",
		"value": "BE",
		"code": "BE"
	}, {
		"label": "Belize",
		"value": "BZ",
		"code": "BZ"
	}, {
		"label": "Benin",
		"value": "BJ",
		"code": "BJ"
	}, {
		"label": "Bermuda",
		"value": "BM",
		"code": "BM"
	}, {
		"label": "Bhutan",
		"value": "BT",
		"code": "BT"
	}, {
		"label": "Bolivia",
		"value": "BO",
		"code": "BO"
	}, {
		"label": "Bosnia and Herzegovina",
		"value": "BA",
		"code": "BA"
	}, {
		"label": "Botswana",
		"value": "BW",
		"code": "BW"
	}, {
		"label": "Bouvet Island",
		"value": "BV",
		"code": "BV"
	}, {
		"label": "Brazil",
		"value": "BR",
		"code": "BR"
	}, {
		"label": "British Indian Ocean Territory",
		"value": "IO",
		"code": "IO"
	}, {
		"label": "Brunei Darussalam",
		"value": "BN",
		"code": "BN"
	}, {
		"label": "Bulgaria",
		"value": "BG",
		"code": "BG"
	}, {
		"label": "Burkina Faso",
		"value": "BF",
		"code": "BF"
	}, {
		"label": "Burundi",
		"value": "BI",
		"code": "BI"
	}, {
		"label": "Cambodia",
		"value": "KH",
		"code": "KH"
	}, {
		"label": "Cameroon",
		"value": "CM",
		"code": "CM"
	}, {
		"label": "Canada",
		"value": "CA",
		"code": "CA"
	}, {
		"label": "Cape Verde",
		"value": "CV",
		"code": "CV"
	}, {
		"label": "Cayman Islands",
		"value": "KY",
		"code": "KY"
	}, {
		"label": "Central African Republic",
		"value": "CF",
		"code": "CF"
	}, {
		"label": "Chad",
		"value": "TD",
		"code": "TD"
	}, {
		"label": "Chile",
		"value": "CL",
		"code": "CL"
	}, {
		"label": "China",
		"value": "CN",
		"code": "CN"
	}, {
		"label": "Christmas Island",
		"value": "CX",
		"code": "CX"
	}, {
		"label": "Cocos (Keeling) Islands",
		"value": "CC",
		"code": "CC"
	}, {
		"label": "Colombia",
		"value": "CO",
		"code": "CO"
	}, {
		"label": "Comoros",
		"value": "KM",
		"code": "KM"
	}, {
		"label": "Congo",
		"value": "CG",
		"code": "CG"
	}, {
		"label": "Congo, The Democratic Republic of the",
		"value": "CD",
		"code": "CD"
	}, {
		"label": "Cook Islands",
		"value": "CK",
		"code": "CK"
	}, {
		"label": "Costa Rica",
		"value": "CR",
		"code": "CR"
	}, {
		"label": "Cote D'Ivoire",
		"value": "CI",
		"code": "CI"
	}, {
		"label": "Croatia",
		"value": "HR",
		"code": "HR"
	}, {
		"label": "Cuba",
		"value": "CU",
		"code": "CU"
	}, {
		"label": "Cyprus",
		"value": "CY",
		"code": "CY"
	}, {
		"label": "Czech Republic",
		"value": "CZ",
		"code": "CZ"
	}, {
		"label": "Denmark",
		"value": "DK",
		"code": "DK"
	}, {
		"label": "Djibouti",
		"value": "DJ",
		"code": "DJ"
	}, {
		"label": "Dominica",
		"value": "DM",
		"code": "DM"
	}, {
		"label": "Dominican Republic",
		"value": "DO",
		"code": "DO"
	}, {
		"label": "Ecuador",
		"value": "EC",
		"code": "EC"
	}, {
		"label": "Egypt",
		"value": "EG",
		"code": "EG"
	}, {
		"label": "El Salvador",
		"value": "SV",
		"code": "SV"
	}, {
		"label": "Equatorial Guinea",
		"value": "GQ",
		"code": "GQ"
	}, {
		"label": "Eritrea",
		"value": "ER",
		"code": "ER"
	}, {
		"label": "Estonia",
		"value": "EE",
		"code": "EE"
	}, {
		"label": "Ethiopia",
		"value": "ET",
		"code": "ET"
	}, {
		"label": "Falkland Islands (Malvinas)",
		"value": "FK",
		"code": "FK"
	}, {
		"label": "Faroe Islands",
		"value": "FO",
		"code": "FO"
	}, {
		"label": "Fiji",
		"value": "FJ",
		"code": "FJ"
	}, {
		"label": "Finland",
		"value": "FI",
		"code": "FI"
	}, {
		"label": "France",
		"value": "FR",
		"code": "FR"
	}, {
		"label": "French Guiana",
		"value": "GF",
		"code": "GF"
	}, {
		"label": "French Polynesia",
		"value": "PF",
		"code": "PF"
	}, {
		"label": "French Southern Territories",
		"value": "TF",
		"code": "TF"
	}, {
		"label": "Gabon",
		"value": "GA",
		"code": "GA"
	}, {
		"label": "Gambia",
		"value": "GM",
		"code": "GM"
	}, {
		"label": "Georgia",
		"value": "GE",
		"code": "GE"
	}, {
		"label": "Germany",
		"value": "DE",
		"code": "DE"
	}, {
		"label": "Ghana",
		"value": "GH",
		"code": "GH"
	}, {
		"label": "Gibraltar",
		"value": "GI",
		"code": "GI"
	}, {
		"label": "Greece",
		"value": "GR",
		"code": "GR"
	}, {
		"label": "Greenland",
		"value": "GL",
		"code": "GL"
	}, {
		"label": "Grenada",
		"value": "GD",
		"code": "GD"
	}, {
		"label": "Guadeloupe",
		"value": "GP",
		"code": "GP"
	}, {
		"label": "Guam",
		"value": "GU",
		"code": "GU"
	}, {
		"label": "Guatemala",
		"value": "GT",
		"code": "GT"
	}, {
		"label": "Guernsey",
		"value": "GG",
		"code": "GG"
	}, {
		"label": "Guinea",
		"value": "GN",
		"code": "GN"
	}, {
		"label": "Guinea-Bissau",
		"value": "GW",
		"code": "GW"
	}, {
		"label": "Guyana",
		"value": "GY",
		"code": "GY"
	}, {
		"label": "Haiti",
		"value": "HT",
		"code": "HT"
	}, {
		"label": "Heard Island and Mcdonald Islands",
		"value": "HM",
		"code": "HM"
	}, {
		"label": "Holy See (Vatican City State)",
		"value": "VA",
		"code": "VA"
	}, {
		"label": "Honduras",
		"value": "HN",
		"code": "HN"
	}, {
		"label": "Hong Kong",
		"value": "HK",
		"code": "HK"
	}, {
		"label": "Hungary",
		"value": "HU",
		"code": "HU"
	}, {
		"label": "Iceland",
		"value": "IS",
		"code": "IS"
	}, {
		"label": "India",
		"value": "IN",
		"code": "IN"
	}, {
		"label": "Indonesia",
		"value": "ID",
		"code": "ID"
	}, {
		"label": "Iran, Islamic Republic Of",
		"value": "IR",
		"code": "IR"
	}, {
		"label": "Iraq",
		"value": "IQ",
		"code": "IQ"
	}, {
		"label": "Ireland",
		"value": "IE",
		"code": "IE"
	}, {
		"label": "Isle of Man",
		"value": "IM",
		"code": "IM"
	}, {
		"label": "Israel",
		"value": "IL",
		"code": "IL"
	}, {
		"label": "Italy",
		"value": "IT",
		"code": "IT"
	}, {
		"label": "Jamaica",
		"value": "JM",
		"code": "JM"
	}, {
		"label": "Japan",
		"value": "JP",
		"code": "JP"
	}, {
		"label": "Jersey",
		"value": "JE",
		"code": "JE"
	}, {
		"label": "Jordan",
		"value": "JO",
		"code": "JO"
	}, {
		"label": "Kazakhstan",
		"value": "KZ",
		"code": "KZ"
	}, {
		"label": "Kenya",
		"value": "KE",
		"code": "KE"
	}, {
		"label": "Kiribati",
		"value": "KI",
		"code": "KI"
	}, {
		"label": "Korea, Democratic People's Republic of",
		"value": "KP",
		"code": "KP"
	}, {
		"label": "Korea, Republic of",
		"value": "KR",
		"code": "KR"
	}, {
		"label": "Kuwait",
		"value": "KW",
		"code": "KW"
	}, {
		"label": "Kyrgyzstan",
		"value": "KG",
		"code": "KG"
	}, {
		"label": "Lao People's Democratic Republic",
		"value": "LA",
		"code": "LA"
	}, {
		"label": "Latvia",
		"value": "LV",
		"code": "LV"
	}, {
		"label": "Lebanon",
		"value": "LB",
		"code": "LB"
	}, {
		"label": "Lesotho",
		"value": "LS",
		"code": "LS"
	}, {
		"label": "Liberia",
		"value": "LR",
		"code": "LR"
	}, {
		"label": "Libyan Arab Jamahiriya",
		"value": "LY",
		"code": "LY"
	}, {
		"label": "Liechtenstein",
		"value": "LI",
		"code": "LI"
	}, {
		"label": "Lithuania",
		"value": "LT",
		"code": "LT"
	}, {
		"label": "Luxembourg",
		"value": "LU",
		"code": "LU"
	}, {
		"label": "Macao",
		"value": "MO",
		"code": "MO"
	}, {
		"label": "Macedonia, The Former Yugoslav Republic of",
		"value": "MK",
		"code": "MK"
	}, {
		"label": "Madagascar",
		"value": "MG",
		"code": "MG"
	}, {
		"label": "Malawi",
		"value": "MW",
		"code": "MW"
	}, {
		"label": "Malaysia",
		"value": "MY",
		"code": "MY"
	}, {
		"label": "Maldives",
		"value": "MV",
		"code": "MV"
	}, {
		"label": "Mali",
		"value": "ML",
		"code": "ML"
	}, {
		"label": "Malta",
		"value": "MT",
		"code": "MT"
	}, {
		"label": "Marshall Islands",
		"value": "MH",
		"code": "MH"
	}, {
		"label": "Martinique",
		"value": "MQ",
		"code": "MQ"
	}, {
		"label": "Mauritania",
		"value": "MR",
		"code": "MR"
	}, {
		"label": "Mauritius",
		"value": "MU",
		"code": "MU"
	}, {
		"label": "Mayotte",
		"value": "YT",
		"code": "YT"
	}, {
		"label": "Mexico",
		"value": "MX",
		"code": "MX"
	}, {
		"label": "Micronesia, Federated States of",
		"value": "FM",
		"code": "FM"
	}, {
		"label": "Moldova, Republic of",
		"value": "MD",
		"code": "MD"
	}, {
		"label": "Monaco",
		"value": "MC",
		"code": "MC"
	}, {
		"label": "Mongolia",
		"value": "MN",
		"code": "MN"
	}, {
		"label": "Montserrat",
		"value": "MS",
		"code": "MS"
	}, {
		"label": "Morocco",
		"value": "MA",
		"code": "MA"
	}, {
		"label": "Mozambique",
		"value": "MZ",
		"code": "MZ"
	}, {
		"label": "Myanmar",
		"value": "MM",
		"code": "MM"
	}, {
		"label": "Namibia",
		"value": "NA",
		"code": "NA"
	}, {
		"label": "Nauru",
		"value": "NR",
		"code": "NR"
	}, {
		"label": "Nepal",
		"value": "NP",
		"code": "NP"
	}, {
		"label": "Netherlands",
		"value": "NL",
		"code": "NL"
	}, {
		"label": "Netherlands Antilles",
		"value": "AN",
		"code": "AN"
	}, {
		"label": "New Caledonia",
		"value": "NC",
		"code": "NC"
	}, {
		"label": "New Zealand",
		"value": "NZ",
		"code": "NZ"
	}, {
		"label": "Nicaragua",
		"value": "NI",
		"code": "NI"
	}, {
		"label": "Niger",
		"value": "NE",
		"code": "NE"
	}, {
		"label": "Nigeria",
		"value": "NG",
		"code": "NG"
	}, {
		"label": "Niue",
		"value": "NU",
		"code": "NU"
	}, {
		"label": "Norfolk Island",
		"value": "NF",
		"code": "NF"
	}, {
		"label": "Northern Mariana Islands",
		"value": "MP",
		"code": "MP"
	}, {
		"label": "Norway",
		"value": "NO",
		"code": "NO"
	}, {
		"label": "Oman",
		"value": "OM",
		"code": "OM"
	}, {
		"label": "Pakistan",
		"value": "PK",
		"code": "PK"
	}, {
		"label": "Palau",
		"value": "PW",
		"code": "PW"
	}, {
		"label": "Palestinian Territory, Occupied",
		"value": "PS",
		"code": "PS"
	}, {
		"label": "Panama",
		"value": "PA",
		"code": "PA"
	}, {
		"label": "Papua New Guinea",
		"value": "PG",
		"code": "PG"
	}, {
		"label": "Paraguay",
		"value": "PY",
		"code": "PY"
	}, {
		"label": "Peru",
		"value": "PE",
		"code": "PE"
	}, {
		"label": "Philippines",
		"value": "PH",
		"code": "PH"
	}, {
		"label": "Pitcairn",
		"value": "PN",
		"code": "PN"
	}, {
		"label": "Poland",
		"value": "PL",
		"code": "PL"
	}, {
		"label": "Portugal",
		"value": "PT",
		"code": "PT"
	}, {
		"label": "Puerto Rico",
		"value": "PR",
		"code": "PR"
	}, {
		"label": "Qatar",
		"value": "QA",
		"code": "QA"
	}, {
		"label": "Reunion",
		"value": "RE",
		"code": "RE"
	}, {
		"label": "Romania",
		"value": "RO",
		"code": "RO"
	}, {
		"label": "Russian Federation",
		"value": "RU",
		"code": "RU"
	}, {
		"label": "RWANDA",
		"value": "RW",
		"code": "RW"
	}, {
		"label": "Saint Helena",
		"value": "SH",
		"code": "SH"
	}, {
		"label": "Saint Kitts and Nevis",
		"value": "KN",
		"code": "KN"
	}, {
		"label": "Saint Lucia",
		"value": "LC",
		"code": "LC"
	}, {
		"label": "Saint Pierre and Miquelon",
		"value": "PM",
		"code": "PM"
	}, {
		"label": "Saint Vincent and the Grenadines",
		"value": "VC",
		"code": "VC"
	}, {
		"label": "Samoa",
		"value": "WS",
		"code": "WS"
	}, {
		"label": "San Marino",
		"value": "SM",
		"code": "SM"
	}, {
		"label": "Sao Tome and Principe",
		"value": "ST",
		"code": "ST"
	}, {
		"label": "Saudi Arabia",
		"value": "SA",
		"code": "SA"
	}, {
		"label": "Senegal",
		"value": "SN",
		"code": "SN"
	}, {
		"label": "Serbia and Montenegro",
		"value": "CS",
		"code": "CS"
	}, {
		"label": "Seychelles",
		"value": "SC",
		"code": "SC"
	}, {
		"label": "Sierra Leone",
		"value": "SL",
		"code": "SL"
	}, {
		"label": "Singapore",
		"value": "SG",
		"code": "SG"
	}, {
		"label": "Slovakia",
		"value": "SK",
		"code": "SK"
	}, {
		"label": "Slovenia",
		"value": "SI",
		"code": "SI"
	}, {
		"label": "Solomon Islands",
		"value": "SB",
		"code": "SB"
	}, {
		"label": "Somalia",
		"value": "SO",
		"code": "SO"
	}, {
		"label": "South Africa",
		"value": "ZA",
		"code": "ZA"
	}, {
		"label": "South Georgia and the South Sandwich Islands",
		"value": "GS",
		"code": "GS"
	}, {
		"label": "Spain",
		"value": "ES",
		"code": "ES"
	}, {
		"label": "Sri Lanka",
		"value": "LK",
		"code": "LK"
	}, {
		"label": "Sudan",
		"value": "SD",
		"code": "SD"
	}, {
		"label": "Suriname",
		"value": "SR",
		"code": "SR"
	}, {
		"label": "Svalbard and Jan Mayen",
		"value": "SJ",
		"code": "SJ"
	}, {
		"label": "Swaziland",
		"value": "SZ",
		"code": "SZ"
	}, {
		"label": "Sweden",
		"value": "SE",
		"code": "SE"
	}, {
		"label": "Switzerland",
		"value": "CH",
		"code": "CH"
	}, {
		"label": "Syrian Arab Republic",
		"value": "SY",
		"code": "SY"
	}, {
		"label": "Taiwan, Province of China",
		"value": "TW",
		"code": "TW"
	}, {
		"label": "Tajikistan",
		"value": "TJ",
		"code": "TJ"
	}, {
		"label": "Tanzania, United Republic of",
		"value": "TZ",
		"code": "TZ"
	}, {
		"label": "Thailand",
		"value": "TH",
		"code": "TH"
	}, {
		"label": "Timor-Leste",
		"value": "TL",
		"code": "TL"
	}, {
		"label": "Togo",
		"value": "TG",
		"code": "TG"
	}, {
		"label": "Tokelau",
		"value": "TK",
		"code": "TK"
	}, {
		"label": "Tonga",
		"value": "TO",
		"code": "TO"
	}, {
		"label": "Trinidad and Tobago",
		"value": "TT",
		"code": "TT"
	}, {
		"label": "Tunisia",
		"value": "TN",
		"code": "TN"
	}, {
		"label": "Turkey",
		"value": "TR",
		"code": "TR"
	}, {
		"label": "Turkmenistan",
		"value": "TM",
		"code": "TM"
	}, {
		"label": "Turks and Caicos Islands",
		"value": "TC",
		"code": "TC"
	}, {
		"label": "Tuvalu",
		"value": "TV",
		"code": "TV"
	}, {
		"label": "Uganda",
		"value": "UG",
		"code": "UG"
	}, {
		"label": "Ukraine",
		"value": "UA",
		"code": "UA"
	}, {
		"label": "United Arab Emirates",
		"value": "AE",
		"code": "AE"
	}, {
		"label": "United Kingdom",
		"value": "GB",
		"code": "GB"
	}, {
		"label": "United States",
		"value": "US",
		"code": "US"
	}, {
		"label": "United States Minor Outlying Islands",
		"value": "UM",
		"code": "UM"
	}, {
		"label": "Uruguay",
		"value": "UY",
		"code": "UY"
	}, {
		"label": "Uzbekistan",
		"value": "UZ",
		"code": "UZ"
	}, {
		"label": "Vanuatu",
		"value": "VU",
		"code": "VU"
	}, {
		"label": "Venezuela",
		"value": "VE",
		"code": "VE"
	}, {
		"label": "Viet Nam",
		"value": "VN",
		"code": "VN"
	}, {
		"label": "Virgin Islands, British",
		"value": "VG",
		"code": "VG"
	}, {
		"label": "Virgin Islands, U.S.",
		"value": "VI",
		"code": "VI"
	}, {
		"label": "Wallis and Futuna",
		"value": "WF",
		"code": "WF"
	}, {
		"label": "Western Sahara",
		"value": "EH",
		"code": "EH"
	}, {
		"label": "Yemen",
		"value": "YE",
		"code": "YE"
	}, {
		"label": "Zambia",
		"value": "ZM",
		"code": "ZM"
	}, {
		"label": "Zimbabwe",
		"value": "ZW",
		"code": "ZW"
	}
];



export let countriesWithPopulation = [
	// {
	// 	"country":"China",
	// 	"population":1392730000,
	// 	"code":"CN"
	// },
	// {
	// 	"country":"India",
	// 	"population":1352617328,
	// 	"code":"IN"
	// },
	{
		"country":"United States",
		"population":326687501,
		"code":"US"
	},
	{
		"country":"Indonesia",
		"population":267663435,
		"code":"ID"
	},
	{
		"country":"Pakistan",
		"population":212215030,
		"code":"PK"
	},
	{
		"country":"Brazil",
		"population":209469333,
		"code":"BR"
	},
	{
		"country":"Nigeria",
		"population":195874740,
		"code":"NG"
	},
	{
		"country":"Bangladesh",
		"population":161356039,
		"code":"BD"
	},
	{
		"country":"Russian Federation",
		"population":144478050,
		"code":"RU"
	},
	{
		"country":"Japan",
		"population":126529100,
		"code":"JP"
	},
	{
		"country":"Mexico",
		"population":126190788,
		"code":"MX"
	},
	{
		"country":"Ethiopia",
		"population":109224559,
		"code":"ET"
	},
	{
		"country":"Philippines",
		"population":106651922,
		"code":"PH"
	},
	{
		"country":"Egypt",
		"population":98423595,
		"code":"EG"
	},
	{
		"country":"Vietnam",
		"population":95540395,
		"code":"VN"
	},
	{
		"country":"Congo",
		"population":84068091,
		"code":"CD"
	},
	{
		"country":"Germany",
		"population":82905782,
		"code":"DE"
	},
	{
		"country":"Turkey",
		"population":82319724,
		"code":"TR"
	},
	{
		"country":"Iran",
		"population":81800269,
		"code":"IR"
	},
	{
		"country":"Thailand",
		"population":69428524,
		"code":"TH"
	},
	{
		"country":"France",
		"population":66977107,
		"code":"FR"
	},
	{
		"country":"United Kingdom",
		"population":66460344,
		"code":"GB"
	},
	{
		"country":"Italy",
		"population":60421760,
		"code":"IT"
	},
	{
		"country":"South Africa",
		"population":57779622,
		"code":"ZA"
	},
	{
		"country":"Tanzania",
		"population":56318348,
		"code":"TZ"
	},
	{
		"country":"England",
		"population":55619400,
		"code":"NULL"
	},
	{
		"country":"Myanmar",
		"population":53708395,
		"code":"MM"
	},
	{
		"country":"South Korea",
		"population":51606633,
		"code":"KR"
	},
	{
		"country":"Kenya",
		"population":51393010,
		"code":"KE"
	},
	{
		"country":"Colombia",
		"population":49648685,
		"code":"CO"
	},
	{
		"country":"Spain",
		"population":46796540,
		"code":"ES"
	},
	{
		"country":"Ukraine",
		"population":44622516,
		"code":"UA"
	},
	{
		"country":"Argentina",
		"population":44494502,
		"code":"AR"
	},
	{
		"country":"Uganda",
		"population":42723139,
		"code":"UG"
	},
	{
		"country":"Algeria",
		"population":42228429,
		"code":"DZ"
	},
	{
		"country":"Sudan",
		"population":41801533,
		"code":"SD"
	},
	{
		"country":"Iraq",
		"population":38433600,
		"code":"IQ"
	},
	{
		"country":"Poland",
		"population":37974750,
		"code":"PL"
	},
	{
		"country":"Afghanistan",
		"population":37172386,
		"code":"AF"
	},
	{
		"country":"Canada",
		"population":37057765,
		"code":"CA"
	},
	{
		"country":"Morocco",
		"population":36029138,
		"code":"MA"
	},
	{
		"country":"Saudi Arabia",
		"population":33699947,
		"code":"SA"
	},
	{
		"country":"Uzbekistan",
		"population":32955400,
		"code":"UZ"
	},
	{
		"country":"Peru",
		"population":31989256,
		"code":"PE"
	},
	{
		"country":"Malaysia",
		"population":31528585,
		"code":"MY"
	},
	{
		"country":"Angola",
		"population":30809762,
		"code":"AO"
	},
	{
		"country":"Ghana",
		"population":29767108,
		"code":"GH"
	},
	{
		"country":"Mozambique",
		"population":29495962,
		"code":"MZ"
	},
	{
		"country":"Venezuela",
		"population":28870195,
		"code":"VE"
	},
	{
		"country":"Yemen",
		"population":28498687,
		"code":"YE"
	},
	{
		"country":"Nepal",
		"population":28087871,
		"code":"NP"
	},
	{
		"country":"Madagascar",
		"population":26262368,
		"code":"MG"
	},
	{
		"country":"North Korea",
		"population":25549819,
		"code":"KP"
	},
	{
		"country":"Cameroon",
		"population":25216237,
		"code":"CM"
	},
	{
		"country":"Ivory Coast",
		"population":25069229,
		"code":"CI"
	},
	{
		"country":"Australia",
		"population":24982688,
		"code":"AU"
	},
	{
		"country":"Niger",
		"population":22442948,
		"code":"NE"
	},
	{
		"country":"SriLanka",
		"population":21670000,
		"code":"NULL"
	},
	{
		"country":"Burkina Faso",
		"population":19751535,
		"code":"BF"
	},
	{
		"country":"Romania",
		"population":19466145,
		"code":"RO"
	},
	{
		"country":"Mali",
		"population":19077690,
		"code":"ML"
	},
	{
		"country":"Chile",
		"population":18729160,
		"code":"CL"
	},
	{
		"country":"Kazakhstan",
		"population":18272430,
		"code":"KZ"
	},
	{
		"country":"Malawi",
		"population":18143315,
		"code":"MW"
	},
	{
		"country":"Zambia",
		"population":17351822,
		"code":"ZM"
	},
	{
		"country":"Guatemala",
		"population":17247807,
		"code":"GT"
	},
	{
		"country":"Netherlands",
		"population":17231624,
		"code":"NL"
	},
	{
		"country":"Ecuador",
		"population":17084357,
		"code":"EC"
	},
	{
		"country":"Syria",
		"population":16906283,
		"code":"SY"
	},
	{
		"country":"Cambodia",
		"population":16249798,
		"code":"KH"
	},
	{
		"country":"Senegal",
		"population":15854360,
		"code":"SN"
	},
	{
		"country":"Chad",
		"population":15477751,
		"code":"TD"
	},
	{
		"country":"Somalia",
		"population":15008154,
		"code":"SO"
	},
	{
		"country":"Zimbabwe",
		"population":14439018,
		"code":"ZW"
	},
	{
		"country":"Guinea",
		"population":12414318,
		"code":"GN"
	},
	{
		"country":"Rwanda",
		"population":12301939,
		"code":"RW"
	},
	{
		"country":"Tunisia",
		"population":11565204,
		"code":"TN"
	},
	{
		"country":"Benin",
		"population":11485048,
		"code":"BJ"
	},
	{
		"country":"Belgium",
		"population":11433256,
		"code":"BE"
	},
	{
		"country":"Bolivia",
		"population":11353142,
		"code":"BO"
	},
	{
		"country":"Cuba",
		"population":11338138,
		"code":"CU"
	},
	{
		"country":"Burundi",
		"population":11175378,
		"code":"BI"
	},
	{
		"country":"Haiti",
		"population":11123176,
		"code":"HT"
	},
	{
		"country":"South Sudan",
		"population":10975920,
		"code":"SS"
	},
	{
		"country":"Greece",
		"population":10731726,
		"code":"GR"
	},
	{
		"country":"Czech Republic",
		"population":10629928,
		"code":"CZ"
	},
	{
		"country":"Dominican Republic",
		"population":10627165,
		"code":"DO"
	},
	{
		"country":"Portugal",
		"population":10283822,
		"code":"PT"
	},
	{
		"country":"Sweden",
		"population":10175214,
		"code":"SE"
	},
	{
		"country":"Jordan",
		"population":9956011,
		"code":"JO"
	},
	{
		"country":"Azerbaijan",
		"population":9939800,
		"code":"AZ"
	},
	{
		"country":"Hungary",
		"population":9775564,
		"code":"HU"
	},
	{
		"country":"United Arab Emirates",
		"population":9630959,
		"code":"AE"
	},
	{
		"country":"Honduras",
		"population":9587522,
		"code":"HN"
	},
	{
		"country":"Belarus",
		"population":9483499,
		"code":"BY"
	},
	{
		"country":"Tajikistan",
		"population":9100837,
		"code":"TJ"
	},
	{
		"country":"Israel",
		"population":8882800,
		"code":"IL"
	},
	{
		"country":"Austria",
		"population":8840521,
		"code":"AT"
	},
	{
		"country":"Papua New Guinea",
		"population":8606316,
		"code":"PG"
	},
	{
		"country":"Switzerland",
		"population":8513227,
		"code":"CH"
	},
	{
		"country":"Togo",
		"population":7889094,
		"code":"TG"
	},
	{
		"country":"Sierra Leone",
		"population":7650154,
		"code":"SL"
	},
	{
		"country":"Hong Kong",
		"population":7451000,
		"code":"HK"
	},
	{
		"country":"Laos",
		"population":7061507,
		"code":"LA"
	},
	{
		"country":"Bulgaria",
		"population":7025037,
		"code":"BG"
	},
	{
		"country":"Serbia",
		"population":6963764,
		"code":"RS"
	},
	{
		"country":"Paraguay",
		"population":6956071,
		"code":"PY"
	},
	{
		"country":"Lebanon",
		"population":6848925,
		"code":"LB"
	},
	{
		"country":"Libyan Arab Jamahiriya",
		"population":6678567,
		"code":"LY"
	},
	{
		"country":"Nicaragua",
		"population":6465513,
		"code":"NI"
	},
	{
		"country":"El Salvador",
		"population":6420744,
		"code":"SV"
	},
	{
		"country":"Kyrgyzstan",
		"population":6322800,
		"code":"KG"
	},
	{
		"country":"Turkmenistan",
		"population":5850908,
		"code":"TM"
	},
	{
		"country":"Denmark",
		"population":5793636,
		"code":"DK"
	},
	{
		"country":"Singapore",
		"population":5638676,
		"code":"SG"
	},
	{
		"country":"Finland",
		"population":5515525,
		"code":"FI"
	},
	{
		"country":"Slovakia",
		"population":5446771,
		"code":"SK"
	},
	{
		"country":"Scotland",
		"population":5424800,
		"code":"NULL"
	},
	{
		"country":"Norway",
		"population":5311916,
		"code":"NO"
	},
	{
		"country":"Congo",
		"population":5244363,
		"code":"CG"
	},
	{
		"country":"Costa Rica",
		"population":4999441,
		"code":"CR"
	},
	{
		"country":"Ireland",
		"population":4867309,
		"code":"IE"
	},
	{
		"country":"New Zealand",
		"population":4841000,
		"code":"NZ"
	},
	{
		"country":"Oman",
		"population":4829483,
		"code":"OM"
	},
	{
		"country":"Liberia",
		"population":4818977,
		"code":"LR"
	},
	{
		"country":"Central African Republic",
		"population":4666377,
		"code":"CF"
	},
	{
		"country":"Palestine",
		"population":4569087,
		"code":"PS"
	},
	{
		"country":"Mauritania",
		"population":4403319,
		"code":"MR"
	},
	{
		"country":"Panama",
		"population":4176873,
		"code":"PA"
	},
	{
		"country":"Kuwait",
		"population":4137309,
		"code":"KW"
	},
	{
		"country":"Croatia",
		"population":4087843,
		"code":"HR"
	},
	{
		"country":"Georgia",
		"population":3726549,
		"code":"GE"
	},
	{
		"country":"Uruguay",
		"population":3449299,
		"code":"UY"
	},
	{
		"country":"Bosnia and Herzegovina",
		"population":3323929,
		"code":"BA"
	},
	{
		"country":"Eritrea",
		"population":3213972,
		"code":"ER"
	},
	{
		"country":"Puerto Rico",
		"population":3195153,
		"code":"PR"
	},
	{
		"country":"Mongolia",
		"population":3170208,
		"code":"MN"
	},
	{
		"country":"Wales",
		"population":3139000,
		"code":"NULL"
	},
	{
		"country":"Armenia",
		"population":2951776,
		"code":"AM"
	},
	{
		"country":"Jamaica",
		"population":2934855,
		"code":"JM"
	},
	{
		"country":"Albania",
		"population":2866376,
		"code":"AL"
	},
	{
		"country":"Lithuania",
		"population":2801543,
		"code":"LT"
	},
	{
		"country":"Qatar",
		"population":2781677,
		"code":"QA"
	},
	{
		"country":"Moldova",
		"population":2706049,
		"code":"MD"
	},
	{
		"country":"Namibia",
		"population":2448255,
		"code":"NA"
	},
	{
		"country":"Gambia",
		"population":2280102,
		"code":"GM"
	},
	{
		"country":"Botswana",
		"population":2254126,
		"code":"BW"
	},
	{
		"country":"Gabon",
		"population":2119275,
		"code":"GA"
	},
	{
		"country":"Lesotho",
		"population":2108132,
		"code":"LS"
	},
	{
		"country":"North Macedonia",
		"population":2084367,
		"code":"MK"
	},
	{
		"country":"Slovenia",
		"population":2073894,
		"code":"SI"
	},
	{
		"country":"Latvia",
		"population":1927174,
		"code":"LV"
	},
	{
		"country":"Northern Ireland",
		"population":1885400,
		"code":"GB"
	},
	{
		"country":"Guinea-Bissau",
		"population":1874309,
		"code":"GW"
	},
	{
		"country":"Bahrain",
		"population":1569439,
		"code":"BH"
	},
	{
		"country":"Trinidad and Tobago",
		"population":1389858,
		"code":"TT"
	},
	{
		"country":"Estonia",
		"population":1321977,
		"code":"EE"
	},
	{
		"country":"Equatorial Guinea",
		"population":1308974,
		"code":"GQ"
	},
	{
		"country":"East Timor",
		"population":1267972,
		"code":"TP"
	},
	{
		"country":"Mauritius",
		"population":1265303,
		"code":"MU"
	},
	{
		"country":"Cyprus",
		"population":1189265,
		"code":"CY"
	},
	{
		"country":"Swaziland",
		"population":1136191,
		"code":"SZ"
	},
	{
		"country":"Djibouti",
		"population":958920,
		"code":"DJ"
	},
	{
		"country":"Fiji Islands",
		"population":883483,
		"code":"FJ"
	},
	{
		"country":"Reunion",
		"population":859959,
		"code":"RE"
	},
	{
		"country":"Comoros",
		"population":832322,
		"code":"KM"
	},
	{
		"country":"Guyana",
		"population":779004,
		"code":"GY"
	},
	{
		"country":"Bhutan",
		"population":754394,
		"code":"BT"
	},
	{
		"country":"Solomon Islands",
		"population":652858,
		"code":"SB"
	},
	{
		"country":"Western Sahara",
		"population":652271,
		"code":"EH"
	},
	{
		"country":"Macao",
		"population":631636,
		"code":"MO"
	},
	{
		"country":"Montenegro",
		"population":631219,
		"code":"ME"
	},
	{
		"country":"Luxembourg",
		"population":607950,
		"code":"LU"
	},
	{
		"country":"Suriname",
		"population":575991,
		"code":"SR"
	},
	{
		"country":"Cape Verde",
		"population":543767,
		"code":"CV"
	},
	{
		"country":"Maldives",
		"population":515696,
		"code":"MV"
	},
	{
		"country":"Malta",
		"population":484630,
		"code":"MT"
	},
	{
		"country":"Brunei",
		"population":428962,
		"code":"BN"
	},
	{
		"country":"Guadeloupe",
		"population":395700,
		"code":"GP"
	},
	{
		"country":"Bahamas",
		"population":385640,
		"code":"BS"
	},
	{
		"country":"Belize",
		"population":383071,
		"code":"BZ"
	},
	{
		"country":"Martinique",
		"population":376480,
		"code":"MQ"
	},
	{
		"country":"Iceland",
		"population":352721,
		"code":"IS"
	},
	{
		"country":"Vanuatu",
		"population":292680,
		"code":"VU"
	},
	{
		"country":"French Guiana",
		"population":290691,
		"code":"GF"
	},
	{
		"country":"Barbados",
		"population":286641,
		"code":"BB"
	},
	{
		"country":"New Caledonia",
		"population":284060,
		"code":"NC"
	},
	{
		"country":"French Polynesia",
		"population":277679,
		"code":"PF"
	},
	{
		"country":"Mayotte",
		"population":270372,
		"code":"YT"
	},
	{
		"country":"Netherlands Antilles",
		"population":227049,
		"code":"AN"
	},
	{
		"country":"Sao Tome and Principe",
		"population":211028,
		"code":"ST"
	},
	{
		"country":"Samoa",
		"population":196130,
		"code":"WS"
	},
	{
		"country":"Saint Lucia",
		"population":181889,
		"code":"LC"
	},
	{
		"country":"Guam",
		"population":165768,
		"code":"GU"
	},
	{
		"country":"Kiribati",
		"population":115847,
		"code":"KI"
	},
	{
		"country":"Micronesia, Federated States of",
		"population":112640,
		"code":"FM"
	},
	{
		"country":"Grenada",
		"population":111454,
		"code":"GD"
	},
	{
		"country":"Saint Vincent and the Grenadines",
		"population":110210,
		"code":"VC"
	},
	{
		"country":"Virgin Islands, U.S.",
		"population":106977,
		"code":"VI"
	},
	{
		"country":"Aruba",
		"population":105845,
		"code":"AW"
	},
	{
		"country":"Tonga",
		"population":103197,
		"code":"TO"
	},
	{
		"country":"Seychelles",
		"population":96762,
		"code":"SC"
	},
	{
		"country":"Antigua and Barbuda",
		"population":96286,
		"code":"AG"
	},
	{
		"country":"Andorra",
		"population":77006,
		"code":"AD"
	},
	{
		"country":"Dominica",
		"population":71625,
		"code":"DM"
	},
	{
		"country":"Cayman Islands",
		"population":64174,
		"code":"KY"
	},
	{
		"country":"Bermuda",
		"population":63973,
		"code":"BM"
	},
	{
		"country":"Marshall Islands",
		"population":58413,
		"code":"MH"
	},
	{
		"country":"Northern Mariana Islands",
		"population":56882,
		"code":"MP"
	},
	{
		"country":"Greenland",
		"population":56025,
		"code":"GL"
	},
	{
		"country":"American Samoa",
		"population":55465,
		"code":"AS"
	},
	{
		"country":"Saint Kitts and Nevis",
		"population":52441,
		"code":"KN"
	},
	{
		"country":"Faroe Islands",
		"population":48497,
		"code":"FO"
	},
	{
		"country":"Monaco",
		"population":38682,
		"code":"MC"
	},
	{
		"country":"Liechtenstein",
		"population":37910,
		"code":"LI"
	},
	{
		"country":"Turks and Caicos Islands",
		"population":37665,
		"code":"TC"
	},
	{
		"country":"San Marino",
		"population":33785,
		"code":"SM"
	},
	{
		"country":"Gibraltar",
		"population":33718,
		"code":"GI"
	},
	{
		"country":"Virgin Islands, British",
		"population":29802,
		"code":"VG"
	},
	{
		"country":"Palau",
		"population":17907,
		"code":"PW"
	},
	{
		"country":"Cook Islands",
		"population":17379,
		"code":"CK"
	},
	{
		"country":"Wallis and Futuna",
		"population":15289,
		"code":"WF"
	},
	{
		"country":"Anguilla",
		"population":15094,
		"code":"AI"
	},
	{
		"country":"Nauru",
		"population":12704,
		"code":"NR"
	},
	{
		"country":"Tuvalu",
		"population":11508,
		"code":"TV"
	},
	{
		"country":"Saint Helena",
		"population":6600,
		"code":"SH"
	},
	{
		"country":"Montserrat",
		"population":5900,
		"code":"MS"
	},
	{
		"country":"Saint Pierre and Miquelon",
		"population":5888,
		"code":"PM"
	},
	{
		"country":"Falkland Islands",
		"population":2840,
		"code":"FK"
	},
	{
		"country":"Svalbard and Jan Mayen",
		"population":2572,
		"code":"SJ"
	},
	{
		"country":"Norfolk Island",
		"population":2169,
		"code":"NF"
	},
	{
		"country":"Niue",
		"population":1624,
		"code":"NU"
	},
	{
		"country":"Tokelau",
		"population":1411,
		"code":"TK"
	},
	{
		"country":"Christmas Island",
		"population":1402,
		"code":"CX"
	},
	{
		"country":"Antarctica",
		"population":1106,
		"code":"AQ"
	},
	{
		"country":"Holy See (Vatican City State)",
		"population":825,
		"code":"VA"
	},
	{
		"country":"Cocos (Keeling) Islands",
		"population":596,
		"code":"CC"
	},
	{
		"country":"United States Minor Outlying Islands",
		"population":300,
		"code":"UM"
	},
	{
		"country":"Pitcairn",
		"population":67,
		"code":"PN"
	},
	{
		"country":"South Georgia and the South Sandwich Islands",
		"population":30,
		"code":"GS"
	},
	{
		"country":"Bouvet Island",
		"population":0,
		"code":"BV"
	},
	{
		"country":"British Indian Ocean Territory",
		"population":0,
		"code":"IO"
	},
	{
		"country":"French Southern territories",
		"population":0,
		"code":"TF"
	},
	{
		"country":"Heard Island and McDonald Islands",
		"population":0,
		"code":"HM"
	}
]


export default countries;
