import React,{useEffect, useState} from 'react';
import './aphrodite.scss';
import DateEpochInput from '../../elements/date-epoch-input/date-epoch-input';
import usePersistState from '../../../utils/use-persist-state';
import Button from '../../elements/button/button';
import Loading from '../../common/loading/loading';
import { toast } from 'react-toastify';
import { request, unSubRequest } from '../../../utils/request';
import * as DateUtil from '../../../utils/date';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import RepairButton from './repair-button';

import {ReactComponent as AphroditeSvg} from '../../../css/imgs/greek-gods/aphrodite.svg';


const oneMinute = 60000;
const TableEnum = {
  KlineMinuteOne: 60000,
  KlineMinuteFive: 60000 * 5,
  KlineMinuteFifteen: 60000 * 15,
  KlineHourOne: 60000 * 60,
}

const Aphrodite = () => {

  const [loading,setLoading] = useState(false);
  const [loadingS,setLoadingS] = useState(false);
  const [loadingE,setLoadingE] = useState(false);
  const [loadingK,setLoadingK] = useState(false);

  const [rangeStart,setRangeStart] = usePersistState(0,"aphroditeRangeStart","number");
  const [duration,setDuration] = usePersistState(0,"aphroditeDuration","number");
  const [rangeEnd,setRangeEnd] = useState(0);
  const [exchange,setExchange] = usePersistState("binance","aphroditeExchange");
  const [table,setTable] = usePersistState("KlineMinuteFifteen","aphroditeTable");
  const [orderBy,setOrderBy] = usePersistState("symbol","aphroditeOrderBy");
  const [orderDir,setOrderDir] = usePersistState("DESC","aphroditeOrderDir");
  const [onlyCountOne,setOnlyCountOne] = usePersistState(false,"aphroditeOnlyCountOne","boolean");
  const [symbol,setSymbol] = useState("");

  const [localSymbol,setLocalSymbol] = useState("");
  const [exchangeSymbol,setExchangeSymbol] = useState("");


  const [expectedCount,setExpectedCount] = useState(0);
  const [expectedOpenTimes,setExpectedOpenTimes] = useState([]);
  const [healthySymbols,setHealthySymbols] = useState(0);

  const [results,setResults] = useState([]);
  const [localSymbols,setLocalSymbols] = useState([]);
  const [exchangeSymbols,setExchangeSymbols] = useState([]);

  useEffect(() => {
    return () => {
      unSubRequest("aphrodite-search-kline-health")
    }
  },[])

  useEffect(() => {
    setRangeEnd(parseInt(rangeStart)+parseInt(duration));
  },[rangeStart,duration])

  useEffect(() => {
    let holdExpectedOpenTimes = [];
    let timenow = new Date().getTime();
    let rangeEndHold = rangeEnd;
    if(rangeEndHold > timenow) rangeEndHold = timenow;
    let interval = TableEnum[table];
    let rangeStartHold = rangeStart;
    while(rangeStartHold <= (rangeEndHold - interval)){
      holdExpectedOpenTimes.push(rangeStartHold);
      rangeStartHold += interval;
    }
    setExpectedOpenTimes(holdExpectedOpenTimes.reverse());
    setExpectedCount(holdExpectedOpenTimes.length);
  },[rangeStart,rangeEnd,table]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < results.length; i++) {
      if(results[i]['count'] === expectedCount) count++;
    }
    setHealthySymbols(count);
  },[results,expectedCount,]);

  const handleSubmit = (e) => {
    e.preventDefault();
    search();
  }

  const search = () => {
    let endpoint = "/search-kline-health";

    let data = {
      rangeStart,
      rangeEnd,
      exchange,
      table,
      orderBy,
      orderDir,
    }

    setLoading(true);
    request("aphrodite-search-kline-health",endpoint,"POST", data, {
      then: function(res){
        setResults(res.data.res);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  const showSymbol = (symbol) => {

    let endpoint = "/show-kline-timeframe"
    let data = {
      symbol,
      rangeStart,
      rangeEnd,
      exchange,
      table,
    }

    setLoadingS(true);
    request("aphrodite-kline-show-timeframe",endpoint,"POST", data, {
      then: function(res){
        if(!res.data.res.length) toast.info("Olympus "+symbol+" Empty");
        // console.log("res.data.res",res.data.res)
        setLocalSymbol(symbol);
        setLocalSymbols(res.data.res);
      },
      catch: function(err){toast.error("Repair Error: "+symbol+" "+err.message);},
      finally: function(){
        setLoadingS(false);
      }
    }) 
  }

  const getKline = (exchange,symbol,table,rangeStart,rangeEnd,cbFunc) => {
    let endpoint = "/kline";
    endpoint += "?exchange="+exchange;
    endpoint += "&symbol="+symbol;
    endpoint += "&table="+table;
    endpoint += "&startTime="+rangeStart;
    endpoint += "&endTime="+rangeEnd;

    setLoadingK(true);
    request("kline",endpoint,"GET", {}, {
      then: function(res){
        // console.log(res.data.res);
        if(cbFunc) cbFunc(res.data.res);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoadingK(false);setLoadingE(false);}
    }) 
  }

  const updateKline = (quick) => {
    let endpoint = "/update-kline";

    let data = {
      exchange: exchange,
      symbol: symbol,
      table: table,
      quick: quick
    }

    setLoadingK(true);
    request("update-kline",endpoint,"POST", data, {
      then: function(res){toast.success("Klines crawled");},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoadingK(false);}
    }) 
  }

  return (
    <div className="aphrodite">
      <h3>Aphrodite</h3>
      <div className='god-icon'><AphroditeSvg/></div>

      <div className="aphrodite-content">
        <form onSubmit={handleSubmit}>
          <div className="block">
            <div className='range'>
              <label>Range Start</label>
              <DateEpochInput time={rangeStart} setTime={setRangeStart} />
            </div>
            <div>
              <label>Duration</label>
              <select value={duration} onChange={(e) => {setDuration(e.target.value)}}>
                <option value={oneMinute * 60 * 12}>12 hours</option>
                <option value={oneMinute * 60 * 24 * 1}>1 day</option>
                <option value={oneMinute * 60 * 24 * 3}>3 days</option>
                <option value={oneMinute * 60 * 24 * 7}>1 week</option>
                <option value={oneMinute * 60 * 24 * 7 * 2}>2 weeks</option>
                <option value={oneMinute * 60 * 24 * 7 * 3}>3 weeks</option>
                <option value={oneMinute * 60 * 24 * 30 * 1}>1 month</option>
                <option value={oneMinute * 60 * 24 * 30 * 2}>2 months</option>
                <option value={oneMinute * 60 * 24 * 30 * 3}>3 months</option>
                <option value={oneMinute * 60 * 24 * 30 * 4}>4 months</option>
                <option value={oneMinute * 60 * 24 * 30 * 5}>5 months</option>
                <option value={oneMinute * 60 * 24 * 30 * 6}>6 months</option>
              </select>
            </div>
            <div className='range'>
              <label>Range End</label>
              <DateEpochInput time={rangeEnd} setTime={setRangeEnd} />
            </div>
          </div>
          <div className="block">
            <div>
              <label>Exchange</label>
              <select value={exchange} onChange={(e) => {setExchange(e.target.value)}}>
                <option>Ascendex</option>
                <option>Binance</option>
                <option>Kucoin</option>
                <option>Poloniex</option>
              </select>
            </div>
            <div>
              <label>Table</label>
              <select value={table} onChange={(e) => {setTable(e.target.value)}}>
                <option value="KlineMinuteOne">Minute One</option>
                <option value="KlineMinuteFive">Minute Five</option>
                <option value="KlineMinuteFifteen">Minute Fifteen</option>
                <option value="KlineHourOne">Hour One</option>
              </select>
            </div>
            <div>
              <label>Order By</label>
              <select value={orderBy} onChange={(e) => {setOrderBy(e.target.value)}}>
                <option value="symbol">symbol</option>
                <option value="count">count</option>
              </select>
            </div>
            <div>
              <label>Order Dir</label>
              <select value={orderDir} onChange={(e) => {setOrderDir(e.target.value)}}>
                <option value="DESC">DESC</option>
                <option value="ASC">ASC</option>
              </select>
            </div>
            <div>
              <label>Only Count One Plus</label>
              <SwitchToggle isOn={onlyCountOne} onClickFunc={()=>{setOnlyCountOne(!onlyCountOne)}}/>
            </div>
          </div>
          <div className="buttons kline-buttons">
            <input value={symbol} onChange={(e) => {setSymbol(e.target.value)}} type="text" placeholder="Symbol" />
            <Button onClick={() => {getKline(exchange,symbol,table,rangeStart,rangeEnd)}} type="button">Get Kline</Button>
            <Button onClick={() => {updateKline(false)}}type="button">Update Kline</Button>
            <Button onClick={() => {updateKline(true)}}type="button">Update Kline Quick</Button>
            <Loading show={loadingK} size="24px"/>
          </div>
          <div className="buttons">
            <Button type="submit" value={"View Klines"} />
          </div>
        </form>

        <Loading show={loading} />
        <div className="results">
          <div className="symbol-count">
            <label>Expected Count: {expectedCount} total:{results.length} Healthy{healthySymbols}</label>
            <table>
              <tbody>
                <tr>
                  <th>Symbol</th>
                  <th>Count</th>
                  <th>Repair</th>
                  <th>Olympus</th>
                  <th>Exchange</th>
                </tr>
                {
                  results.map((v,i) => {
                    if(onlyCountOne && v.count <= 0) return null;
                    return(
                      <tr key={i}>
                        <td>{v.symbol}</td>
                        <td>{v.count}</td>
                        
                        <td>
                          {(v.count === expectedCount)?"Healthy":
                          <RepairButton 
                            symbol={v.symbol}
                            rangeStart={rangeStart}
                            rangeEnd={rangeEnd}
                            exchange={exchange}
                            table={table}
                            count={v.count}
                            />}
                        </td>
                        <td><button onClick={() => {
                          showSymbol(v.symbol)
                        }}>Olympus</button></td>
                        <td><button onClick={() => {
                          setLoadingE(true);
                          getKline(exchange,v.symbol,table,rangeStart,rangeEnd,(res) => {
                            let results = [];
                            for (let i = 0; i < res.length; i++) {
                              const item = res[i];
                              results.push(item[0]);
                            }
                            setExchangeSymbol(v.symbol);
                            setExchangeSymbols(results);
                          });
                        }}>Exchange</button></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="local-symbols">
            <Loading show={loadingS}/>
            <label>Data Olympus Has<button onClick={() => {
              setLocalSymbol("");
              setLocalSymbols([])
            }}>clearr</button> count: {localSymbols.length}</label>
            <table>
              <tbody>
                <tr>
                  <th>Symbol</th>
                  <th>Open Time Pretty</th>
                  <th>Open Time</th>
                </tr>
                {
                  expectedOpenTimes.map((v,i) => {
                    let hasOpenTime = "missing-time";
                    if(localSymbols.indexOf(v) !== -1){
                      hasOpenTime = "has-time";
                    }
                    return(
                      <tr className={hasOpenTime} key={i}>
                        <td>{localSymbol}</td>
                        <td>{DateUtil.prettifyDateWithTime(v)}</td>
                        <td>{v}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="exchange-symbols">
            <Loading show={loadingE}/>
            <label>Data Exchange Has<button onClick={() => {
              setExchangeSymbol("");
              setExchangeSymbols([])
            }}>clear</button> count: {exchangeSymbols.length}</label>
            <table>
              <tbody>
                <tr>
                  <th>Symbol</th>
                  <th>Open Time Pretty</th>
                  <th>Open Time</th>
                </tr>
                {
                  expectedOpenTimes.map((v,i) => {
                    let hasOpenTime = "missing-time";
                    if(exchangeSymbols.indexOf(v) !== -1){
                      hasOpenTime = "has-time";
                    }
                    return(
                      <tr className={hasOpenTime} key={i}>
                        <td>{exchangeSymbol}</td>
                        <td>{DateUtil.prettifyDateWithTime(v)}</td>
                        <td>{v}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Aphrodite;