
const AccountStatusEnum = {
  Any: 0,
  EmailUnverified: 1,
  Banned: 2,
  Disabled: 3,
  Basic: 4,
  Premium: 5,
  Staff: 6,
  Admin: 7,
  SuperAdmin: 8,
  System: 9,
}

export const AccountStatusIdEnum = {
  0:"Any",
  1:"EmailUnverified",
  2:"Banned",
  3:"Disabled",
  4:"Basic",
  5:"Premium",
  6:"Staff",
  7:"Admin",
  8:"SuperAdmin",
  9:"System",
}

export const displayAccountStatusLabelById = (id) => {
  var label = "Null";
  switch(id){
    case AccountStatusEnum.Any: label = "Any"; break;
    case AccountStatusEnum.EmailUnverified: label = "Email Unverified"; break;
    case AccountStatusEnum.Banned: label = "Banned"; break;
    case AccountStatusEnum.Disabled: label = "Disabled"; break;
    case AccountStatusEnum.Basic: label = "Basic"; break;
    case AccountStatusEnum.Premium: label = "Premium"; break;
    case AccountStatusEnum.Staff: label = "Staff"; break;
    case AccountStatusEnum.Admin: label = "Admin"; break;
    case AccountStatusEnum.SuperAdmin: label = "SuperAdmin"; break;

    default: break;
  }
  return label;
}

export default AccountStatusEnum;