import React,{useState,useEffect} from 'react';
import './repair-button.scss';
import { toast } from 'react-toastify';
import { request, unSubRequest } from '../../../utils/request';
import Button from '../../elements/button/button';

const RepairButton = ({symbol,exchange,rangeStart,rangeEnd,table,count}) => {

  const [loading,setLoading] = useState(false);
  const [repaired,setRepaired] = useState(false);

  let name = "aphrodite-repair-kline-"+symbol+"-"+exchange

  useEffect(() => {
    setRepaired(false);
  },[symbol,exchange,exchange,rangeEnd,rangeStart,count])

  useEffect(() => {
    return () => {
      unSubRequest(name);
    }
  },[name])

  const repairSymbol = () => {

    let endpoint = "/repair-kline"
    let data = {
      symbol,
      rangeStart,
      rangeEnd,
      exchange,
      table,
    }

    setLoading(true);
    request(name,endpoint,"POST", data, {
      then: function(res){toast.success("Repaired: "+symbol);setRepaired(true)},
      catch: function(err){toast.error("Repair Error: "+symbol+" "+err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }

  return (
    <Button disabled={repaired} parentClassName="repair-button" status={(loading)?"loading":"refresh"}
      onClick={() => {repairSymbol()}}>
    </Button>
  );

};

export default RepairButton;