import React from 'react';
import './logo.scss';
import Config from '../../../config/config';
import {ReactComponent as LogoSvg} from '../../../css/imgs/logo.svg';

const Logo = ({options}) => {
  options = (options)?options:"";
  return (
    <div className={"logo-c "+(Config.Sandbox?"sandbox ":"")+options}>
      <span className="icon"><LogoSvg /></span>
      <span className="text">
        <span className="main">{Config.Common.AppName}</span>
        {Config.Sandbox?<span className="sub">sandbox</span>:null}
      </span>
    </div>
  );

};

export default Logo;